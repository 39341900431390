import styled from "styled-components";
import { Title } from "components/TokenPair/index.styles";
import { Subtitle } from "components/TokenSelect/TokenCard.styles";
import {
  colorGrayscaleOffWhite,
  colorGrayscaleTitleActive,
  colorInfoDefault,
  colorPrimaryDark,
  colorPrimaryDefault,
  colorPrimaryLight,
} from "utils/colors";
import { ActionButton, FlexContainer } from "containers/common/styles";
import { ReactComponent as StakedAprBadge } from "assets/icons/stakescreen/Badges-apr.svg";
import { ReactComponent as StakedApyBadge } from "assets/icons/stakescreen/Badges-apy.svg";
import { ReactComponent as LockIcon } from "assets/icons/stakescreen/lock.svg";

export const PageTitle = styled(Title)`
  margin-top: 47px;
  text-align: ${(props) => props.textAlign};
  margin-bottom: 0;
  font-size: ${(props) => props.fontSize};
  height: auto;
  width: auto;
`;

export const PageSubtitle = styled(Subtitle)`
  font-size: 14px;
  margin-top: 5px;
  width: auto;
`;

export const StakingTabs = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PanelTitle = styled(Title)`
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 18px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
`;

export const TitleSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: ${(props) => props.justifyItems};
  align-items: ${(props) => props.alignItems};
  width: 530px;
  margin: ${(props) => props.margin};
`;

export const CustomActionButton = styled(ActionButton)`
  border: ${(props) => (props.outline ? `1px solid ${props.outline}` : "none")};
  :hover {
    background-color: ${(props) => props.background || colorPrimaryDark};
  }
  width: 100%;
  min-height: 60px;
  margin-top: 5px;
`;

export const Controls = styled(FlexContainer)`
  position: relative;
  width: 100%;
`;

export const Info = styled(Controls)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${colorGrayscaleTitleActive};
  padding: 5px 10px;
  box-sizing: border-box;
`;

export const RadioButton = styled.input.attrs({
  type: "radio",
})`
  display: none;
  :checked + label {
    color: ${colorGrayscaleOffWhite};
  }
  :disabled + label {
    cursor: default;
  }
`;

export const BoostButton = styled.button`
  z-index: 10;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(61, 0, 162, 0.4);
  box-sizing: border-box;
  background-color: ${(props) =>
    props.selected
      ? colorPrimaryDefault
      : "#220a56"}; // Does not appear in color pallete
  cursor: pointer;

  label {
    display: inline-block;
    color: ${(props) =>
      props.highlight ? colorGrayscaleOffWhite : colorPrimaryDefault};
    cursor: inherit;
    font-size: 12px;
  }

  &:hover:not([disabled]) {
    background-color: ${colorPrimaryDark};
    border-color: ${colorPrimaryDark};
    label {
      color: ${colorGrayscaleOffWhite};
    }
  }

  :disabled {
    color: ${colorPrimaryDefault};
    border-color: #220a56;
    label {
      opacity: 0.4;
    }
    cursor: inherit;
  }
`;
const badgeStyle = "position: absolute; bottom: 1px;";
export const StyledApyBadge = styled(StakedApyBadge)`
  ${badgeStyle}
`;
export const StyledAprBadge = styled(StakedAprBadge)`
  ${badgeStyle}
`;

export const HighlightedTitle = styled.div`
  cursor: pointer;
  color: ${colorGrayscaleOffWhite};
  font-weight: bold;
  font-size: 20px;
  line-height: 2.1875rem;
  padding: 0 0 0.625rem 0;
  min-width: 6.25rem;
  text-align: center;
`;

export const StakeInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 470px;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  background: linear-gradient(
    0deg,
    rgba(95, 46, 234, 0.39) 0%,
    rgba(95, 46, 234, 0) 94.55%
  );
  padding: 30px 15px 54px 15px; // bottom padding: 44px for Footer height and extra 10px for spacing
  @-moz-document url-prefix() {
    bottom: -110px;
  }
`;

export const Items = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressBarContainer = styled.div`
  progress[value] {
    width: 100%;
    height: 0.625rem;
    -webkit-appearance: none;
    appearance: none;
  }

  // Webkit
  progress[value]::-webkit-progress-bar {
    background-color: #0a0c18;
    border-radius: 3.125rem;
  }
  progress[value]::-webkit-progress-value {
    background: ${(props) =>
      props.isAutoCompound
        ? colorInfoDefault
        : "linear-gradient(126.66deg, #5f2eea 14.36%, #972eea 99.39%);"};
    border-radius: 3.125rem;
  }

  // Moz
  progress[value]::-moz-progress-bar {
    background-color: #0a0c18;
    border-radius: 3.125rem;
  }
  progress[value]::-moz-progress-value {
    background: ${(props) =>
      props.isAutoCompound
        ? colorInfoDefault
        : "linear-gradient(126.66deg, #5f2eea 14.36%, #972eea 99.39%);"};
    border-radius: 3.125rem;
  }
`;

export const StyledLockIcon = styled(LockIcon)`
  stroke: ${colorPrimaryLight};
`;
