const { ethers } = require("ethers");
const { Provider } = require("ethers-multicall");

const { config } = require("../config/config");
const addresses = config.getNetworkAddresses();

const { getToken, getChainByName } = require("./utils/addresses");
const { getWalletUser } = require("./utils/wallet");
const { getRevaStats, getCirculatingSupply } = require("./read/revaStats");
const {
  getAllPositions,
  getAllUserPositions,
  getAllUnderlyingTvls,
} = require("./read/positions");
const { getAllApys } = require("./read/apy");
const { getGasPrice, estimateGas, getGasLimit } = require("./read/network");
const { getLiquidityRatio } = require("./read/lp");
const {
  getAllTokenToBusdRates,
  getUserBalances,
  getUserBalanceByTokenAddress,
  hasUserApprovedInfinity,
  getTokenToBusdRate,
} = require("./read/tokens");
const {
  getPercentageOfProfitsConvertedToReva,
  getPercentageOfProfitsDistributedToStakers,
} = require("./read/revault");
const {
  getVaultFees,
  getRebalanceCosts,
  getRebalanceDailyGainEstimation,
  getRebalanceBreakEvenPeriod,
} = require("./read/vaults");
const {
  deposit,
  withdraw,
  withdrawAll,
  rebalanceAll,
  harvest,
} = require("./write/revault");
const { zap, zapAndDeposit } = require("./write/zap");
const { generateApproveInfinityData } = require("./write/tokens");
const {
  stakeReva,
  stakeRevaLp,
  unstakeReva,
  unstakeRevaLp,
  unstakeRevaEarly,
  claimRevaReward,
  claimRevaLpReward,
  enterRevaCompoundingPosition,
  exitRevaCompoundingPosition,
  depositToRevaCompoundingPosition,
} = require("./write/staking");
const {
  getRevaStakingPools,
  getRevaLpStakingPools,
  getAllUserRevaStakingPoolPositions,
  getAllUserRevaLpStakingPoolPositions,
} = require("./read/staking");

function RevaApi() {
  const ethersProvider = new ethers.providers.StaticJsonRpcProvider(
    config.getProviderUrl(),
  );

  // NOTE: always using mainnet chain ID because multicall uses the chain ID to determine contract address:
  // https://github.com/cavanmflynn/ethers-multicall/blob/master/src/provider.ts#L35
  const multicallProvider = new Provider(
    ethersProvider,
    getChainByName("matic-mainnet").chainId,
  );

  if (!(this instanceof RevaApi)) {
    return new RevaApi();
  }

  // stats at the bottom of the page. same for all users (can be cached?)
  RevaApi.prototype.stats = function () {
    return getRevaStats(ethersProvider);
  };

  RevaApi.prototype.getCirculatingSupply = function () {
    return getCirculatingSupply(ethersProvider);
  };

  // returns an array of all pairs we support.
  // this is static details the same for all users, can be cached, etc.
  // order of vaults is not guaranteed (i.e., not ranked based on APY)
  // TODO: vaultID can be Autofarm, etc. Is it confusing? it's more a vault "provider" rather than an
  // actual vault
  RevaApi.prototype.getAllPositions = function () {
    return getAllPositions(multicallProvider, ethersProvider);
  };

  // get a connected user position within our pairs. for each pair the user has a position in, receive
  // the selected vault and position
  RevaApi.prototype.getAllUserPositions = async function (accountAddress) {
    return getAllUserPositions(
      ethersProvider,
      multicallProvider,
      accountAddress,
    );
  };

  RevaApi.prototype.getAllApys = async function () {
    return getAllApys();
  };

  // all tokens appearing when zapping
  // TODO: should also contain LP tokens to be displayed as stats? how we differentiate between
  // regular tokens and LP?
  RevaApi.prototype.tokenRates = async function () {
    return getAllTokenToBusdRates(multicallProvider);
  };

  RevaApi.prototype.userTokenBalances = async function (accountAddress) {
    return getUserBalances(ethersProvider, multicallProvider, accountAddress);
  };

  RevaApi.prototype.getUserRevaBalance = async function (userAddress) {
    return getUserBalanceByTokenAddress(
      ethersProvider,
      userAddress,
      addresses.revaToken,
      18,
    );
  };

  RevaApi.prototype.getUserRevaBnbBalance = async function (userAddress) {
    return getUserBalanceByTokenAddress(
      ethersProvider,
      userAddress,
      addresses["reva-matic"],
      18,
    );
  };

  RevaApi.prototype.generateDepositData = async function (vaultId, amount) {
    return deposit(ethersProvider, vaultId, amount);
  };

  RevaApi.prototype.generateWithdrawData = async function (vaultId, amount) {
    return withdraw(vaultId, amount);
  };

  RevaApi.prototype.generateWithdrawAllData = async function (
    vaultId,
    userAddress,
  ) {
    return withdrawAll(vaultId, userAddress);
  };

  RevaApi.prototype.generateRebalanceAllData = async function (
    fromVaultId,
    toVaultId,
    userAddress,
  ) {
    return rebalanceAll(fromVaultId, toVaultId, userAddress);
  };

  RevaApi.prototype.generateClaimData = async function (vaultId) {
    return harvest(vaultId);
  };

  RevaApi.prototype.generateZapData = async function (
    fromTokenId,
    toTokenId,
    amount,
  ) {
    return zap(ethersProvider, fromTokenId, toTokenId, amount);
  };

  RevaApi.prototype.generateZapAndDepositData = async function (
    fromTokenId,
    toVaultId,
    amount,
  ) {
    return zapAndDeposit(ethersProvider, fromTokenId, toVaultId, amount);
  };

  RevaApi.prototype.hasApprovedInfinity = async function (
    tokenAddress,
    userAddress,
    toAddress,
  ) {
    return hasUserApprovedInfinity(
      ethersProvider,
      tokenAddress,
      userAddress,
      toAddress,
    );
  };

  RevaApi.prototype.generateApproveInfinityData = async function (
    tokenAddress,
    targetAddress,
  ) {
    return generateApproveInfinityData(tokenAddress, targetAddress);
  };

  RevaApi.prototype.stakeReva = async function (poolId, amount) {
    return stakeReva(poolId, amount);
  };

  RevaApi.prototype.unstakeReva = async function (poolId, amount) {
    return unstakeReva(poolId, amount);
  };

  RevaApi.prototype.enterCompoundingPosition = async function (poolId) {
    return enterRevaCompoundingPosition(poolId);
  };

  RevaApi.prototype.exitCompoundingPosition = async function (poolId) {
    return exitRevaCompoundingPosition(poolId);
  };

  RevaApi.prototype.depositToRevaCompoundingPosition = async function (
    poolId,
    amount,
  ) {
    return depositToRevaCompoundingPosition(poolId, amount);
  };

  RevaApi.prototype.unstakeRevaEarly = async function (poolId, amount) {
    return unstakeRevaEarly(poolId, amount);
  };

  RevaApi.prototype.claimRevaStakeReward = async function (poolId) {
    return claimRevaReward(poolId);
  };

  RevaApi.prototype.getRevaStakingPools = async function () {
    return getRevaStakingPools(multicallProvider);
  };

  RevaApi.prototype.getAllUserRevaStakingPoolPositions = async function (
    userAddress,
  ) {
    return getAllUserRevaStakingPoolPositions(
      ethersProvider,
      multicallProvider,
      userAddress,
    );
  };

  RevaApi.prototype.stakeRevaLp = async function (poolId, amount) {
    return stakeRevaLp(poolId, amount);
  };

  RevaApi.prototype.unstakeRevaLp = async function (poolId, amount) {
    return unstakeRevaLp(poolId, amount);
  };

  RevaApi.prototype.claimRevaLpStakeReward = async function (poolId) {
    return claimRevaLpReward(poolId);
  };

  RevaApi.prototype.getRevaLpStakingPools = async function () {
    return getRevaLpStakingPools(ethersProvider, multicallProvider);
  };

  RevaApi.prototype.getAllUserRevaLpStakingPoolPositions = async function (
    userAddress,
  ) {
    return getAllUserRevaLpStakingPoolPositions(multicallProvider, userAddress);
  };

  RevaApi.prototype.rebalanceStats = async function (
    fromVaultId,
    toVaultId,
    userAddress,
  ) {
    const rebalanceCosts = await getRebalanceCosts(
      ethersProvider,
      fromVaultId,
      toVaultId,
      userAddress,
    );
    const rebalanceDailyGain = await getRebalanceDailyGainEstimation(
      ethersProvider,
      fromVaultId,
      toVaultId,
      userAddress,
    );
    const rebalanceBreakEvenPeriod = await getRebalanceBreakEvenPeriod(
      ethersProvider,
      fromVaultId,
      toVaultId,
      userAddress,
    );

    return {
      actionCost: rebalanceCosts.totalCostUsd,
      withdrawFee: rebalanceCosts.withdrawalFeeUsd,
      depositFee: rebalanceCosts.depositFeeUsd,
      txGas: rebalanceCosts.txGas,
      gasPrice: rebalanceCosts.gasPrice,
      gasFee: rebalanceCosts.gasCostUsd,
      gain24h: rebalanceDailyGain,
      gain7d: rebalanceDailyGain * 7,
      gain30d: rebalanceDailyGain * 30,
      timeToBreakEven: {
        days: rebalanceBreakEvenPeriod.days,
        hours: rebalanceBreakEvenPeriod.hours,
        minutes: rebalanceBreakEvenPeriod.minutes,
      },
    };
  };

  RevaApi.prototype.getGasPrice = async function (getBignumber) {
    return getGasPrice(ethersProvider, getBignumber);
  };

  RevaApi.prototype.estimateGas = async function (tx) {
    return estimateGas(ethersProvider, tx);
  };

  RevaApi.prototype.getVaultFees = async function (vaultId, userAddress) {
    return getVaultFees(ethersProvider, vaultId, userAddress);
  };

  RevaApi.prototype.getLiquidityRatio = async function (
    token0symbol,
    token1symbol,
    amount,
    precision,
  ) {
    return getLiquidityRatio(
      ethersProvider,
      getToken(token0symbol),
      getToken(token1symbol),
      amount,
      precision,
    );
  };

  RevaApi.prototype.getTokenToBusdRate = async function (tokenId) {
    return getTokenToBusdRate(ethersProvider, tokenId);
  };

  RevaApi.prototype.getGasLimit = async function (tx, multiplier = 1.0) {
    try {
      const gasLimit = await getGasLimit(ethersProvider, tx, multiplier);
      return `0x${gasLimit.toString(16)}`;
    } catch (error) {
      console.log("gas estimation fail", error);
      return undefined;
    }
  };

  RevaApi.prototype.getPlatformFees = async function () {
    return {
      platformFee: await getPercentageOfProfitsConvertedToReva(ethersProvider),
      buybackRate: await getPercentageOfProfitsDistributedToStakers(
        ethersProvider,
      ),
    };
  };

  RevaApi.prototype.getWalletUser = function () {
    return getWalletUser();
  };

  // Returns a mapping of { vaultId: TvlBusdBignumber }
  RevaApi.prototype.getAllUnderlyingTvls = async function () {
    return getAllUnderlyingTvls(multicallProvider, ethersProvider, true);
  };
}

export { RevaApi };
