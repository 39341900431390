import { FlexContainer } from "containers/common/styles";
import styled from "styled-components";

export const FarmingTabs = styled.div`
  margin-top: 20px;
`;

export const Info = styled(FlexContainer)`
  position: relative;
  flex-direction: row;
`;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const BottomTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
