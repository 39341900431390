import React from "react";
import { colorGrayscaleOffWhite, colorPrimaryDefault } from "utils/colors";
import { getTokenLink } from "helpers/utils";
import Link from ".";

const RevaLink = () => (
  <Link
    text="Get Reva"
    to={getTokenLink("reva")}
    color={colorPrimaryDefault}
    external
    hoverColor={colorGrayscaleOffWhite}
    textTransform="none"
  />
);

export default RevaLink;
