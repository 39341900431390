import { Observable } from "rxjs";
import { NOTIFICATION_TYPES } from "utils/constants";
import { ethers } from "ethers";

const WEB3_URL_PROVIDER =
  require("../../config/config").config.getProviderUrl();
const provider = new ethers.providers.JsonRpcProvider(WEB3_URL_PROVIDER);

function txHashStatus(txHash) {
  const txObservable = new Observable((subscriber) => {
    (async () => {
      let retries = 900;
      subscriber.next({ state: NOTIFICATION_TYPES.PENDING, txHash });
      for (let index = 0; index < retries; index++) {
        let txBlock = await provider.getTransaction(txHash);
        if (txBlock && txBlock.blockNumber) {
          let currentBlock = await provider.getBlockNumber();
          let confirmations = currentBlock - txBlock.blockNumber;
          if (confirmations >= 3) {
            const receipt = await provider.getTransactionReceipt(txHash);
            if (!receipt || !receipt.status) {
              subscriber.next({ state: NOTIFICATION_TYPES.FAILED, txHash });
              subscriber.error("TX Failed");
              subscriber.complete();
            } else {
              subscriber.next({ state: NOTIFICATION_TYPES.CONFIRMED, txHash });
              subscriber.complete();
            }

            return;
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      subscriber.next({ state: NOTIFICATION_TYPES.UNCONFIRMED, txHash });
      subscriber.error("Could not find TX");
      subscriber.complete();
    })();
  });

  return txObservable;
}

export { txHashStatus };
