import React from "react";
import { render } from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import App from "./containers/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "services/web3Provider";
import Web3ReactManager from "components/Web3ReactManager";
import { NETWORK_CONTEXT_NAME } from "utils/constants";

const queryClient = new QueryClient();
const Web3ProviderNetwork = createWeb3ReactRoot(NETWORK_CONTEXT_NAME);

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Web3ReactManager>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </Web3ReactManager>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </BrowserRouter>
    </ConnectedRouter>
  </Provider>,
  target,
);
