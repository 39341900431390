import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { BscConnector } from "@binance-chain/bsc-connector";

const NETWORK_CHAIN_ID = 56;

export const injected = new InjectedConnector({
  supportedChainIds: undefined, // we can specify supported networks but we're handling those in UI
});

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: process.env.REACT_APP_WEB3_PROVIDER },
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: process.env.REACT_APP_WEB3_PROVIDER },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
  chainId: NETWORK_CHAIN_ID,
});

export const bscConnector = new BscConnector({
  supportedChainIds: undefined,
});
