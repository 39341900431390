import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  colorGrayscaleLabel,
  colorGrayscaleLine,
  colorGrayscaleBody,
  colorInfoDefault,
  colorInfoDark,
  colorGrayscaleOffWhite,
  colorInfoDefaultRGBA,
  colorGrayscaleBodyRGBA,
  colorGrayscaleDark,
  colorErrorDark,
  colorErrorDefault,
} from "utils/colors";
import { useAutoCompound } from "hooks/autocompound.hook.js";
import AutoCompoundTooltip from "./AutoCompoundTooltip";
import { ReactComponent as IconInfo } from "assets/icons/stakescreen/info.svg";
import { ActionButton, ActionButtons } from "containers/common/styles";
import usePortal from "react-cool-portal";
import { Content } from "components/ModalConnect/ConnectorSelection.styles"; // Move to modal components
import { useOnClickOutside } from "helpers/hooks";
import { ReactComponent as LockTimeIcon } from "assets/icons/stakescreen/time-lock.svg";
import { ReactComponent as UnlockTimeIcon } from "assets/icons/stakescreen/time-unlock.svg";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import LoadingSpinner from "components/LoadingSpinner";
import { useStakeData } from "hooks/staking.hooks";
const wrapperId = "autocompund-tooltip-wrapper";

export default function AutoCompound({ poolId }) {
  const { data: stakeData, isLoading: isLoadingStakeData } = useStakeData();
  if (isLoadingStakeData || !stakeData) {
    return null;
  }
  const { userPositions, pools } = stakeData;
  const { apr, apy } = pools[poolId];
  const selectedPoolUserPosition = userPositions[poolId];
  const isStakedInPool = selectedPoolUserPosition.revaStaked.gt(0);
  const isAutoCompound = selectedPoolUserPosition.isCompounding;
  const { mutate: mutateAutoCompound, isLoading: isAutoCompLoading } =
    useAutoCompound({
      params: {
        autoCompound: !isAutoCompound,
        poolId,
      },
      onSettled: () => {
        hide();
      },
    });
  const { Portal, show, hide } = usePortal({
    defaultShow: false,
    clickOutsideToHide: false,
  });
  const ref = useRef();
  useOnClickOutside(ref, () => {
    hide();
  });

  return (
    <Wrapper data-for={wrapperId} data-tip="auto-compound info tooltip">
      <AutoCompoundTooltip apr={apr} apy={apy} wrapperId={wrapperId} />

      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <IconInfo />
        <Label
          css={`
            margin-left: 6px;
          `}
          color={isAutoCompound ? colorInfoDefault : colorGrayscaleBody}
        >
          Auto Comp
        </Label>
      </div>
      {isAutoCompLoading ? (
        <LoadingSpinner style={{ width: 15, height: 15 }} />
      ) : (
        <Slider
          autoCompoundOn={isAutoCompound}
          onClick={poolId === 0 ? mutateAutoCompound : show}
          disabled={!isStakedInPool}
        >
          <APROption autoCompoundOn={isAutoCompound}>APR</APROption>
          <APYOption autoCompoundOn={isAutoCompound}>APY</APYOption>
        </Slider>
      )}

      <Portal>
        <Content ref={ref} width={332} height={346}>
          <CloseBtn onClick={hide} />

          {!isAutoCompound ? <StyledLockTimeIcon /> : <StyledUnlockTimeIcon />}
          <Content.Title>
            Time {isAutoCompound ? "Unlock" : "Lock"}
          </Content.Title>
          <Content.Subtitle
            css={`
              margin-bottom: 0;
            `}
          >
            Turning auto compound{" "}
            <BoldLabel autoCompoundOn={isAutoCompound}>
              {isAutoCompound ? "OFF" : "ON"}
            </BoldLabel>{" "}
            will reset and
            {` `}
            {isAutoCompound ? "unfreeze" : "freeze"} the lock timer, are you
            sure?
          </Content.Subtitle>
          <ActionButtons>
            <ActionButton
              text="Cancel"
              background={colorGrayscaleDark}
              outline={colorGrayscaleBody}
              onClick={hide}
            />
            <CustomActionButton
              text={
                isAutoCompLoading ? (
                  <LoadingSpinner />
                ) : (
                  `Turn ${isAutoCompound ? "OFF" : "ON"}`
                )
              }
              background={isAutoCompound ? colorErrorDefault : colorInfoDefault}
              color={colorGrayscaleOffWhite}
              onClick={mutateAutoCompound}
              loading={isAutoCompLoading}
              loadingText={`Turning ${isAutoCompound ? "off" : "on"}...`}
              autoCompoundOn={isAutoCompound}
            />
          </ActionButtons>
        </Content>
      </Portal>
    </Wrapper>
  );
}

/** Styled Components*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
`;

const Slider = styled.button`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  background: ${(props) =>
    !props.autoCompoundOn ? colorGrayscaleBodyRGBA : colorInfoDefaultRGBA};
  border: none;
  border-radius: 100px;
  width: 50px;
  font-size: 10px;
  line-height: 15px;
  height: 17px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  :hover {
    background: ${(props) =>
      props.autoCompoundOn
        ? "rgba(252, 252, 252, 1)"
        : "rgba(160, 167, 189, 1)"};
  }
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${colorGrayscaleBody};
  ${(props) => (props.color ? `color:${props.color}` : "inherit")};
  text-transform: uppercase;
`;

const Option = styled.span`
  border-radius: 100px;
  padding: 0 3px;
  width: 50%;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const APROption = styled(Option)`
  ${(props) =>
    !props.autoCompoundOn && `background-color:${colorGrayscaleLabel}`};
  color: ${(props) =>
    !props.autoCompoundOn ? colorGrayscaleLine : colorInfoDark};
`;

const APYOption = styled(Option)`
  ${(props) => props.autoCompoundOn && `background-color:${colorInfoDefault}`};
  color: ${(props) =>
    props.autoCompoundOn ? colorGrayscaleOffWhite : colorGrayscaleLabel};
`;

AutoCompound.propTypes = {
  poolId: PropTypes.number,
};

/** Confirm Modal */

//TODO: share all styled with other modals

const CloseBtn = styled(XIcon)`
  cursor: pointer;
  margin-left: auto;
  margin-top: -20px;
`;

const timeIconsCSS = `width: 114px;
height: 114px;
margin-top: auto;
margin-bottom: 20px;`;

const StyledLockTimeIcon = styled(LockTimeIcon)`
  ${timeIconsCSS}
`;

const StyledUnlockTimeIcon = styled(UnlockTimeIcon)`
  ${timeIconsCSS}
`;

const CustomActionButton = styled(ActionButton)`
  border: ${(props) =>
    props.outline ? `1px solid ${props.outline}` : undefined};
  :hover {
    background-color: ${(props) =>
      props.autoCompoundOn ? colorErrorDark : colorInfoDark};
  }
`;

const BoldLabel = styled.span`
  color: ${(props) => (props.autoCompoundOn ? colorErrorDark : colorInfoDark)};
`;
