const { ethers } = require("ethers");
const { Contract } = require("ethers-multicall");
const { BigNumber } = require("ethers");

const { getApy } = require("./apy");
const {
  generateWithdrawAllPayload,
  generateWithdrawAllFarmAndVaultPayloads,
} = require("../utils/payloads");
const { format } = require("../utils/format");
const {
  getVaultsByToken,
  getAllVaultTokens,
  getAllVaults,
} = require("../utils/addresses.js");
const { getAllTokenToBusdRates } = require("./tokens");
const {
  getUnderlyingVaultContract,
  getUnderlyingFarmContract,
  getAutofarmStrategyContract,
} = require("./vault_providers");

const addresses = require("../../config/config").config.getNetworkAddresses();
const revaultAddress = addresses.revault;
const revaChefAddress = addresses.revaChef;

/* ABI's */

const zapAbi = require("../../abi/Zap.json");
const revaultAbi = require("../../abi/ReVault.json");
const revaChefAbi = require("../../abi/RevaChef.json");

/* FUNCTIONS */

async function getAllPositions(multicallProvider, ethersProvider) {
  const tokens = getAllVaultTokens();

  const revaChefContract = new Contract(revaChefAddress, revaChefAbi);

  // RevaChef token info
  const [revaPerBlock, totalLastUpdatedTvlBusd, ...tokensInfo] =
    await multicallProvider.all([
      revaChefContract.revaPerBlock(),
      revaChefContract.totalRevaultTvlBusd(),
      ...tokens.map((t) => revaChefContract.tokens(t.address)),
    ]);

  // Token rates
  const tokenRateMap = await getAllTokenToBusdRates(multicallProvider, true);

  const revaBusdPerYear = revaPerBlock
    .mul(tokenRateMap["reva"])
    .mul("10512000"); // blocks per year

  // Convert TVL to BUSD
  const tokensActualTvlBusd = tokens.map((t, i) =>
    tokenRateMap[t.symbol]
      .mul(tokensInfo[i].totalPrincipal)
      .div(ethers.utils.parseUnits("1", String(t.decimals))),
  );

  // Calculate REVA APR
  const tokensRevaApr = tokens.map((t, i) => {
    const tokenActualTvlBusd = tokensActualTvlBusd[i];
    if (tokenActualTvlBusd.eq("0") || tokensInfo[i].totalPrincipal.eq("0")) {
      return "0";
    }
    const tokenLastUpdatedTvlBusd = tokensInfo[i].tvlBusd;
    const revaApr = revaBusdPerYear
      .mul(tokenLastUpdatedTvlBusd)
      .div(totalLastUpdatedTvlBusd)
      .div(tokenActualTvlBusd);
    const revaAprPercentage = ethers.utils.formatEther(revaApr.mul(100));
    return parseFloat(revaAprPercentage).toFixed(2);
  });

  // Get vault TVL's
  const tvlsMap = await getAllUnderlyingTvls(
    multicallProvider,
    ethersProvider,
    true,
  );

  let positions = [];

  // Build positions data struct
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];

    const tvlBusd = tokensInfo[i].totalPrincipal
      .mul(tokenRateMap[token.symbol])
      .div(ethers.utils.parseUnits("1", String(token.decimals)));

    const tokenVaultsFiltered = getVaultsByToken(token.symbol);
    const tokenVaults = await Promise.all(
      tokenVaultsFiltered.map(async (vault) => {
        const apy = await getApy(vault.additionalData.vid);
        return {
          vaultId: vault.additionalData.vid,
          apy,
          tvlBusd: ethers.utils.formatEther(tvlsMap[vault.additionalData.vid]),
        };
      }),
    );

    positions.push({
      tokenId: token.tokenId,
      vaults: tokenVaults,
      revaApy: tokensRevaApr[i],
      tvl: ethers.utils.formatEther(tvlBusd),
      rate: tokenRateMap[token.symbol].toString(),
    });
  }

  return positions;
}

async function getAllUserPositions(
  ethersProvider,
  multicallProvider,
  userAddress,
) {
  const tokens = getAllVaultTokens();
  const allVaultsMD = getAllVaults();

  const userProxyContractAddress = await getUserProxyContractAddress(
    ethersProvider,
    userAddress,
  );

  const revaultContractEthers = new ethers.Contract(
    revaultAddress,
    revaultAbi,
    ethersProvider,
  );
  const reevaultContractMC = new Contract(revaultAddress, revaultAbi);
  const revaChefContractMC = new Contract(revaChefAddress, revaChefAbi);

  // Token rates
  const tokenRateMap = await getAllTokenToBusdRates(multicallProvider, true);

  const allVaultsData = {};

  // Get revault principals
  (
    await multicallProvider.all(
      allVaultsMD.map((vm) =>
        reevaultContractMC.userVaultPrincipal(
          vm.additionalData.vid,
          userAddress,
        ),
      ),
    )
  ).map((principal, i) => {
    const vm = allVaultsMD[i];
    allVaultsData[vm.additionalData.vid] = {
      vaultMetadata: vm,
      revaultPrincipal: principal,
      isDeposited: principal.gt("0"),
    };
  });

  // For each vault with a revault principal = get the underlying vault's principal
  const depositedVaults = Object.values(allVaultsData).filter(
    (vd) => vd.isDeposited,
  );
  (
    await multicallProvider.all(
      depositedVaults.map((vd) => {
        const vm = vd.vaultMetadata;
        const vaultContract = getUnderlyingVaultContract(
          multicallProvider,
          vm.additionalData.vid,
        );
        // Each vault provider has its own method of saving the principal
        if (vm.vaultProvider == "bunny" || vm.vaultProvider == "beefy") {
          return vaultContract.balanceOf(userProxyContractAddress);
        } else if (vm.vaultProvider == "autofarm") {
          return vaultContract.userInfo(
            vm.additionalData.pid,
            userProxyContractAddress,
          );
        } else if (vm.vaultProvider == "acryptos") {
          const farmContract = getUnderlyingFarmContract(
            multicallProvider,
            vm.additionalData.vid,
          );
          return farmContract.userInfo(vm.address, userProxyContractAddress);
        }
      }),
    )
  ).map((res, i) => {
    const vm = depositedVaults[i].vaultMetadata;

    // Parse result
    let underlyingPrincipal;
    if (vm.vaultProvider === "autofarm") underlyingPrincipal = res.shares;
    else if (vm.vaultProvider === "acryptos") underlyingPrincipal = res[0];
    else underlyingPrincipal = res;

    // Update vaults data with underlying principal + isActive
    Object.assign(allVaultsData[vm.additionalData.vid], {
      underlyingPrincipal,
      isActive: underlyingPrincipal.gt(
        ethers.utils.parseEther("0.0000000000000001"),
      ),
    });
  });

  // For each active vault - simulate a withdraw call to check how many withdrawable tokens are available
  const activeVaults = Object.values(allVaultsData).filter((vd) => vd.isActive);
  (
    await Promise.all(
      activeVaults.map(async (vd) => {
        const vm = vd.vaultMetadata;
        let depositTokenReturn, revaReturn;

        // Farm vault
        if (vm.additionalData.farmAddress) {
          const [withdrawFarmPayload, withdrawVaultPayload] =
            await generateWithdrawAllFarmAndVaultPayloads(
              userAddress,
              vm.additionalData.vid,
            );
          [depositTokenReturn, revaReturn] =
            await revaultContractEthers.callStatic.withdrawFromFarmAndVault(
              vm.additionalData.vid,
              withdrawFarmPayload,
              withdrawVaultPayload,
              { from: userAddress },
            );

          // Non-farm vault
        } else {
          const withdrawPayload = await generateWithdrawAllPayload(
            vm.additionalData.vid,
          );
          [depositTokenReturn, revaReturn] =
            await revaultContractEthers.callStatic.withdrawFromVault(
              vm.additionalData.vid,
              withdrawPayload,
              { from: userAddress },
            );
        }

        return [depositTokenReturn, revaReturn];
      }),
    )
  ).map(([depositTokenReturn, revaReturn], i) => {
    const vd = activeVaults[i];

    let tokensConsideredProfit = depositTokenReturn.sub(vd.revaultPrincipal);
    if (tokensConsideredProfit.lt("0")) {
      tokensConsideredProfit = ethers.utils.parseEther("0");
    }
    Object.assign(vd, {
      withdrawableTokens: depositTokenReturn,
      revaFromBuybacks: revaReturn,
      tokensConsideredProfit,
    });
    allVaultsData[vd.vaultMetadata.additionalData.vid] = vd;
  });

  // NOTE - assuming:
  // 1. Only bunny returns native tokens (BUNNY) [autofarm, acryptos, beefy don't]
  // 2. Bunny's withdrawAll function returns all vault tokens AS WELL AS native tokens
  // 3. Revault's withdrawAll function does not call RevaChef's claim
  //
  // Therefore:
  // 1. There's no need to simulate a harvest call (because all available tokens have already been accounted for by the withdrawAll call)
  // 2. There is a need to call RevaChef's claim

  // Get reva chef's pending reva
  (
    await multicallProvider.all(
      activeVaults.map((vd) =>
        revaChefContractMC.pendingReva(
          vd.vaultMetadata.depositTokenAddress,
          userAddress,
        ),
      ),
    )
  ).map((pendingReva, i) => {
    const vd = activeVaults[i];
    Object.assign(allVaultsData[vd.vaultMetadata.additionalData.vid], {
      revaFromChef: pendingReva,
    });
  });

  const exportedVaultsData = {};

  Object.values(allVaultsData).map((vd) => {
    // Active vault
    if (vd.isActive) {
      const token = tokens.find(
        (t) => t.symbol === vd.vaultMetadata.depositTokenSymbol,
      );
      const tokenToBusdRate = tokenRateMap[token.symbol];

      // Token rate is also saved in Wei so we divide by 10^18
      const principalBalanceBusd = tokenToBusdRate
        .mul(vd.revaultPrincipal)
        .div(BigNumber.from("10").pow("18"));
      const depositBalanceBusd = tokenToBusdRate
        .mul(vd.withdrawableTokens)
        .div(BigNumber.from("10").pow("18"));
      const depositRewardBusd = tokenToBusdRate
        .mul(vd.tokensConsideredProfit)
        .div(BigNumber.from("10").pow("18"));
      const totalRevaReward = vd.revaFromChef.add(vd.revaFromBuybacks);
      const revaRewardBusd = tokenRateMap["reva"]
        .mul(totalRevaReward)
        .div(BigNumber.from("10").pow("18"));
      const totalBalanceBusd = depositBalanceBusd.add(revaRewardBusd);

      exportedVaultsData[vd.vaultMetadata.additionalData.vid] = {
        ...vd.vaultMetadata,
        principalNative: format(vd.revaultPrincipal, token.decimals), // principal == how much user deposited
        principalBalanceBusd: format(principalBalanceBusd),
        depositTokenBalance: format(vd.withdrawableTokens, token.decimals), // balance == how much is available to withdraw
        depositTokenBalanceBusd: format(depositBalanceBusd),
        depositTokenReward: format(vd.tokensConsideredProfit, token.decimals), // profit == how much is considered profit for fees and buybacks (basically balance - principal)
        depositTokenRewardBusd: format(depositRewardBusd),
        revaReward: format(totalRevaReward), // reva profit == how much reva is available to claim (from buybacks + from chef minting)
        revaRewardBusd: format(revaRewardBusd),
        totalBalanceBusd: format(totalBalanceBusd), // total balance = token balance + reva profits
      };

      // Inactive vault
    } else {
      exportedVaultsData[vd.vaultMetadata.additionalData.vid] = {
        ...vd.vaultMetadata,
        principalNative: "0",
        principalBalanceBusd: "0",
        depositTokenBalance: "0",
        depositTokenBalanceBusd: "0",
        depositTokenReward: "0",
        depositTokenRewardBusd: "0",
        revaReward: "0",
        revaRewardBusd: "0",
        totalBalanceBusd: "0",
      };
    }
  });

  // Return mapping by token: [ { tokenId, userVaults: [{}, {}, {}] }, { t, u }, {...} ]
  const vaultsByToken = tokens.map((token) => {
    const tokenVaultsMD = getVaultsByToken(token.symbol);
    const userData = tokenVaultsMD.map(
      (vm) => exportedVaultsData[vm.additionalData.vid],
    );
    return {
      tokenId: token.tokenId,
      userVaults: userData,
    };
  });

  return vaultsByToken;
}

async function getAllUnderlyingTvls(
  multicallProvider,
  ethersProvider,
  inBusd = true,
) {
  const vaults = getAllVaults();

  const nativeTvlsArray = await multicallProvider.all(
    await Promise.all(
      vaults.map(async (vault) => {
        const underlyingVaultContract = getUnderlyingVaultContract(
          multicallProvider,
          vault.additionalData.vid,
        );

        if (vault.vaultProvider == "bunny") {
          return underlyingVaultContract.balance();
        } else if (vault.vaultProvider == "beefy") {
          return underlyingVaultContract.balance();
        } else if (vault.vaultProvider == "autofarm") {
          const autofarmStrategyContract = await getAutofarmStrategyContract(
            ethersProvider,
            vault.additionalData.vid,
            multicallProvider,
          );
          return autofarmStrategyContract.wantLockedTotal();
        } else if (vault.vaultProvider === "acryptos") {
          return underlyingVaultContract.balance();
        } else {
          throw new Error(`Unrecognized provider ${vault.vaultProvider}`);
        }
      }),
    ),
  );

  let tvlsArray = nativeTvlsArray;
  if (inBusd) {
    const zapContract = new Contract(addresses.zap, zapAbi);
    tvlsArray = await multicallProvider.all(
      vaults.map((vault, i) =>
        zapContract.getBUSDValue(vault.depositTokenAddress, nativeTvlsArray[i]),
      ),
    );
  }

  const tvlsMap = {};
  vaults.map((vault, i) => {
    tvlsMap[vault.additionalData.vid] = tvlsArray[i];
  });
  return tvlsMap;
}

function getRevaultContract(provider) {
  return new ethers.Contract(addresses.revault, revaultAbi, provider);
}

async function getUserProxyContractAddress(provider, userAddress) {
  const revaultContract = getRevaultContract(provider);
  return revaultContract.userProxyContractAddress(userAddress);
}

export { getAllUserPositions, getAllPositions, getAllUnderlyingTvls };
