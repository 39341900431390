import React from "react";
import PropTypes from "prop-types";
import InfoItem from "components/InfoItem";
import { colorInfoDefault } from "utils/colors";
import { POOL_TIERS } from "../../../helpers/utils";
import NumberFormatter from "../../../components/NumberFormatter";
import { colorGrayscaleLabel } from "utils/colors";
import { FlexContainer } from "containers/common/styles";
import {
  BoostButton,
  Controls,
  Info,
  RadioButton,
  StyledAprBadge,
  StyledApyBadge,
} from "../StakeScreen.styles";

export default function StakePools({
  userPositions,
  pools,
  selectedPool,
  onSelect,
  disableNonStakedPools,
}) {
  const {
    apy,
    apr,
    lockPeriod,
    multiplier: selectedPoolSymbol,
    totalRevaStaked,
  } = pools[selectedPool];

  const powerShareTier = POOL_TIERS[selectedPoolSymbol];

  const lockPeriodInDays = lockPeriod / 60 / 60 / 24;
  const isCompounding = userPositions[selectedPool].isCompounding;
  const poolsInfoDetails = generatePoolsInfoDetails({
    isCompounding,
    apy,
    apr,
    totalRevaStaked,
    powerShareTier,
    lockPeriodInDays,
  });

  return (
    <FlexContainer css="margin: 0 0 3rem 0;" justify="space-between" col>
      <Controls>
        {pools.map((pool, index) => {
          const { multiplier } = pool;
          const staked = userPositions
            ? userPositions[index]?.revaStaked
            : undefined;
          const isStaked = staked.gt(0);
          const isComp = userPositions[index]?.isCompounding;
          const disabled = disableNonStakedPools && staked.eq(0);
          const cssProp =
            index === 0
              ? "border-top-left-radius: 10px;"
              : index === pools.length - 1
              ? "border-top-right-radius: 10px;"
              : null;
          return (
            <BoostButton
              key={multiplier}
              highlight={isStaked}
              onClick={() => (!disabled ? onSelect(index) : null)}
              selected={selectedPoolSymbol === multiplier}
              css={cssProp}
              disabled={disabled}
            >
              <FlexContainer col>
                <RadioButton
                  type="radio"
                  id={multiplier}
                  name="boost-options"
                  value={multiplier}
                  checked={selectedPoolSymbol === multiplier}
                  readOnly
                  disabled={disabled}
                />
                <label htmlFor={multiplier}>X{multiplier}</label>
                {isStaked ? (
                  isComp ? (
                    <StyledApyBadge />
                  ) : (
                    <StyledAprBadge />
                  )
                ) : null}
              </FlexContainer>
            </BoostButton>
          );
        })}
      </Controls>
      <Info>
        {poolsInfoDetails.map((item, index) => {
          return (
            <InfoItem
              style="width:100%"
              {...item}
              key={item.subtitle + index}
            />
          );
        })}
      </Info>
    </FlexContainer>
  );
}

const generatePoolsInfoDetails = ({
  isCompounding,
  apy,
  apr,
  lockPeriodInDays,
  powerShareTier,
  totalRevaStaked,
}) => {
  let poolsInfoDetails = [
    {
      title: (
        <NumberFormatter
          value={isCompounding ? apy : apr}
          suffix="%"
          withToolTip
        />
      ),
      subtitle: `Reva  ${isCompounding ? "Apy" : "Apr"}`,
      subtitleStyle: isCompounding ? `color : ${colorInfoDefault};` : undefined,
    },
    {
      title: lockPeriodInDays,
      subtitle: "Days Lock",
      titleStyle:
        lockPeriodInDays === 0 ? `color: ${colorGrayscaleLabel};` : undefined,
    },
    { title: powerShareTier, subtitle: "Power Share" },
    {
      title: <NumberFormatter value={totalRevaStaked} withToolTip />,
      subtitle: "Total Reva Staked",
    },
  ];

  const commonPoolsInfoStyle = {
    title: "font-size: 24px",
    subtitle: "font-size: 12px",
  };

  poolsInfoDetails = poolsInfoDetails.map((item) => ({
    ...item,
    titleStyle: item.titleStyle
      ? item.titleStyle.concat(commonPoolsInfoStyle.title) // item.titleStyle string should end with ';' for the css string to work
      : commonPoolsInfoStyle.title,
    subtitleStyle: item.subtitleStyle
      ? item.subtitleStyle.concat(commonPoolsInfoStyle.subtitle) // item.subtitleStyle string should end with ';' for the css string to work
      : commonPoolsInfoStyle.subtitle,
  }));
  return poolsInfoDetails;
};

StakePools.propTypes = {
  pools: PropTypes.array.isRequired,
  userPositions: PropTypes.array,
  selectedPool: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  disableNonStakedPools: PropTypes.bool,
};

/** Styled Components */
