import { RevaApi } from "apis";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../utils/constants";

export const useRevaUSDprice = () => {
  try {
    const revaApi = RevaApi();
    const result = useQuery({
      queryKey: QUERY_KEYS.revaUSDprice,
      queryFn: () => revaApi.stats(),
    });

    return { ...result, price: parseFloat(result?.data?.revaPrice) };
  } catch (error) {
    console.log({ error });
  }
};
