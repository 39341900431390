import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "../../helpers/hooks";
import { useWallet } from "hooks/wallet.hooks";
import { isMetaMaskAvailable } from "../../helpers/utils";

import {
  Content,
  Wallets,
  Wallet,
  MoreInfoLink,
  Notice,
} from "./ConnectorSelection.styles";

import { SUPPORTED_WALLETS } from "utils/wallets";

const howToUrl =
  "https://revault-network.gitbook.io/revault-network/platform/how-to-use-the-mvp/connect-metamask-to-revault-platform";
const learnHowUrl =
  "https://revault-network.gitbook.io/revault-network/platform/how-to-use-the-mvp";

export default function ConnectorSelection({ onClose }) {
  const ref = useRef();
  const { logIn } = useWallet();

  useOnClickOutside(ref, onClose);

  return (
    <Content ref={ref}>
      <Content.Title>Connect your wallet</Content.Title>
      <Content.Subtitle>
        Please make sure you connected to Binance Chain Network account only!
        <MoreInfoLink href={howToUrl} target="_blank" rel="noopener noreferrer">
          How to?
        </MoreInfoLink>
      </Content.Subtitle>
      <Wallets>
        <Wallet
          onClick={async () => {
            onClose();
            if (isMetaMaskAvailable()) {
              logIn(SUPPORTED_WALLETS.INJECTED.connector);
            } else {
              const win = window.open("https://metamask.io/", "_blank");
              if (win != null) {
                win.focus();
              }
            }
          }}
        >
          <Wallet.Icon src={SUPPORTED_WALLETS.INJECTED.iconBig} />
          <Wallet.Title>{SUPPORTED_WALLETS.INJECTED.name}</Wallet.Title>
        </Wallet>
        <Wallet
          onClick={async () => {
            onClose();
            logIn(SUPPORTED_WALLETS.WALLET_CONNECT.connector);
          }}
        >
          <Wallet.Icon src={SUPPORTED_WALLETS.WALLET_CONNECT.iconBig} />
          <Wallet.Title>{SUPPORTED_WALLETS.WALLET_CONNECT.name}</Wallet.Title>
        </Wallet>
        <Wallet
          onClick={async () => {
            onClose();
            logIn(SUPPORTED_WALLETS.BINANCE_CHAIN.connector);
          }}
        >
          <Wallet.Icon src={SUPPORTED_WALLETS.BINANCE_CHAIN.iconBig} />
          <Wallet.Title>{SUPPORTED_WALLETS.BINANCE_CHAIN.name}</Wallet.Title>
        </Wallet>
      </Wallets>
      <Notice>
        Haven’t got a crypto wallet yet?
        <MoreInfoLink
          href={learnHowUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn How
        </MoreInfoLink>
      </Notice>
    </Content>
  );
}

ConnectorSelection.propTypes = {
  onClose: PropTypes.func,
};
