import React from "react";
import { PropTypes } from "prop-types";
import { Divider } from "components/Common.styles";
import NumAndTitleElement from "../../../components/NumAndTitleElement";
import { useFarmingData } from "hooks/farming.hooks";
import { Operator, FlexContainer } from "containers/common/styles";
import Big from "big.js";

export default function DoubleTokenInPoolInfo({ firstToken, secondToken }) {
  const {
    isLoading,
    data: { lpPoolPositions, busdRate },
  } = useFarmingData({
    firstTokenSymbol: firstToken.title,
    secondTokenSymbol: secondToken.title,
  });

  if (isLoading) {
    return null;
  }

  const { pendingReva: revaEarned, lpStaked } = lpPoolPositions[0];

  return (
    <FlexContainer justify="space-between" css="padding: 1rem 0">
      <NumAndTitleElement
        number={firstToken.number}
        title={`${firstToken.title} in pool`}
        isFirstWordBold
      />
      <Operator>+</Operator>
      <NumAndTitleElement
        number={secondToken.number}
        title={`${secondToken.title} in pool`}
        isFirstWordBold
      />
      <Operator>=</Operator>
      <NumAndTitleElement number={lpStaked} title="lp deposited" />
      <Divider />

      <NumAndTitleElement
        number={lpStaked.times(busdRate)}
        title="lp value"
        numberPrefix="$"
      />
      <Divider />
      <NumAndTitleElement number={revaEarned} title={"reva earned"} />
    </FlexContainer>
  );
}

DoubleTokenInPoolInfo.propTypes = {
  firstToken: PropTypes.shape({
    title: PropTypes.string,
    number: PropTypes.instanceOf(Big),
  }),
  secondToken: PropTypes.shape({
    title: PropTypes.string,
    number: PropTypes.instanceOf(Big),
  }),
};
