import styled from "styled-components";

import { colorGrayscaleBody, colorGrayscaleOffWhite } from "../utils/colors";

export const Divider = styled.div`
  height: 1px;
  opacity: 0.4;
  background-color: ${colorGrayscaleBody};
`;

export const VerticalSpacer = styled.div`
  flex: 1;
`;

export const Line = styled.div`
  border-left: 2px solid
    ${(props) => (props.color ? `${props.color}` : `${colorGrayscaleOffWhite}`)};
  height: 75px;
  width: 20px;
  transform: ${(props) => (props.rotate ? `rotate(${props.rotate})` : "none")};
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  gap: ${(props) => (props.gap ? props.gap : "0")};
  align-items: center;
  justify-content: center;
`;
