import _ from "lodash";
// when integrating with frontend this should be window.fetch?
const fetch = window.fetch;

const { getVault, getAllVaults } = require("../utils/addresses");

// mvp cheat. we can either run our own (open source), or finish onchain apy calculator
const beefyApyUrl = "https://api.beefy.finance/apy/breakdown";
const autoApyUrl = "https://static.autofarm.network/polygon/farm_data.json";
const bunnyApyUrl =
  "https://us-central1-bunny-polygon.cloudfunctions.net/api-bunnyData";
const acryptosApyUrl = "https://api.unrekt.net/api/v1/acryptos-asset";

/*
 * CORS Proxy
 * @see: https://blog.jim-nielsen.com/2020/a-cors-proxy-with-netlify/
 */
const PROXY =
  window.location.hostname === "localhost"
    ? "https://secure-caverns-86637.herokuapp.com" // this is an app on amir's heroku's account
    : "/cors-proxy"; // this is a netlify function

let cache = {};

async function getAllApys() {
  const apys = await Promise.all(
    getAllVaults().map(async (v) => ({
      vaultProvider: v.vaultProvider,
      symbol: v.depositTokenSymbol,
      apy: await getApy(v.additionalData.vid),
    })),
  );
  return apys;
}

async function getApy(vaultId) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider === "autofarm") {
    const apy = await getAutofarmApy(vault);
    return (100 * parseFloat(apy)).toFixed(2);
  } else if (vault.vaultProvider === "beefy") {
    const apy = await getBeefyApy(vault);
    return (100 * parseFloat(apy)).toFixed(2);
  } else if (vault.vaultProvider === "bunny") {
    const apy = await getBunnyApy(vault);
    return parseFloat(apy).toFixed(2);
  } else if (vault.vaultProvider === "acryptos") {
    const apy = await getAcryptosApy(vault);
    return apy.toFixed(2);
  }
}

async function getAutofarmApy(vault) {
  if (!cache["autofarm"]) {
    try {
      const res = await fetch(`${PROXY}/${autoApyUrl}`, { mode: "cors" });
      cache["autofarm"] = await res.json();
    } catch (e) {
      return 0;
    }
  }
  const apyBreakdown = cache["autofarm"]?.pools[vault?.additionalData?.pid];

  if (!_.isNil(apyBreakdown) && !_.isNil(apyBreakdown["APY_total"])) {
    return apyBreakdown["APY_total"];
  } else {
    return 0;
  }
}

async function getBeefyApy(vault) {
  if (!cache["beefy"]) {
    try {
      const res = await fetch(beefyApyUrl, { mode: "cors" });
      cache["beefy"] = await res.json();
    } catch (e) {
      return 0;
    }
  }

  let apyBreakdown;

  if (vault.additionalData.protocol === "aave") {
    let depositTokenSymbol = vault.depositTokenSymbol;
    if (["weth"].includes(depositTokenSymbol)) {
      depositTokenSymbol = depositTokenSymbol.slice(1);
    }
    apyBreakdown = cache["beefy"][`aave-${depositTokenSymbol}`];
  } else if (vault.additionalData.protocol === "quickswap") {
    apyBreakdown = cache["beefy"][`quick-${vault.depositTokenSymbol}`];
    if (!apyBreakdown && vault.depositTokenSymbol === "matic-eth") {
      apyBreakdown = cache["beefy"]["quick-eth-matic"];
    }
  }

  if (!_.isNil(apyBreakdown) && !_.isNil(apyBreakdown.totalApy)) {
    return apyBreakdown.totalApy;
  } else {
    return 0;
  }
}

async function getBunnyApy(vault) {
  if (!cache["bunny"]) {
    try {
      const res = await fetch(bunnyApyUrl, { mode: "cors" });
      cache["bunny"] = await res.json();
    } catch (e) {
      return 0;
    }
  }

  const bunnyApy = cache["bunny"]?.apy[vault.address]?.apy;

  if (!_.isNil(bunnyApy)) {
    return bunnyApy;
  } else {
    return 0;
  }
}

async function getAcryptosApy(vault) {
  if (!cache["acryptos"]) {
    const res = await fetch(acryptosApyUrl, { mode: "cors" });
    cache["acryptos"] = await res.json();
  }

  // api uses btc not btcb (now handles LP)
  let depositTokenSymbol = vault.depositTokenSymbol.replace("btcb", "btc");

  // keys are in uppercase
  depositTokenSymbol = depositTokenSymbol.toUpperCase();

  // TODO: implement lookup by address instead of token symbol
  // For now this redirects to the right key
  if (
    depositTokenSymbol === "BUSD" &&
    vault.address === "0x14B197CA1A5Aef891e86E1EaE7A110c865c7DCec"
  )
    depositTokenSymbol = "channelBUSD";

  const info = cache["acryptos"].assets[depositTokenSymbol];
  const apy = parseFloat(info.apyvault) + parseFloat(info.aprfarm);
  return apy;
}

export { getApy, getAllApys };
