import { getToken } from "apis/utils/addresses";
import { useQuery } from "react-query";
import { fetchTokenToBusdRate } from "services/api/rates";
import { REFETCHING_INTERVALS } from "utils/constants";
import { QUERY_KEYS } from "utils/constants";

export const useBUSDrate = ({ tokenSymbol }) => {
  const result = useQuery(
    QUERY_KEYS.revaToBusdRate,
    async () => {
      const { tokenId } = getToken(tokenSymbol);
      const busdRate = await fetchTokenToBusdRate({ tokenId });
      return busdRate;
    },
    {
      refetchInterval: REFETCHING_INTERVALS.HOOKS.BUSD_RATE,
    },
  );
  return { ...result, busdRate: result.data };
};
