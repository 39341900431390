import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Header from "components/Header/Header";
import Tabs from "components/Tabs";
import {
  ActionButton,
  ActionButtons,
  AssetViewContainer,
  Root,
  FlexContainer,
} from "../common/styles.js";
import { PageSubtitle, PageTitle } from "../common/page.styles.js";
import { PanelContainer } from "../common/panel.styles.js";
import AssetView from "components/AssetView";
import {
  colorGrayscaleBody,
  colorPrimaryDefault,
  colorGrayscaleOffWhite,
} from "utils/colors";
import LoadingSpinner from "components/LoadingSpinner";
import * as coinTypes from "utils/constants";
import ModalConnect from "components/ModalConnect";
import {
  claimRevaLP,
  depositRevaLP,
  withdrawRevaLP,
} from "services/api/farming";
import { useMutation } from "react-query";
import { getToken } from "apis/utils/addresses";
import { onTransaction } from "../../utils";
import { PANCAKESWAP_ADD_BNB_URL } from "utils/constants";
import {
  useApproveToken,
  useIsTokenApproved,
} from "../../hooks/approveToken.hooks";
import { config } from "../../config/config";
import { ActionButton as ConnectWalletButton } from "components/PositionSidePane/ControlPanel.styles";
import DoubleTokenLogoAndName from "./partials/DoubleTokenLogosAndNames";
import FarmingLoader from "./partials/FarmingLoader";
import { FarmingTabs, FirstRow } from "./styles/index.js";
import FarmingInfo from "./partials/FarmingInfo";
import DoubleTokenInPoolInfo from "./partials/DoubleTokenInPoolInfo";
import { useFarmingData, useRevaLPBalance } from "hooks/farming.hooks.js";
import Link from "components/Link";

const addresses = config.getNetworkAddresses();

const doubleTokenSymbol = "REVA-MATIC";

export const FarmingModes = Object.freeze({
  Deposit: "Deposit",
  Withdraw: "Withdraw",
});

const firstTokenSymbol = "reva";
const secondTokenSymbol = "matic";
const dubleTokenSymbol = `${firstTokenSymbol}-${secondTokenSymbol}`;

export default function FarmingScreen() {
  const { isConnected } = useSelector((state) => state.globalState);

  // Initial queries
  const { refetch: farmingDataRefetch } = useFarmingData({
    firstTokenSymbol,
    secondTokenSymbol,
    isConnected,
  });

  let { refetch: balanceRefetch } = useRevaLPBalance({
    refetchInterval: 3000,
    isConnected,
  });

  function refreshData() {
    farmingDataRefetch();
    balanceRefetch();
  }

  const depositMutation = useMutation(
    ({ amount }) => {
      return depositRevaLP({
        poolId: 0,
        amount,
      });
    },
    {
      onSuccess: (txHash) =>
        onTransaction({
          txHash,
          callback: refreshData,
        }),
    },
  );

  const withdrawMutation = useMutation(
    ({ amount }) => {
      return withdrawRevaLP({ poolId: 0, amount });
    },
    {
      onSuccess: (txHash) =>
        onTransaction({
          txHash,
          callback: refreshData,
        }),
    },
  );

  const claimMutation = useMutation(
    ({ poolId }) => {
      return claimRevaLP({ poolId });
    },
    {
      onSuccess: (txHash) =>
        onTransaction({
          txHash,
          callback: refreshData,
        }),
    },
  );

  const onClaim = () => {
    return claimMutation.mutate({ poolId: 0 });
  };

  const onDeposit = (amount) => {
    return depositMutation.mutate({ amount });
  };

  const onWithdraw = (amount) => {
    return withdrawMutation.mutate({ amount });
  };

  const { data: isRevaLpTokenApproved } = useIsTokenApproved({
    tokenAddress: addresses[dubleTokenSymbol],
    targetAddress: addresses.revaLpStakingPool,
  });
  const { mutate: approveRevaLpToken, isLoading: isApprovingRevaLpToken } =
    useApproveToken({
      params: {
        tokenAddress: addresses[dubleTokenSymbol],
        targetAddress: addresses.revaLpStakingPool,
      },
    });

  return (
    <Root>
      <Header isConnected={isConnected} />

      <div>
        <PageTitle>Earn REVA as Liquidity Provider</PageTitle>
        <PageSubtitle>
          Maximize your REVA rewards by providing liquidity via pancakeswap and
          deposit your REVA-MATIC LP tokens
        </PageSubtitle>
      </div>
      <FarmingTabs>
        <Tabs
          tabs={[
            {
              title: FarmingModes.Deposit + " LP Tokens",
              disabled: !isConnected,
            },
            {
              title: FarmingModes.Withdraw + " LP Tokens",
              disabled: !isConnected,
            },
          ]}
          panels={[
            <ContentPanel
              key={FarmingModes.Deposit}
              content={{ type: FarmingModes.Deposit }}
              mutations={{
                depositMutation,
                withdrawMutation,
                claimMutation,
                isRevaLpTokenApproved,
                isApprovingRevaLpToken,
              }}
              actions={{
                onDeposit,
                onWithdraw,
                onClaim,
                approveRevaLpToken,
              }}
            />,
            <ContentPanel
              key={FarmingModes.Withdraw}
              content={{ type: FarmingModes.Withdraw }}
              mutations={{
                depositMutation,
                withdrawMutation,
                claimMutation,
                isRevaLpTokenApproved,
                isApprovingRevaLpToken,
              }}
              actions={{
                onDeposit,
                onWithdraw,
                onClaim,
                approveRevaLpToken,
              }}
            />,
          ]}
        />
      </FarmingTabs>
    </Root>
  );
}

function ContentPanel({
  content,
  mutations: {
    depositMutation,
    withdrawMutation,
    claimMutation,
    isRevaLpTokenApproved,
    isApprovingRevaLpToken,
  },
  actions: { onDeposit, onWithdraw, onClaim, approveRevaLpToken },
}) {
  const [amount, setAmount] = useState();
  const { isConnected } = useSelector((state) => state.globalState);

  const {
    isLoading,
    data: { lpPoolPositions, liquidityRatio, busdRate },
  } = useFarmingData({
    firstTokenSymbol,
    secondTokenSymbol,
    isConnected,
  });

  let { data: balance } = useRevaLPBalance({
    refetchInterval: 3000,
    isConnected,
  });

  if (isLoading) {
    return (
      <FlexContainer
        style={{
          height: 380,
          width: 708,
          marginLeft: -136,
        }}
      >
        <FarmingLoader />
      </FlexContainer>
    );
  }

  const { portion0: revaPortion, portion1: maticPortion } = liquidityRatio;
  const { lpStaked } = lpPoolPositions[0];

  const revaStaked = lpStaked.times(revaPortion);
  const maticStaked = lpStaked.times(maticPortion);
  const revaDetails = {
    number: revaStaked,
    title: firstTokenSymbol,
    type: coinTypes.TOKEN_CODE_REVA,
  };

  const maticDetails = {
    number: maticStaked,
    title: secondTokenSymbol,
    type: coinTypes.TOKEN_CODE_MATIC,
  };

  const isActionButtonDisabled =
    amount <= 0 ||
    (content.type === FarmingModes.Deposit &&
      !(0 <= amount && balance.gte(amount))) ||
    (content.type === FarmingModes.Withdraw &&
      !(0 <= amount && lpStaked.gte(amount)));

  // const revaTokenAddress = getToken("reva").address;
  const getTokenLink = "https://quickswap.exchange/#/swap"; // PANCAKESWAP_ADD_BNB_URL + revaTokenAddress;

  const precentageButtonsConfig = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 75 },
    { value: 100 },
  ];

  return (
    <FlexContainer col>
      <PanelContainer>
        <FirstRow>
          <DoubleTokenLogoAndName
            firstToken={revaDetails}
            secondToken={maticDetails}
          />
          <FarmingInfo
            firstTokenSymbol={firstTokenSymbol}
            secondTokenSymbol={secondTokenSymbol}
          />
        </FirstRow>
        <DoubleTokenInPoolInfo
          firstToken={revaDetails}
          secondToken={maticDetails}
        />

        <AssetViewContainer>
          <AssetView
            assetTitle={doubleTokenSymbol.toUpperCase()}
            leftFrameTextTitleTextTransform="uppercase"
            textTransform="uppercase"
            leftFrameTitle={`${content.type} LP Tokens`}
            tokenDetails={{
              symbol: "reva",
              codes: [coinTypes.TOKEN_CODE_REVA, coinTypes.TOKEN_CODE_MATIC],
            }}
            balance={content.type === FarmingModes.Deposit ? balance : lpStaked}
            amountText={!isNaN(amount) ? amount.toString() : amount}
            onValueChange={setAmount}
            busdPerToken={busdRate}
            rightFrameTitle={
              content.type === FarmingModes.Withdraw ? (
                ""
              ) : (
                <Link
                  to={getTokenLink}
                  text={`Get ${doubleTokenSymbol} LP`}
                  external
                  color={colorPrimaryDefault}
                  hoverColor={colorGrayscaleOffWhite}
                />
              )
            }
            balancePrecentageShortcutsConfig={
              content.type === FarmingModes.Withdraw
                ? precentageButtonsConfig
                : undefined
            }
          />
        </AssetViewContainer>
        {isConnected ? (
          <ActionButtons>
            <ActionButton
              text={claimMutation.isLoading ? <LoadingSpinner /> : "Claim"}
              loadingText="Claiming..."
              loading={claimMutation.isLoading}
              outline={colorPrimaryDefault}
              onClick={onClaim}
              background="none"
              disabled={lpStaked.eq(0)}
            />
            {isRevaLpTokenApproved ? (
              <ActionButton
                text={
                  content.type === FarmingModes.Deposit ? (
                    depositMutation.isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      FarmingModes.Deposit
                    )
                  ) : withdrawMutation.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    FarmingModes.Withdraw + " & Claim"
                  )
                }
                loadingText={
                  depositMutation.isLoading ? "Depositing..." : "Withdrawing..."
                }
                loading={
                  depositMutation.isLoading || withdrawMutation.isLoading
                }
                disabled={isActionButtonDisabled}
                outline={
                  isActionButtonDisabled ? colorGrayscaleBody : undefined
                }
                background={colorPrimaryDefault}
                color={colorGrayscaleOffWhite}
                onClick={() => {
                  content.type === FarmingModes.Deposit
                    ? onDeposit(amount)
                    : onWithdraw(amount);
                  setAmount(0);
                }}
              />
            ) : (
              <ActionButton
                text={
                  isApprovingRevaLpToken ? (
                    <LoadingSpinner />
                  ) : (
                    `Approve ${doubleTokenSymbol}`
                  )
                }
                loading={isApprovingRevaLpToken}
                loadingText="Approving..."
                color={colorGrayscaleOffWhite}
                onClick={approveRevaLpToken}
              />
            )}
          </ActionButtons>
        ) : (
          <ModalConnect
            isConnected={isConnected}
            customButton={
              <ConnectWalletButton
                style={{ minHeight: 60, padding: "10px 0", marginTop: "30px" }}
              >
                Connect Wallet
              </ConnectWalletButton>
            }
          />
        )}
      </PanelContainer>
    </FlexContainer>
  );
}

ContentPanel.propTypes = {
  content: PropTypes.object,
  mutations: PropTypes.object, // TODO: convert to shape
  actions: PropTypes.object, // TODO: convert to shape
};
