import React from "react";
import styled from "styled-components";
import { Title } from "components/TokenPair/index.styles";
import ReactTooltip from "react-tooltip";
import { ReactComponent as RightArrow } from "assets/icons/stakescreen/rightArrow.svg";
import {
  colorGrayscaleBody,
  colorGrayscaleLabel,
  colorGrayscaleOffWhite,
  colorInfoDefault,
  colorBackgroundDark,
  colorBackgroundDark2,
  colorBackground,
} from "utils/colors.js";
import PropTypes from "prop-types";
import NumberFormatter from "components/NumberFormatter";

export default function AutoCompoundTooltip({ apr, apy, wrapperId }) {
  return (
    <ReactTooltipStyled id={wrapperId} backgroundColor={"transparent"}>
      <ToolTip>
        <Container>
          <StyledTitle>Auto Compound Meaning</StyledTitle>
          <ColoredText size={14} color={colorGrayscaleLabel}>
            Earned REVA automatically restaked and gradually increases your REVA
            emission
          </ColoredText>
          <Wrapper
            width={150}
            style={{
              margin: "10px 0px",
            }}
          >
            <ColoredText color={colorInfoDefault}>Auto Compound</ColoredText>
            <CheckBoxWrapper>
              <CheckBox id="switch-checkbox" type="checkbox" />
              <CheckBoxLabel htmlFor="switch-checkbox" />
            </CheckBoxWrapper>
          </Wrapper>
          <Wrapper>
            <APRBeforeAfter
              color={`linear-gradient(180deg, ${colorBackgroundDark} 0%, ${colorGrayscaleBody} 100%)`}
            >
              <ColoredText size={"10"} color={colorGrayscaleLabel}>
                Auto Comp. OFF
              </ColoredText>
              <ColoredText size={"24"} color={colorGrayscaleOffWhite}>
                <NumberFormatter value={apr} suffix="%" />
              </ColoredText>
              <ColoredText size={"10"} color={colorGrayscaleLabel}>
                APR
              </ColoredText>
            </APRBeforeAfter>
            <RightArrow />
            <APRBeforeAfter
              color={`linear-gradient(0deg, ${colorBackground} 0%, ${colorBackgroundDark2} 100%)`}
            >
              <ColoredText size={"10"} color={colorInfoDefault}>
                Auto Comp. ON
              </ColoredText>
              <ColoredText size={"24"} color={colorGrayscaleOffWhite}>
                <NumberFormatter value={apy} suffix="%" />
              </ColoredText>
              <ColoredText size={"10"} color={colorInfoDefault}>
                APY
              </ColoredText>
            </APRBeforeAfter>
          </Wrapper>
        </Container>
      </ToolTip>
    </ReactTooltipStyled>
  );
}

AutoCompoundTooltip.propTypes = {
  apr: PropTypes.number,
  apy: PropTypes.number,
  wrapperId: PropTypes.string,
};

const APRBeforeAfter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) => `background:${props.color}`};
  height: 80px;
  width: 110px;
  border-radius: 0px 0px 5px 5px;
  padding: 5px;
`;

const ColoredText = styled.span`
  ${(props) => (props.size ? `font-size:${props.size}px` : "font-size : 12px")};
  line-height: 18px;
  ${(props) => (props.color ? `color:${props.color}` : "inherit")};
  margin-bottom: 5px;
  text-align: left;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  margin: auto;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 14px;
  border-radius: 65px;
  background: ${colorInfoDefault};
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin: 2.4px;
    margin-left: auto;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.start ? "align-items: flex-start" : "align-items: center"}
  justify-content: center;
  height: 100%;
`;

const StyledTitle = styled(Title)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

const ToolTip = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 290px;
  height: 210px;
  border-radius: 10px;
  opacity: 1 !important;
  background: rgba(20, 22, 43, 1);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props) => (props.width ? `width:${props.width}px` : "100%")};
`;

//override transparent background
const ReactTooltipStyled = styled(ReactTooltip)`
  &.__react_component_tooltip.show {
    opacity: 1;
  }
`;
