import ContentLoader from "react-content-loader";
import React from "react";

const StakeLoader = (props) => (
  <ContentLoader
    speed={2}
    width={460}
    height={482}
    viewBox="0 0 460 482"
    backgroundColor="rgba(38, 43, 69, 0.4)"
    foregroundColor="rgba(255, 255, 255, 0.1)"
    {...props}
  >
    <rect x="81" y="9" rx="0" ry="0" width="271" height="21" />
    <rect x="0" y="60" rx="5" ry="5" width="460" height="100" />
    <rect x="0" y="180" rx="4" ry="4" width="460" height="97" />
    <rect x="0" y="336" rx="6" ry="6" width="460" height="44" />
    <rect x="8" y="435" rx="0" ry="0" width="66" height="33" />
    <rect x="365" y="435" rx="0" ry="0" width="66" height="33" />
    <rect x="184" y="435" rx="0" ry="0" width="66" height="33" />
    <rect x="0" y="473" rx="0" ry="0" width="82" height="15" />
    <rect x="177" y="473" rx="0" ry="0" width="82" height="15" />
    <rect x="358" y="473" rx="0" ry="0" width="82" height="15" />
  </ContentLoader>
);

export default StakeLoader;
