import React, { useState, useRef } from "react";
import styled from "styled-components";
import {
  colorGrayscaleBody,
  colorGrayscaleDark,
  colorGrayscaleOffWhite,
  colorGrayscalePlaceholder,
  colorGrayscaleInputBackground,
} from "../../utils/colors";
import Drawer from "../Drawer";
import InfoItem from "../InfoItem/InfoItem";
import PropTypes from "prop-types";
import Logo from "../Logo";
import { StyledLink } from "../AssetView/index.styles";
import { Title } from "../PositionSidePane/ControlPanel.styles";
import iconChevronUp from "../../assets/chevron-up-white.png";
import iconChevronDown from "../../assets/chevron-down-white.svg";
import { animated, useSpring } from "@react-spring/web";
import { easeExpOut } from "d3-ease";
import metamaskLogo from "assets/icon-metamask.png";
import { ReactComponent as PlusIcon } from "assets/icon-plus-no-bg.svg";
import bugIcon from "assets/bug.png";

import { config } from "../../config/config";
import { FlexContainer } from "containers/common/styles";
import { useRevaStats } from "hooks/revaStats.hooks";

const immunifyUrl = "https://immunefi.com/bounty/revault/";
const addresses = config.getNetworkAddresses();

const FOOTER_NAVIGATION = [
  {
    title: "Discover",
    links: [
      { name: "about us", href: "https://www.revault.network/about" },
      { name: "medium", href: "https://revaultnetwork.medium.com/" },
      { name: "media kit", href: "https://www.revault.network/press" },
      {
        name: "contracts",
        href: "https://revault-network.gitbook.io/revault-network/protocol/smart-contracts",
      },
    ],
  },
  {
    title: "community",
    links: [
      { name: "twitter", href: "https://twitter.com/revaultnetwork" },
      { name: "discord", href: "https://discord.com/invite/revaultnetwork" },
      { name: "telegram", href: "https://t.me/revaultnetwork" },
    ],
  },
  // {
  //   title: "legal",
  //   links: [
  //     { name: "terms of service", href: undefined },
  //     { name: "cookies", href: undefined },
  //     { name: "privacy policy", href: undefined },
  //   ],
  // },
  {
    title: "contact",
    links: [
      {
        name: "team@revault.network",
        href: "mailto:team@revault.network",
      },
    ],
  },
];

export default function Footer() {
  const [showExpandedFooter, setShowExpandedFooter] = useState(false);
  const { stats } = useRevaStats();

  const expandAnimationProps = useSpring({
    height: showExpandedFooter ? 254 : 130,
    config: { duration: 800, easing: easeExpOut },
  });
  if (!stats) {
    return null;
  }

  return (
    <MainContainer
      css={showExpandedFooter ? "height: 254px;" : null}
      onClick={() => setShowExpandedFooter((prev) => !prev)}
    >
      <animated.div className="container" style={expandAnimationProps}>
        <FlexContainer css=" align-items: center; width: 100%;">
          <Drawer />
          <AddRevaToMetamaskButton />
          <Title.Close
            src={showExpandedFooter ? iconChevronDown : iconChevronUp}
          />
        </FlexContainer>
        {showExpandedFooter ? <ExpandedFooter /> : null}
      </animated.div>
    </MainContainer>
  );
}

const subtitleStyle = "text-transform: capitalize ; font-size: 14px; ";

export const ExpandedFooter = () => {
  const data = [
    {
      title: <Logo width={100} height={"auto"} />,
      body: (
        <div>
          {" "}
          <p>© 2021 Revault Ltd. All rights reserved</p>{" "}
          <ImmunifyButton
            href={immunifyUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImmunifyButton.Icon src={bugIcon} />
            <ImmunifyButton.Text>
              <ImmunifyButton.Title>Bug Bounty</ImmunifyButton.Title>
              We offer bounties for security issues.
            </ImmunifyButton.Text>
          </ImmunifyButton>
        </div>
      ),
      css: "flex: 0.5; text-align: start;",
    },
    ...FOOTER_NAVIGATION.map(({ title, links }) => ({
      title,
      body: <ListOfLinks list={links} />,
    })),
  ];

  return (
    <FlexContainer
      css="padding: 20px 20px 0 0;"
      gap="25px"
      justify="space-between"
      align="none"
    >
      {data.map(({ title, body, css }) => (
        <InfoItem
          style={css}
          textAlign="start"
          key={title}
          title={title}
          subtitle={body}
          titleStyle="text-transform: capitalize; font-size: 16px; text-align: start;"
          subtitleStyle={subtitleStyle}
        />
      ))}
    </FlexContainer>
  );
};

const ListOfLinks = ({ list }) => {
  return (
    <ListOfLinksContainer>
      {list.map(
        ({ name, href }) =>
          href && (
            <StyledLink
              key={name}
              href={href}
              css={subtitleStyle + "font-weight: normal;"}
            >
              {name}
            </StyledLink>
          ),
      )}
    </ListOfLinksContainer>
  );
};

ListOfLinks.propTypes = {
  list: PropTypes.array,
};

const AddRevaToMetamaskButton = () => {
  const tokenAddress = addresses.revaToken;
  const tokenSymbol = "REVA";
  const tokenDecimals = 18;
  const tokenImage = "https://app.revault.network/images/img_0.png";
  const buttonRef = useRef();

  const addToken = async (e) => {
    e.stopPropagation();
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      buttonRef.current.blur();
    }
  };

  return (
    <Button onClick={addToken} ref={buttonRef}>
      <FlexContainer gap="8px">
        <StyledPlusIcon />
        <img style={{ width: "25px" }} src={metamaskLogo} alt="logo" />
      </FlexContainer>
    </Button>
  );
};

const StyledPlusIcon = styled(PlusIcon)`
  stroke: ${colorGrayscaleBody};
`;

const ImmunifyButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 290px;
  height: 55px;
  border: 1px solid ${colorGrayscaleBody};
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${colorGrayscaleBody};
  text-decoration: none;
  cursor: pointer;

  :hover {
    border-color: ${colorGrayscaleOffWhite};
  }
`;

ImmunifyButton.Icon = styled.img`
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin-right: 5px;
`;

ImmunifyButton.Text = styled.div`
  display: flex;
  flex-direction: column;
`;

ImmunifyButton.Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${colorGrayscaleInputBackground};
  margin-bottom: 4px;
`;

const Button = styled.button`
  border-radius: 100px;
  background-color: ${colorGrayscaleDark};
  border-color: ${colorGrayscaleDark};
  border-style: solid;
  cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
  height: 30px;

  :hover {
    border-color: ${(props) =>
      props.disabled ? colorGrayscaleDark : colorGrayscaleBody};
  }
  :focus {
    border-color: ${colorGrayscaleOffWhite};
  }
  &:hover ${StyledPlusIcon} {
    stroke: ${colorGrayscaleOffWhite};
  }
`;

const MainContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 44px;
  width: 100%;
  max-width: 1164px;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  background-color: ${colorGrayscaleDark};
  padding-left: 86px;
  padding-right: 8px;
  z-index: 99;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colorGrayscalePlaceholder};
  justify-content: space-around;
`;

const ListOfLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
