import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  claimReva,
  fetchStakeData,
  stakeReva,
  stakeRevaWhileCompound,
  unstakeReva,
} from "services/api/staking";
import { QUERY_KEYS } from "utils/constants";
import { useIsAutoCompoundInPool } from "./autocompound.hook";
import { useForceUpdate } from "../helpers/hooks";
import { StakingPoolsContext } from "contexts/StakingPoolsContext";
import { initTimersByPoolsPositions } from "utils/timers";
import _ from "lodash";

export const useStakeData = () => {
  const { isInitialized } = useSelector((state) => state.globalState);
  const loadDataQuery = useQuery(
    [QUERY_KEYS.stakingData, isInitialized],
    () => {
      return fetchStakeData();
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: isInitialized,
    },
  );
  return loadDataQuery;
};

export const useStakingPools = () => {
  const {
    data,
    selectedPool,
    setSelectedPool,
    timers,
    setTimers,
    areSomePoolsStaked,
    setAreSomePoolsStaked,
    totalRevaStaked,
    setTotalRevaStaked,
  } = React.useContext(StakingPoolsContext);

  const isCurrentPoolTimerRunning = timers[selectedPool]?.isRunning() || false;

  const selectedPoolUserPosition = React.useMemo(() => {
    if (!_.isEmpty(data) && data.userPositions) {
      const { userPositions } = data;
      const selectedPoolUserPosition = userPositions[selectedPool];
      return selectedPoolUserPosition;
    }
  }, [data, selectedPool]);

  const isPoolLocked = React.useMemo(() => {
    if (data?.userPositions) {
      return selectedPoolUserPosition
        ? isCurrentPoolTimerRunning && selectedPoolUserPosition.revaStaked.gt(0)
        : false;
    }
  }, [isCurrentPoolTimerRunning, selectedPoolUserPosition]);

  //calculate total reva staked
  React.useEffect(() => {
    if (data) {
      const { pools } = data;
      if (pools) {
        const allPoolsRevaStaked = pools.reduce((stakedReva, pool) => {
          stakedReva += pool.totalRevaStaked;
          return stakedReva;
        }, 0);

        setTotalRevaStaked(allPoolsRevaStaked);
      }
    }
  }, [data]);

  //initialize all pools timers
  React.useEffect(() => {
    if (data && data.userPositions) {
      const { userPositions, pools } = data;
      const timersData = initTimersByPoolsPositions({ userPositions, pools });
      setTimers(timersData);
      setAreSomePoolsStaked(
        userPositions.some((pool) => pool.revaStaked.gt(0)),
      );
    }
  }, [data?.userPositions]);

  return {
    selectedPool,
    setSelectedPool,
    areSomePoolsStaked,
    timers,
    isPoolLocked,
    totalRevaStaked,
    selectedPoolUserPosition,
    isCurrentPoolTimerRunning,
  };
};

export const useUpdateWhenTimerChange = () => {
  const { data, selectedPool, timers } = React.useContext(StakingPoolsContext);
  const forceUpdate = useForceUpdate();

  //rerender page to show change in timer
  React.useEffect(() => {
    if (data && data.pools) {
      const selectedPoolData = data.pools[selectedPool];
      const activeTimer = timers[selectedPoolData.poolId];

      activeTimer?.addEventListener("secondsUpdated", () => {
        forceUpdate();
      });
      return () => activeTimer?.removeEventListener("secondsUpdated");
    }
  }, [data, selectedPool, timers]);
};

export const useClaim = ({ onSuccess, poolId }) => {
  const result = useMutation(
    () => {
      return claimReva({ poolId });
    },
    {
      onSuccess,
    },
  );
  return result;
};

export const useStake = ({ poolId, amount, onSuccess }) => {
  const isAutoCompound = useIsAutoCompoundInPool({ poolId });

  const result = useMutation(
    async () => {
      const stakingParams = {
        poolId,
        amount,
      };
      const txHash = isAutoCompound
        ? await stakeRevaWhileCompound(stakingParams)
        : await stakeReva(stakingParams);
      return txHash;
    },
    {
      onSuccess,
    },
  );
  return result;
};

export const useUnstake = ({ poolId, amount, isEarly, onSuccess }) => {
  const result = useMutation(
    () => {
      return unstakeReva({ poolId, amount, isEarly });
    },
    {
      onSuccess,
    },
  );
  return result;
};
