import React from "react";
import {
  getRevaStat,
  getCurrentEmissionsStat,
  getMaxSupplyStat,
  getTotalSupplyStat,
  getTotalCirculatingSupplyStat,
  getTVL,
  Loader,
} from "./partials";
import { Root, ExtrnalLink } from "./Drawer.styles.js";
import { getTokenLink } from "../../helpers/utils";
import { useRevaStats } from "hooks/revaStats.hooks";

export default function Drawer() {
  const { stats } = useRevaStats();

  if (!stats) {
    return <Loader />;
  }

  return (
    <Root>
      {getRevaStat()}
      {getCurrentEmissionsStat()}
      {getTVL()}
      {getMaxSupplyStat()}
      {getTotalSupplyStat()}
      {getTotalCirculatingSupplyStat()}
      <ExtrnalLink
        href={getTokenLink("reva")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Get Reva
      </ExtrnalLink>
    </Root>
  );
}
