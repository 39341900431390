import styled from "styled-components";
import Button from "../Button";

import {
  colorGrayscaleLine,
  colorGrayscaleOffWhite,
  colorPrimaryDefault,
  colorBarDefault,
  colorBarPositive,
  colorBarNegative,
  colorBarActive,
  colorSuccessDarkmode,
  colorGrayscaleBody,
  colorErrorDefault,
  colorProviderBadgeBackground,
  colorGrayscaleLabel,
} from "../../utils/colors";

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BarContainer = styled.div`
  display: grid;
  row-gap: ${(props) => (props.isCompact ? "5" : "10")}px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: ${(props) => (props.isCompact ? "normal" : "bold")};
  font-size: ${(props) => (props.isCompact ? "12" : "20")}px;
  line-height: ${(props) => (props.isCompact ? "18" : "35")}px;
  color: ${colorGrayscaleOffWhite};
  margin-bottom: ${(props) => (props.isCompact ? "5" : "10")}px;
`;

Title.AllVaults = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${colorPrimaryDefault};
  user-select: none;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

export const Stat = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.isCompact ? "20" : "34")}px;
  font-size: 12px;

  :hover {
    opacity: 0.6;
  }
`;

Stat.Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.fullWidth ? "100%" : "calc(100% - 100px)")};
  font-weight: 500;
  align-items: center;
  text-transform: uppercase;
`;

Stat.Container.Bar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => props.percent}%;
  background: ${(props) => getBarBackgroundColor(props)};
  border-radius: 5px;
  border: 1px solid ${(props) => getBarBackgroundColor(props, true)};
  height: ${(props) => (props.isCompact ? "18" : "32")}px;
  color: ${(props) => getBarTextColor(props)};
`;

export const ProviderBadge = styled.span`
  background-color: ${colorProviderBadgeBackground};
  width: ${(props) => (props.compact ? "8px" : "12px")};
  height: ${(props) => (props.compact ? "8px" : "12px")};
  font-size: 8px;
  color: ${(props) =>
    props.activeColorBadge ? colorGrayscaleOffWhite : colorGrayscaleLabel};
  border-radius: 50%;
  position: absolute;
  top: ${(props) => (props.compact ? "5px" : "6px")};
  right: ${(props) => (props.compact ? "-2px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CompactTokenLogoContainer = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  ${(props) => props.compact && "margin-left: -4px;margin-right: 7px;"}
  ${(props) =>
    props.compact ? "width: 26px;height: 26px;" : "width: 34px;height: 35px;"}
`;

export const Logo = styled.img`
  position: absolute;
  ${(props) =>
    props.compact
      ? "width: 26px;height: 26px;"
      : "width: 34px;height: 34px;margin-left:1px;"}
  object-fit: contain;
`;

Stat.Container.Bar.Text = styled.div`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  margin-right: auto;
  padding-left: ${(props) => (props.singleBar ? "1px" : "2px")};
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: ${(props) =>
    props.inPosition ? getBarTextColor(props) : colorGrayscaleLine};
`;

Stat.Container.APY = styled.div`
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  padding-right: ${(props) => (props.isCompact ? "4" : "10")}px;
  white-space: nowrap;
`;

Stat.Control = styled.div`
  width: 95px;
  margin-left: 10px;
`;

Stat.Rebalance = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 36px;
  margin-left: 14px;
  border-radius: 5px;
  border: 2px solid #096758;
  box-shadow: 0px 4px 14px rgba(0, 186, 136, 0.3);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.25px;
`;

function getBarBackgroundColor(
  { vaultsHavePosition, vaultsHaveBestPosition, inPosition, isFirst },
  isBorder = false,
) {
  if (vaultsHavePosition) {
    if (vaultsHaveBestPosition) {
      // rebalance won't be required
      return inPosition
        ? isBorder
          ? colorGrayscaleOffWhite
          : colorBarActive
        : colorBarDefault;
    } else if (isFirst) {
      // rebalance required, this is the top vault
      return colorBarPositive;
    } else {
      // rebalance required, this might be a vault we're in position in or not
      return inPosition ? colorBarNegative : colorBarDefault;
    }
  } else {
    return isFirst ? colorBarPositive : colorBarDefault;
  }
}

function getBarTextColor({
  vaultsHavePosition,
  vaultsHaveBestPosition,
  inPosition,
  isFirst,
}) {
  if (vaultsHavePosition) {
    if (vaultsHaveBestPosition) {
      // rebalance won't be required
      return inPosition ? colorGrayscaleOffWhite : colorGrayscaleBody;
    } else if (isFirst) {
      // rebalance required, this is the top vault
      return colorSuccessDarkmode;
    } else {
      // rebalance required, this might be a vault we're in position in or not
      return inPosition ? colorErrorDefault : colorGrayscaleBody;
    }
  } else {
    return isFirst ? colorSuccessDarkmode : colorGrayscaleBody;
  }
}
