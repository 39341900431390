import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Subtitle } from "components/TokenSelect/TokenCard.styles";
import { colorPrimaryDefault, colorGrayscaleOffWhite } from "utils/colors";
import vIcon from "assets/check-circle.png";
import xIcon from "assets/x-circle.png";
import loaderIcon from "assets/loader.png";
import { NOTIFICATION_TYPES } from "utils/constants";
import {
  POLYGON_SCAN_TX_LINK,
  METAMASK_API_ERROR_TYPES,
} from "utils/constants";
import { activateNotification } from "../../utils";
import { getShortTxHash } from "../../helpers/utils";

const confirmationTitle = "Transaction confirmed";
const rejectedMessage = "User denied transaction signature";
const failedTitle = "Transaction failed";
const pendingTitle = "Waiting for confirmation";
const unconfirmedTitle = "Unable to confirm transaction";
const unconfirmedMessage = "This can happen when the network is congested";
const generalErrorMessage = "An error occurred, please try again";

export default function TransactionNotification({ txHash, notificationType }) {
  const shortTxHash = getShortTxHash(txHash);
  const explorerLink = POLYGON_SCAN_TX_LINK + txHash;

  const notificationData =
    notificationType === NOTIFICATION_TYPES.CONFIRMED
      ? {
          imgSrc: vIcon,
          title: confirmationTitle,
          subtitle: shortTxHash,
          href: explorerLink,
        }
      : notificationType === NOTIFICATION_TYPES.PENDING
      ? {
          imgSrc: loaderIcon,
          title: pendingTitle,
          subtitle: shortTxHash,
          href: explorerLink,
        }
      : notificationType === NOTIFICATION_TYPES.UNCONFIRMED
      ? {
          imgSrc: xIcon,
          title: unconfirmedTitle,
          subtitle: unconfirmedMessage,
          href: explorerLink,
        }
      : notificationType === NOTIFICATION_TYPES.REJECTED
      ? {
          imgSrc: xIcon,
          title: txHash,
          subtitle: rejectedMessage,
          href: undefined,
        }
      : notificationType === NOTIFICATION_TYPES.GENERAL_ERROR
      ? {
          imgSrc: xIcon,
          title: txHash,
          subtitle: generalErrorMessage,
          href: undefined,
        }
      : {
          imgSrc: xIcon,
          title: failedTitle,
          subtitle: shortTxHash,
          href: explorerLink,
        };

  const { imgSrc, title, subtitle, href } = notificationData;
  return (
    <NotificationContainer>
      <NotificationIcon
        src={imgSrc}
        animate={notificationType === NOTIFICATION_TYPES.PENDING}
      />
      <div>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationSubtitle>{subtitle}</NotificationSubtitle>
        {href ? (
          <NotificationLink
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on polygonscan
          </NotificationLink>
        ) : null}
      </div>
    </NotificationContainer>
  );
}

TransactionNotification.propTypes = {
  txHash: PropTypes.string,
  notificationType: PropTypes.string,
};

export const activateErrorNotification = (error) => {
  if (error.code === METAMASK_API_ERROR_TYPES.USER_REJECT) {
    // Currently we treat only this exception type from MetaMask
    // https://docs.metamask.io/guide/ethereum-provider.html#errors
    activateNotification({
      txHash: "Transaction Rejected", // usually we use the txHash as a title, but here we don't have a txHash, so a placeholder is provided
      notificationType: NOTIFICATION_TYPES.REJECTED,
    });
  } else {
    activateNotification({
      txHash: "An Error Occurred", // usually we use the txHash as a title, but here we don't have a txHash, so a placeholder is provided
      notificationType: NOTIFICATION_TYPES.GENERAL_ERROR,
    });
  }
};

const NotificationContainer = styled.div`
  display: flex;
  width: 100%;
`;

const NotificationTitle = styled.div`
  color: ${colorGrayscaleOffWhite};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
`;

const NotificationSubtitle = styled(Subtitle)`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;
const NotificationLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colorPrimaryDefault};
  text-decoration: underline;
`;

const spinningAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}`;

const NotificationIcon = styled.img`
  margin-right: 5px;
  animation-name: ${(props) => (props.animate ? spinningAnimation : undefined)};
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
