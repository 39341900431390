import { useQuery } from "react-query";
import { QUERY_KEYS } from "utils/constants";
import { RevaApi } from "../apis";

export const useRevaStats = () => {
  const revaApi = RevaApi();
  const res = useQuery({
    queryKey: QUERY_KEYS.revaStats,
    queryFn: async () => await revaApi.stats(),
    refetchInterval: 1000 * 60, // one minute
  });

  return { ...res, stats: res.data };
};
