import styled from "styled-components";
import { PageTitle } from "./page.styles";
import { colorGrayscaleTitleActive } from "utils/colors";

export const PanelTitle = styled(PageTitle)`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bolder;
  margin: 0 0;
  height: 50%;
  justify-content: flex-start;
`;

export const PanelContainer = styled.div`
  color: white;
  background-color: ${colorGrayscaleTitleActive};
  width: 150%;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 20rem;
`;
