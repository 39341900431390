import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { vaultLogo } from "../../helpers/utils";

import {
  Root,
  Title,
  Stat,
  BarContainer,
  ProviderBadge,
  CompactTokenLogoContainer,
  Logo,
} from "./index.styles";
import {
  colorGrayscaleOffWhite,
  colorSuccessDefault,
} from "../../utils/colors";
import { getVaultProviderName } from "../../helpers/utils";

const compactModeVaultLimit = 3;

export default function TopVaults({
  data,
  inBestPosition = false,
  isConnected = false,
  isCompact = false,
  isLoading = false,
  onRebalanceClick,
  onHoverStateChange,
}) {
  const position = data.position;
  const vaultsHavePosition = data.inPosition;
  const vaults = useMemo(() => {
    const result = [...(data.vaults || [])];
    if (isCompact) {
      // if we are in "compact mode"
      // check if our position is displayed beyound the limits of `compactModeVaultLimit` (i.e it's about to be cut)
      // and if so, bump up our position to the vary last displayable item in the list
      if (vaultsHavePosition && !_.isEmpty(position)) {
        const positionIndex = result.findIndex(
          (vault) => vault.vaultId === position.vaultId,
        );
        if (positionIndex >= compactModeVaultLimit) {
          result.splice(compactModeVaultLimit - 1, 0, position);
        }
      }
      return result.slice(0, compactModeVaultLimit);
    }
    return result;
  }, [data.vaults, isCompact, position, vaultsHavePosition]);
  const maxValue = _.maxBy(vaults, "apyNumeric").apyNumeric;

  const getApyText = (vault, isFirst, isPositionInThisVault) => {
    const vaultApy = vault.apy;
    if (isCompact) {
      if (isFirst) {
        return `${
          isPositionInThisVault ? "ACTIVE VAULT" : "BEST APY"
        } ${vaultApy}%`;
      }
      if (vaultsHavePosition && isPositionInThisVault && !inBestPosition) {
        return `ACTIVE VAULT ${vaultApy}%`;
      }
    } else {
      if (isFirst) {
        return `BEST APY ${vaultApy}%`;
      }
      return `APY ${vaultApy}%`;
    }
    return `${vaultApy}%`;
  };

  return (
    <Root>
      <Title isCompact={isCompact}>
        {vaults.length === 1 ? "Top Vault" : `Top ${vaults.length} Vaults`}
      </Title>
      <BarContainer isCompact={isCompact}>
        {vaults.map((vault, index) => {
          const isFirstVault = index === 0;
          const isPositionInThisVault =
            vaultsHavePosition &&
            data.userVault?.additionalData?.vid === vault.vaultId;
          const protocol = vault.details.additionalData.protocol;

          return (
            <Stat
              key={`top-vault-${index}`}
              isCompact={isCompact}
              onMouseOver={
                onHoverStateChange
                  ? () => onHoverStateChange(vault, true)
                  : null
              }
              onMouseOut={
                onHoverStateChange
                  ? () => onHoverStateChange(vault, false)
                  : null
              }
            >
              {isCompact && (
                <ProviderAndProtocolImage
                  compact
                  providerImage={vaultLogo({
                    vaultDetails: vault.details,
                    active: isFirstVault || isPositionInThisVault,
                  })}
                  protocol={protocol}
                  activeColorBadge={isFirstVault || isPositionInThisVault}
                />
              )}
              <Stat.Container
                fullWidth={
                  isCompact ||
                  !isConnected ||
                  !position ||
                  (position && inBestPosition)
                }
              >
                <Stat.Container.Bar
                  percent={Math.max(
                    (vault.apyNumeric / maxValue) * 100.0,
                    50.0,
                  )}
                  isConnected={isConnected && position}
                  isFirst={isFirstVault}
                  vaultsHavePosition={vaultsHavePosition}
                  vaultsHaveBestPosition={inBestPosition}
                  inPosition={isPositionInThisVault}
                  isCompact={isCompact}
                >
                  {!isCompact && (
                    <ProviderAndProtocolImage
                      compact={false}
                      providerImage={vaultLogo({
                        vaultDetails: vault.details,
                        active: true,
                      })}
                      protocol={vault.details.additionalData.protocol}
                      activeColorBadge
                    />
                  )}
                  <Stat.Container.Bar.Text
                    isFirst={isFirstVault}
                    isCompact={isCompact}
                    inPosition={isPositionInThisVault}
                    vaultsHavePosition={vaultsHavePosition}
                    vaultsHaveBestPosition={inBestPosition}
                  >
                    {!isCompact && getVaultProviderName(vault.details)}
                  </Stat.Container.Bar.Text>
                  <Stat.Container.APY
                    isFirst={isFirstVault}
                    isCompact={isCompact}
                  >
                    {getApyText(vault, isFirstVault, isPositionInThisVault)}
                  </Stat.Container.APY>
                </Stat.Container.Bar>
              </Stat.Container>
              {isConnected &&
                position &&
                isFirstVault &&
                !isPositionInThisVault &&
                !isCompact && (
                  <Stat.Rebalance
                    text="To Withdraw"
                    textColor={colorGrayscaleOffWhite}
                    backgroundColor={colorSuccessDefault}
                    loading={isLoading}
                    spinnerSize={14}
                    onClick={() =>
                      onRebalanceClick({
                        fromVaultId: position.vaultId,
                        toVaultId: vault.vaultId,
                      })
                    }
                  >
                    Rebalance
                  </Stat.Rebalance>
                )}
            </Stat>
          );
        })}
      </BarContainer>
    </Root>
  );
}

function ProviderAndProtocolImage({
  compact,
  providerImage,
  protocol,
  activeColorBadge = false,
}) {
  return (
    <CompactTokenLogoContainer compact={compact}>
      <Logo compact={compact} src={providerImage} />
      {protocol && (
        <ProviderBadge compact={compact} activeColorBadge={activeColorBadge}>
          {protocol.charAt(0).toUpperCase()}
        </ProviderBadge>
      )}
    </CompactTokenLogoContainer>
  );
}

ProviderAndProtocolImage.propTypes = {
  compact: PropTypes.bool,
  providerImage: PropTypes.string,
  protocol: PropTypes.string,
  activeColorBadge: PropTypes.bool,
};

TopVaults.propTypes = {
  data: PropTypes.object,
  position: PropTypes.object,
  inBestPosition: PropTypes.bool,
  isConnected: PropTypes.bool,
  isCompact: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRebalanceClick: PropTypes.func,
  onHoverStateChange: PropTypes.func,
};
