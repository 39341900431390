const { ethers } = require("ethers");
const { format } = require("../utils/format");
const { Contract } = require("ethers-multicall");
const addresses = require("../../config/config").config.getNetworkAddresses();

const revaStakingPoolAbi = require("../../abi/RevaStakingPool.json");
const revaLpStakingPoolAbi = require("../../abi/RevaLpStakingPool.json");
const revaAutoCompoundPoolAbi = require("../../abi/RevaAutoCompoundPool.json");
const zapAbi = require("../../abi/Zap.json");

const revaStakingPoolAddress = addresses.revaStakingPool;
const revaLpStakingPoolAddress = addresses.revaLpStakingPool;
const revaAutoCompoundPoolAddress = addresses.revaAutoCompoundPool;
const zapAddress = addresses.zap;

// NOTICE: hard-coded
const STAKING_POOL_LENGTH = 4;
const STAKING_POOL_IDS = [0, 1, 2, 3];
const FARMING_POOL_LENGTH = 1;
const FARMING_POOL_IDS = [0];

// different pool id's have different lock times and multipliers
async function getRevaStakingPools(multicallProvider) {
  const revaStakingPool = new Contract(
    revaStakingPoolAddress,
    revaStakingPoolAbi,
  );

  const [totalAllocPoint, revaPerBlock, ...poolsInfo] =
    await multicallProvider.all([
      revaStakingPool.totalAllocPoint(),
      revaStakingPool.revaPerBlock(),
      ...STAKING_POOL_IDS.map((n) => revaStakingPool.poolInfo(n)),
    ]);

  let pools = [];
  for (let i = 0; i < STAKING_POOL_LENGTH; i++) {
    const pool = poolsInfo[i];
    const totalRevaStaked = parseFloat(
      parseFloat(ethers.utils.formatEther(pool.totalSupply)).toFixed(2),
    );
    //const allocPoint = pool.allocPoint.toNumber();
    const vRevaMultiplier = pool.vRevaMultiplier.toNumber();
    const lockPeriod = pool.timeLocked.toNumber(); // client can turn to date?
    const { apy, apr } = await getRevaStakingPoolApy(
      pool,
      totalAllocPoint,
      revaPerBlock,
    );
    pools.push({
      totalRevaStaked,
      multiplier: vRevaMultiplier,
      lockPeriod,
      apr,
      apy,
      poolId: i,
    });
  }
  return pools;
}

async function getAllUserRevaStakingPoolPositions(
  ethersProvider,
  multicallProvider,
  userAddress,
) {
  const lastBlock = await ethersProvider.getBlock("latest");
  const revaStakingPool = new Contract(
    revaStakingPoolAddress,
    revaStakingPoolAbi,
  );
  const revaAutoCompoundPool = new Contract(
    revaAutoCompoundPoolAddress,
    revaAutoCompoundPoolAbi,
  );

  const isUserCompounding = await multicallProvider.all(
    STAKING_POOL_IDS.map((n) =>
      revaStakingPool.userIsCompounding(n, userAddress),
    ),
  );
  const compoundingPools = STAKING_POOL_IDS.filter((n) => isUserCompounding[n]);
  const regularPools = STAKING_POOL_IDS.filter((n) => !isUserCompounding[n]);
  const compoundingBalances = await multicallProvider.all(
    compoundingPools.map((n) => revaAutoCompoundPool.balanceOf(n, userAddress)),
  );
  const regularPoolsInfo = await multicallProvider.all(
    regularPools.map((n) => revaStakingPool.userPoolInfo(n, userAddress)),
  );
  const regularPoolsPendingReva = await multicallProvider.all(
    regularPools.map((n) => revaStakingPool.pendingReva(n, userAddress)),
  );

  const compoundingStats = compoundingPools.map((n, i) => ({
    poolId: n,
    revaStaked: format(compoundingBalances[i]),
    pendingReva: "0",
    timeStaked: 0,
    isCompounding: true,
  }));
  const stakingStats = regularPools.map((n, i) => ({
    poolId: n,
    revaStaked: format(regularPoolsInfo[i].amount),
    pendingReva: format(regularPoolsPendingReva[i]),
    timeStaked:
      lastBlock.timestamp - regularPoolsInfo[i].timeDeposited.toNumber(),
    isCompounding: false,
  }));
  const userInfo = compoundingStats
    .concat(stakingStats)
    .sort((poolA, poolB) => poolA.poolId - poolB.poolId);

  return userInfo;
}

// different pool id's have different lock times and multipliers
async function getRevaLpStakingPools(ethersProvider, multicallProvider) {
  const revaLpStakingPoolContract = new ethers.Contract(
    revaLpStakingPoolAddress,
    revaLpStakingPoolAbi,
    ethersProvider,
  );
  let pools = [];
  for (let i = 0; i < FARMING_POOL_LENGTH; i++) {
    const pool = await revaLpStakingPoolContract.poolInfo(i);
    const totalLpStaked = parseFloat(
      parseFloat(ethers.utils.formatEther(pool.totalSupply)).toFixed(2),
    );
    const allocPoint = pool.allocPoint.toNumber();
    const lpTokenAddress = pool.lpToken;
    const apy = await getRevaLpStakingPoolApy(
      ethersProvider,
      multicallProvider,
      i,
    );
    pools.push({
      totalLpStaked,
      allocPoint,
      lpTokenAddress,
      apy,
      poolId: i,
    });
  }
  return pools;
}

async function getAllUserRevaLpStakingPoolPositions(
  multicallProvider,
  userAddress,
) {
  const revaLpStakingPool = new Contract(
    revaLpStakingPoolAddress,
    revaLpStakingPoolAbi,
  );

  const callResults = await multicallProvider.all([
    ...FARMING_POOL_IDS.map((n) =>
      revaLpStakingPool.pendingReva(n, userAddress),
    ),
    ...FARMING_POOL_IDS.map((n) =>
      revaLpStakingPool.userPoolInfo(n, userAddress),
    ),
  ]);
  const usersPendingReva = callResults.slice(0, FARMING_POOL_LENGTH);
  const usersPoolsInfo = callResults.slice(
    FARMING_POOL_LENGTH,
    callResults.length,
  );

  const userInfo = FARMING_POOL_IDS.map((n) => ({
    poolId: n,
    lpStaked: format(usersPoolsInfo[n].amount),
    pendingReva: format(usersPendingReva[n]),
  }));
  return userInfo;
}

// TODO: take into account transfer fee and 1% performance fee
async function getRevaStakingPoolApy(pool, totalAllocPoint, revaPerBlock) {
  const { allocPoint, totalSupply } = pool;
  if (totalSupply.toString() === "0") {
    console.warn(`Total REVA staked to pool id ${pool}, returning 0% apy`);
    return 0;
  }
  const blocksPerYear = "10518975";
  const accRevaPerYear = revaPerBlock
    .mul(blocksPerYear)
    .mul(allocPoint)
    .div(totalAllocPoint);
  const revaStakeApr = accRevaPerYear
    .mul(ethers.utils.parseEther("1"))
    .div(totalSupply);
  const formattedApr = ethers.utils.formatEther(revaStakeApr);
  // for percentage, multiply by 100
  const apr = parseFloat((formattedApr * 100).toFixed(2));
  const apy = aprToApy(apr);
  return { apr, apy };
}

// TODO: take into account transfer fee and 1% performance fee
async function getRevaLpStakingPoolApy(
  ethersProvider,
  multicallProvider,
  poolId,
) {
  const revaLpStakingPoolContract = new Contract(
    revaLpStakingPoolAddress,
    revaLpStakingPoolAbi,
  );
  const zapContract = new Contract(zapAddress, zapAbi);

  const [pool, totalAllocPoint, revaPerBlock] = await multicallProvider.all([
    revaLpStakingPoolContract.poolInfo(poolId),
    revaLpStakingPoolContract.totalAllocPoint(),
    revaLpStakingPoolContract.revaPerBlock(),
  ]);

  const { allocPoint, totalSupply, lpToken } = pool;
  if (totalSupply.toString() === "0") {
    console.warn(`Total LP staked to pool id ${poolId}, returning 0% apy`);
    return 0;
  }

  const blocksPerYear = "10518975";
  const accRevaPerYear = revaPerBlock
    .mul(blocksPerYear)
    .mul(allocPoint)
    .div(totalAllocPoint);

  const [accBusdPerYear, totalBusdStaked] = await multicallProvider.all([
    zapContract.getBUSDValue(addresses.revaToken, accRevaPerYear),
    zapContract.getBUSDValue(lpToken, totalSupply),
  ]);

  const revaStakeApr = accBusdPerYear
    .mul(ethers.utils.parseEther("1"))
    .div(totalBusdStaked);
  const formattedApr = ethers.utils.formatEther(revaStakeApr);
  // for percentage, multiply by 100
  return parseFloat((formattedApr * 100).toFixed(2));
}

const aprToApy = (apr, frequency = 365) =>
  ((1 + apr / 100 / frequency) ** frequency - 1) * 100;

export {
  getRevaStakingPools,
  getRevaLpStakingPools,
  getAllUserRevaStakingPoolPositions,
  getAllUserRevaLpStakingPoolPositions,
};
