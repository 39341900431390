import { useMutation, useQuery, useQueryClient } from "react-query";
import { activateErrorNotification } from "../components/TransactionNotification/TransactionNotification";
import { QUERY_KEYS } from "utils/constants";
import { approveToken, hasUserApprovedToken } from "services/api/approveToken";
import { onTransaction } from "../utils";
import { approvalSuccess } from "actions";
import { useDispatch, useSelector } from "react-redux";

export const useIsTokenApproved = (params) => {
  const { isConnected } = useSelector((state) => state.globalState);

  const isTokenApprovedQuery = useQuery({
    queryKey: QUERY_KEYS.isTokenApproved,
    queryFn: () => hasUserApprovedToken(params),
    enabled: isConnected,
  });

  return isTokenApprovedQuery;
};

export const useApproveToken = ({ params, onSuccess } = {}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const approveTokenMutation = useMutation(
    async (mutateParams) => {
      try {
        const data = await approveToken({ ...params, ...mutateParams });
        const { tokenAddress, targetAddress } = data;

        const { txHash } = data;
        await onTransaction({
          txHash,
          callback: () => {
            queryClient.invalidateQueries(QUERY_KEYS.isTokenApproved);
            dispatch(approvalSuccess({ tokenAddress, targetAddress }));
          },
        });

        return { ...data, ...mutateParams };
      } catch (error) {
        activateErrorNotification(error);
      }
    },
    {
      onSuccess,
    },
  );

  return approveTokenMutation;
};
