import { useQuery } from "react-query";
import { QUERY_KEYS } from "../utils/constants";
import { fetchFarmingData, fetchRevaLPBalance } from "services/api/farming";
import { RevaApi } from "apis";
import { getToken } from "apis/utils/addresses";
import Big from "big.js";
import { useSelector } from "react-redux";

export const useFarmingData = ({
  firstTokenSymbol,
  secondTokenSymbol,
  onSuccess = () => null,
  refetchOnMount = false,
  refetchOnWindowFocus = false,
  cacheTime = 0,
}) => {
  const emptyLpData = {
    lpPoolPositions: [{ pendingReva: new Big(0), lpStaked: new Big(0) }],
    lpPools: [{ apy: 0, totalLpStaked: 0 }],
    liquidityRatio: { portion0: 0, portion1: 0 },
    busdRate: new Big(0),
  };
  const { isConnected } = useSelector((state) => state.globalState);
  const doubleTokenSymbol = `${firstTokenSymbol}-${secondTokenSymbol}`;
  const { tokenId } = getToken(doubleTokenSymbol);
  const {
    isLoading,
    data = emptyLpData,
    refetch,
  } = useQuery(
    QUERY_KEYS.farmingData,
    () => {
      return fetchFarmingData({
        userAddress: RevaApi().getWalletUser(),
        firstTokenSymbol,
        secondTokenSymbol,
        tokenId,
      });
    },
    {
      enabled: isConnected,
      onSuccess,
      refetchOnMount,
      refetchOnWindowFocus,
      cacheTime,
    },
  );
  return { isLoading, data, refetch };
};

export const useRevaLPBalance = ({ refetchInterval, cacheTime = 0 }) => {
  const { isConnected } = useSelector((state) => state.globalState);
  const { data, refetch } = useQuery(
    QUERY_KEYS.revaBNBLP,
    async () => {
      if (!isConnected) {
        return;
      }
      const balance = await fetchRevaLPBalance({
        userAddress: RevaApi().getWalletUser(),
      });
      return balance;
    },
    {
      refetchInterval,
      cacheTime,
      enabled: isConnected,
    },
  );

  return { data, refetch };
};
