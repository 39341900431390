const { ethers } = require("ethers");
const { Contract } = require("ethers-multicall");

const addresses = require("../../config/config").config.getNetworkAddresses();
const { getVault } = require("../utils/addresses");

const bunnyVaultAbi = require("../../abi/IBunnyVault.json");
const bunnyMinterAbi = require("../../abi/IBunnyMinterV2.json");

const beefyVaultAbi = require("../../abi/IBeefyVault.json");
const beefyStrategy = require("../../abi/IBeefyStrategy.json");

const autofarmVaultAbi = require("../../abi/IAutoFarm.json");
const autofarmStratXAbi = require("../../abi/IAutoFarmStratX.json");
const autofarmStratX2PCSAbi = require("../../abi/IAutoFarmStratX2PCS.json");

const acryptosVaultAbi = require("../../abi/IACryptoSVault.json");
const acryptosFarmAbi = require("../../abi/IACryptoSFarm.json");

// General

function getUnderlyingVaultContract(provider, vaultId) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider == "bunny") {
    return getBunnyVaultContract(provider, vaultId);
  } else if (vault.vaultProvider == "beefy") {
    return getBeefyVaultContract(provider, vaultId);
  } else if (vault.vaultProvider == "autofarm") {
    return getAutofarmVaultContract(provider, vaultId);
  } else if (vault.vaultProvider === "acryptos") {
    return getAcryptosVaultContract(provider, vaultId);
  } else {
    throw new Error(`Unrecognized provider ${vault.vaultProvider}`);
  }
}

function getUnderlyingFarmContract(provider, vaultId) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider == "acryptos") {
    return getAcryptosFarmContract(provider, vaultId);
  } else {
    throw new Error(`Unrecognized provider ${vault.vaultProvider}`);
  }
}

function getContract(address, abi, provider) {
  // ethers provider
  if (provider && provider instanceof ethers.providers.StaticJsonRpcProvider) {
    return new ethers.Contract(address, abi, provider);
    // multicall provider
  } else {
    return new Contract(address, abi, provider);
  }
}

// Bunny

function getBunnyMinterContract(provider) {
  // Assuming bunny minter address from config (technically it can be different between vaults + dynamically be changed)
  return getContract(addresses.bunny.minter, bunnyMinterAbi, provider);
}

function getBunnyVaultContract(provider, vaultId) {
  const vault = getVault(vaultId);
  return getContract(vault.address, bunnyVaultAbi, provider);
}

// Beefy

function getBeefyVaultContract(provider, vaultId) {
  const vault = getVault(vaultId);
  return getContract(vault.address, beefyVaultAbi, provider);
}

async function getBeefyStrategyContract(provider, vaultId) {
  const beefyVaultContract = getBeefyVaultContract(provider, vaultId);
  const strategyAddress = await beefyVaultContract.strategy();
  return getContract(strategyAddress, beefyStrategy, provider);
}

// Autofarm

function getAutofarmVaultContract(provider, vaultId) {
  const vault = getVault(vaultId);
  return getContract(vault.address, autofarmVaultAbi, provider);
}

async function getAutofarmStrategyContract(
  ethersProvider,
  vaultId,
  multicallProvider,
) {
  const vault = getVault(vaultId);
  const autofarmVaultContract = getAutofarmVaultContract(
    ethersProvider,
    vaultId,
  );

  const poolInfo = await autofarmVaultContract.poolInfo(
    vault.additionalData.pid,
  );
  if (!poolInfo)
    throw new Error(`AutoFarm pool not found: pid ${vault.additionalData.pid}`);

  const contractProvider = multicallProvider
    ? multicallProvider
    : ethersProvider;

  if (vault.additionalData.strategyType == "stratX") {
    return getContract(poolInfo.strat, autofarmStratXAbi, contractProvider);
  } else if (vault.additionalData.strategyType == "stratX2PCS") {
    return getContract(poolInfo.strat, autofarmStratX2PCSAbi, contractProvider);
  }
}

// Acryptos

function getAcryptosVaultContract(provider, vaultId) {
  const vault = getVault(vaultId);
  return getContract(vault.address, acryptosVaultAbi, provider);
}

function getAcryptosFarmContract(provider, vaultId) {
  const vault = getVault(vaultId);
  return getContract(
    vault.additionalData.farmAddress,
    acryptosFarmAbi,
    provider,
  );
}

export {
  getUnderlyingVaultContract,
  getUnderlyingFarmContract,
  getBunnyMinterContract,
  getBunnyVaultContract,
  getBeefyVaultContract,
  getBeefyStrategyContract,
  getAutofarmVaultContract,
  getAutofarmStrategyContract,
  getAcryptosVaultContract,
  getAcryptosFarmContract,
};
