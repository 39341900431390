import NumberFormatter from "components/NumberFormatter";
import React from "react";
import { colorGrayscaleOffWhite } from "utils/colors";
import { PropTypes } from "prop-types";
import { BottomTextContainer } from "containers/farming/styles";
import { Bold, SimpleText } from "containers/common/text.styles";

const NumAndTitleElement = ({
  number,
  title,
  isFirstWordBold,
  numberPrefix,
}) => {
  let [firstWordInTitle, ...titleExceptFirstWord] = title.split(" ");
  titleExceptFirstWord = titleExceptFirstWord.join(" ");

  return (
    <div>
      <SimpleText
        fontSize={24}
        color={number.gt(0) ? colorGrayscaleOffWhite : undefined}
      >
        <NumberFormatter value={number} prefix={numberPrefix} withToolTip />
      </SimpleText>
      <BottomTextContainer>
        <SimpleText fontSize={14}>
          {isFirstWordBold ? (
            <>
              <Bold>{firstWordInTitle}</Bold>
              {titleExceptFirstWord}
            </>
          ) : (
            <>{title}</>
          )}
        </SimpleText>
      </BottomTextContainer>
    </div>
  );
};

export default NumAndTitleElement;

NumAndTitleElement.propTypes = {
  number: PropTypes.object,
  title: PropTypes.string,
  isFirstWordBold: PropTypes.bool,
  numberPrefix: PropTypes.string,
};
