import { injected, walletconnect, bscConnector } from "services/connectors";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import iconMetaMask from "assets/icon-metamask.png";
import iconWalletConnect from "assets/icon-walletconnect.png";
import iconBinanceChain from "assets/icon-binancechain.png";
import iconMetaMaskBig from "assets/metamask-logo-big.png";
import iconWalletConnectBig from "assets/walletconnect-logo-big.png";
import iconBinanceChainBig from "assets/binancechain-logo-big.png";
import { BscConnector } from "@binance-chain/bsc-connector";

export const CONNECTOR_KEYS = {
  injected: "injectedConnectorKey",
  walletConnect: "walletConnectConnectorKey",
  bsc: "bscKey",
};

export const SUPPORTED_WALLETS = {
  INJECTED: {
    connector: injected,
    name: "Metamask",
    iconSmall: iconMetaMask,
    iconBig: iconMetaMaskBig,
    description: "Metamask, injected web3 provider",
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconSmall: iconWalletConnect,
    iconBig: iconWalletConnectBig,
    description: "Connect to Trust Wallet, Rainbow Wallet and more",
  },
  BINANCE_CHAIN: {
    connector: bscConnector,
    name: "Binance Chain",
    iconSmall: iconBinanceChain,
    iconBig: iconBinanceChainBig,
    description: "Binance Chain, injected web3 provider",
  },
};

export function getKeyForConnector(connector) {
  if (connector instanceof InjectedConnector) {
    return CONNECTOR_KEYS.injected;
  } else if (connector instanceof WalletConnectConnector) {
    return CONNECTOR_KEYS.walletConnect;
  } else if (connector instanceof BscConnector) {
    return CONNECTOR_KEYS.bsc;
  }
}

export function getConnectorForKey(connectorKey) {
  switch (connectorKey) {
    case CONNECTOR_KEYS.injected:
      return injected;
    case CONNECTOR_KEYS.walletConnect:
      return walletconnect;
    case CONNECTOR_KEYS.bsc:
      return bscConnector;
    default:
      return undefined;
  }
}

export function getConnectorImageForKey(connectorKey) {
  switch (connectorKey) {
    case CONNECTOR_KEYS.injected:
      return SUPPORTED_WALLETS.INJECTED.iconSmall;
    case CONNECTOR_KEYS.walletConnect:
      return SUPPORTED_WALLETS.WALLET_CONNECT.iconSmall;
    case CONNECTOR_KEYS.bsc:
      return SUPPORTED_WALLETS.BINANCE_CHAIN.iconSmall;
    default:
      return undefined;
  }
}
