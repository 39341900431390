const ethers = require("ethers");

const { config } = require("../../config/config");

const { getVault } = require("../utils/addresses");
const { getUserProxyContractAddress } = require("../read/revault");

const beefyAbi = require("../../abi/IBeefyVault.json");
const autofarmAbi = require("../../abi/IAutoFarm.json");
const acryptosAbi = require("../../abi/IACryptoSVault.json");
const acryptosFarmAbi = require("../../abi/IACryptoSFarm.json");

const MAX_UINT =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

const provider = new ethers.providers.StaticJsonRpcProvider(
  config.getProviderUrl(),
);

// rebalance all, in future regular rebalance as well
async function generateRebalanceAllPayloads(
  fromVaultId,
  toVaultId,
  userAddress,
) {
  const fromVault = getVault(fromVaultId);
  const toVault = getVault(toVaultId);

  let payloads = [];
  if (fromVault.additionalData.farmAddress) {
    const withdrawAllPayload = await generateWithdrawAllPayload(fromVaultId);
    const withdrawAllFarmPayload = await generateWithdrawAllFarmPayload(
      userAddress,
      fromVaultId,
    );
    payloads.push(withdrawAllPayload);
    payloads.push(withdrawAllFarmPayload);
  } else {
    const withdrawAllPayload = await generateWithdrawAllPayload(fromVaultId);
    payloads.push(withdrawAllPayload);
    payloads.push("0x");
  }

  if (toVault.additionalData.farmAddress) {
    if (toVault.vaultProvider == "acryptos") {
      const depositVaultPayload = await generateDepositPayload(toVaultId, "0");
      const depositFarmPayload = await generateDepositFarmPayload(
        toVaultId,
        "0",
      );
      const depositFarmLeftPayload = depositFarmPayload.substr(
        0,
        depositFarmPayload.length - 64,
      );
      let depositVaultLeftPayload = depositVaultPayload;
      if (toVault.depositTokenSymbol !== "matic") {
        depositVaultLeftPayload = depositVaultPayload.substr(
          0,
          depositVaultPayload.length - 64,
        );
      }
      payloads.push(depositVaultLeftPayload);
      payloads.push(depositFarmLeftPayload);
    }
  } else {
    const depositPayload = await generateDepositPayload(toVaultId, "0");
    let depositLeftPayload = depositPayload;
    if (toVault.depositTokenSymbol !== "matic") {
      depositLeftPayload = depositPayload.substr(0, depositPayload.length - 64);
    }
    payloads.push(depositLeftPayload);
    payloads.push("0x");
  }
  return payloads;
}

async function generateWithdrawSharesPayload(vaultId, amountShares) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider === "acryptos") {
    const acryptosVaultContract = new ethers.Contract(
      vault.address,
      acryptosAbi,
      provider,
    );
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await acryptosVaultContract.populateTransaction.withdrawETH(
        amountShares,
      );
    } else {
      txData = await acryptosVaultContract.populateTransaction.withdraw(
        amountShares,
      );
    }
    return txData.data;
  }
}

async function generateWithdrawPayload(vaultId, amountTokens) {
  const vault = getVault(vaultId);

  if (vault.vaultProvider === "bunny") {
    const bunnyAbi = getBunnyAbi(vault.additionalData.vaultType);
    const bunnyVaultContract = new ethers.Contract(vault.address, bunnyAbi);
    let txData;
    if (vault.additionalData.vaultType === "qubit") {
      txData = await bunnyVaultContract.populateTransaction.withdraw(
        amountTokens,
      );
    } else {
      // venus vaults require withdrawUnderlying
      txData = await bunnyVaultContract.populateTransaction.withdrawUnderlying(
        amountTokens,
      );
    }
    return txData.data;
  } else if (vault.vaultProvider === "beefy") {
    const beefyVaultContract = new ethers.Contract(
      vault.address,
      beefyAbi,
      provider,
    );
    let amountShares = await beefyConvertTokensToShares(
      beefyVaultContract,
      amountTokens,
    );
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await beefyVaultContract.populateTransaction.withdrawBNB(
        amountShares,
      );
    } else {
      txData = await beefyVaultContract.populateTransaction.withdraw(
        amountShares,
      );
    }
    return txData.data;
  } else if (vault.vaultProvider === "autofarm") {
    const autofarmVaultContract = new ethers.Contract(
      vault.address,
      autofarmAbi,
    );
    const txData = await autofarmVaultContract.populateTransaction.withdraw(
      vault.additionalData.pid,
      amountTokens,
    );
    return txData.data;
  } else if (vault.vaultProvider === "acryptos") {
    const acryptosVaultContract = new ethers.Contract(
      vault.address,
      acryptosAbi,
      provider,
    );
    let amountShares = await acryptosConvertTokensToShares(
      vault.address,
      amountTokens,
    );
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await acryptosVaultContract.populateTransaction.withdrawETH(
        amountShares,
      );
    } else {
      txData = await acryptosVaultContract.populateTransaction.withdraw(
        amountShares,
      );
    }
    return txData.data;
  } else {
    throw new Error(`unrecognized provider ${vault.vaultProvider}`);
  }
}

async function generateWithdrawAllPayload(vaultId) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider === "bunny") {
    const bunnyAbi = getBunnyAbi(vault.additionalData.vaultType);
    const bunnyVaultContract = new ethers.Contract(vault.address, bunnyAbi);
    const txData = await bunnyVaultContract.populateTransaction.withdrawAll();
    return txData.data;
  } else if (vault.vaultProvider === "beefy") {
    const beefyVaultContract = new ethers.Contract(vault.address, beefyAbi);
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await beefyVaultContract.populateTransaction.withdrawAllBNB();
    } else {
      txData = await beefyVaultContract.populateTransaction.withdrawAll();
    }
    return txData.data;
  } else if (vault.vaultProvider === "autofarm") {
    const autofarmVaultContract = new ethers.Contract(
      vault.address,
      autofarmAbi,
    );
    const txData = await autofarmVaultContract.populateTransaction.withdraw(
      vault.additionalData.pid,
      MAX_UINT,
    );
    return txData.data;
  } else if (vault.vaultProvider === "acryptos") {
    const acryptosVaultContract = new ethers.Contract(
      vault.address,
      acryptosAbi,
    );
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await acryptosVaultContract.populateTransaction.withdrawAllETH();
    } else {
      txData = await acryptosVaultContract.populateTransaction.withdrawAll();
    }
    return txData.data;
  } else {
    throw new Error(`unrecognized provider ${vault.vaultProvider}`);
  }
}

async function generateDepositPayload(vaultId, amountTokens) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider === "bunny") {
    const bunnyAbi = getBunnyAbi(vault.additionalData.vaultType);
    const bunnyVaultContract = new ethers.Contract(vault.address, bunnyAbi);
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      if (vault.additionalData.vaultType === "qubit") {
        txData = await bunnyVaultContract.populateTransaction.deposit(
          amountTokens,
        );
      } else {
        txData = await bunnyVaultContract.populateTransaction.depositBNB();
      }
    } else {
      txData = await bunnyVaultContract.populateTransaction.deposit(
        amountTokens,
      );
    }
    return txData.data;
  } else if (vault.vaultProvider === "beefy") {
    const beefyVaultContract = new ethers.Contract(vault.address, beefyAbi);
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await beefyVaultContract.populateTransaction.depositBNB();
    } else {
      txData = await beefyVaultContract.populateTransaction.deposit(
        amountTokens,
      );
    }
    return txData.data;
  } else if (vault.vaultProvider === "autofarm") {
    const autofarmVaultContract = new ethers.Contract(
      vault.address,
      autofarmAbi,
    );
    const txData = await autofarmVaultContract.populateTransaction.deposit(
      vault.additionalData.pid,
      amountTokens,
    );
    return txData.data;
  } else if (vault.vaultProvider === "acryptos") {
    const acryptosVaultContract = new ethers.Contract(
      vault.address,
      acryptosAbi,
    );
    let txData;
    if (vault.depositTokenSymbol === "matic") {
      txData = await acryptosVaultContract.populateTransaction.depositETH();
    } else {
      txData = await acryptosVaultContract.populateTransaction.deposit(
        amountTokens,
      );
    }
    return txData.data;
  } else {
    throw new Error(`unrecognized provider ${vault.vaultProvider}`);
  }
}

async function generateHarvestPayload(vaultId) {
  const vault = getVault(vaultId);
  if (vault.vaultProvider === "bunny") {
    const bunnyAbi = getBunnyAbi(vault.additionalData.vaultType);
    const bunnyVaultContract = new ethers.Contract(vault.address, bunnyAbi);
    const txData = await bunnyVaultContract.populateTransaction.getReward();
    return txData.data;
  } else if (vault.vaultProvider === "beefy") {
    throw new Error("Can't harvest beefy");
  } else if (vault.vaultProvider === "autofarm") {
    const autofarmVaultContract = new ethers.Contract(
      vault.address,
      autofarmAbi,
    );
    const txData = await autofarmVaultContract.populateTransaction.deposit(
      vault.additionalData.pid,
      0,
    );
    return txData.data;
  } else if (vault.vaultProvider === "acryptos") {
    const acryptosFarmContract = new ethers.Contract(
      vault.additionalData.farmAddress,
      acryptosFarmAbi,
    );
    const txData = await acryptosFarmContract.populateTransaction.harvest(
      vault.address,
    );
    return txData.data;
  }
  throw new Error(`unrecognized provider ${vault.vaultProvider}`);
}

async function generateWithdrawFarmPayload(vaultId, amountShares) {
  const vault = getVault(vaultId);
  const acryptosFarmContract = new ethers.Contract(
    vault.additionalData.farmAddress,
    acryptosFarmAbi,
  );
  const txData = await acryptosFarmContract.populateTransaction.withdraw(
    vault.address,
    amountShares,
  );
  return txData.data;
}

async function generateDepositFarmPayload(vaultId, amountShares) {
  const vault = getVault(vaultId);
  const acryptosFarmContract = new ethers.Contract(
    vault.additionalData.farmAddress,
    acryptosFarmAbi,
  );
  const txData = await acryptosFarmContract.populateTransaction.deposit(
    vault.address,
    amountShares,
  );
  return txData.data;
}

async function generateWithdrawAllFarmPayload(userAddress, vaultId) {
  const vault = getVault(vaultId);
  const shares = await getUserAcryptosSharesAmount(userAddress, vaultId);
  const acryptosFarmContract = new ethers.Contract(
    vault.additionalData.farmAddress,
    acryptosFarmAbi,
  );
  const txData = await acryptosFarmContract.populateTransaction.withdraw(
    vault.address,
    shares,
  );
  return txData.data;
}

async function generateWithdrawAllFarmAndVaultPayloads(userAddress, vaultId) {
  const vault = getVault(vaultId);
  const acryptosVaultContract = new ethers.Contract(vault.address, acryptosAbi);

  let withdrawFarmPayload, withdrawVaultPayload;
  withdrawFarmPayload = await generateWithdrawAllFarmPayload(
    userAddress,
    vaultId,
  );
  if (vault.depositTokenSymbol === "matic") {
    withdrawVaultPayload = (
      await acryptosVaultContract.populateTransaction.withdrawAllETH()
    ).data;
  } else {
    withdrawVaultPayload = (
      await acryptosVaultContract.populateTransaction.withdrawAll()
    ).data;
  }
  return [withdrawFarmPayload, withdrawVaultPayload];
}

async function getUserAcryptosSharesAmount(userAddress, vaultId) {
  const vault = getVault(vaultId);
  const userProxyContractAddress = await getUserProxyContractAddress(
    provider,
    userAddress,
  );
  const acryptosFarmContract = new ethers.Contract(
    vault.additionalData.farmAddress,
    acryptosFarmAbi,
    provider,
  );
  const shares = (
    await acryptosFarmContract.userInfo(vault.address, userProxyContractAddress)
  )[0];
  return shares;
}

async function beefyConvertTokensToShares(beefyVaultContract, tokenAmount) {
  const vaultTotalShares = await beefyVaultContract.totalSupply();
  const vaultTotalTokens = await beefyVaultContract.balance();
  const shareAmount = vaultTotalShares.mul(tokenAmount).div(vaultTotalTokens);
  return shareAmount;
}

function getBunnyAbi(vaultType) {
  if (vaultType == "venus") {
    return require("../../abi/IBunnyVaultVenus.json");
  } else if (vaultType == "qubit") {
    return require("../../abi/IBunnyVaultQubit.json");
  } else if (vaultType == "cakeToCake") {
    return require("../../abi/IBunnyVaultCakeToCake.json");
  } else if (vaultType == "flipToFlip") {
    return require("../../abi/IBunnyVaultFlipToFlip.json");
  } else {
    throw new Error(`unknown contract type: ${vaultType}`);
  }
}

async function acryptosConvertTokensToShares(
  acryptosVaultAddress,
  tokenAmount,
) {
  const acryptosVaultContract = new ethers.Contract(
    acryptosVaultAddress,
    acryptosAbi,
    provider,
  );
  const sharePrice = await acryptosVaultContract.getPricePerFullShare();
  const shareAmount = tokenAmount
    .mul(ethers.utils.parseEther("1"))
    .div(sharePrice);
  return shareAmount;
}

export {
  generateDepositPayload,
  generateWithdrawPayload,
  generateWithdrawAllPayload,
  generateRebalanceAllPayloads,
  generateHarvestPayload,
  beefyConvertTokensToShares,
  generateWithdrawFarmPayload,
  generateWithdrawAllFarmPayload,
  generateDepositFarmPayload,
  generateWithdrawSharesPayload,
  acryptosConvertTokensToShares,
  generateWithdrawAllFarmAndVaultPayloads,
};
