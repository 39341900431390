import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useVaults, usePeriodicUpdate } from "hooks/vaults.hooks";
import { useQueryClient } from "react-query";
import _ from "lodash";

import { Root } from "./VaultsScreen.styles";

import ContentLoader from "react-content-loader";
import Header from "components/Header/Header";
import TokenPair from "components/TokenPair";
import TokenPairContainer from "components/TokenPairContainer";
import WelcomeHeader from "./WelcomeHeader/WelcomeHeader.jsx";
import PositionSidePane from "components/PositionSidePane";
import VaultsScreenLoader from "./VaultsScreenLoader";
import { ReactComponent as NoDepositeVaultsIcon } from "../../assets/icons/vaultsscreen/no_deposite_vaults.svg";
import { ReactComponent as NoVaultsIcon } from "../../assets/icons/vaultsscreen/no_vaults.svg";
import {
  colorGrayscaleTitleActive,
  colorNavBlackBackground,
  colorNavBlackShadow,
  colorPrimaryDefault,
  colorGrayscaleOffWhite,
  colorGrayscaleLabel,
} from "utils/colors";
import { ActionButtons, ActionButton } from "../../containers/common/styles";
import { useIsConnected } from "../../hooks/wallet.hooks";

export default function Vaults() {
  const queryClient = useQueryClient();
  const { isFetching } = useVaults();

  const vaultsState = useSelector((state) => state.vaultsState);
  const { isConnected } = useIsConnected();

  const [selectedTokenId, setSelectedTokenId] = useState();
  const [isMainDataFetched, setIsMainDataFetched] = useState(false);

  const vaults = React.useMemo(
    () => vaultsState.data?.allPositions || [],
    [vaultsState],
  );

  const exchangeRatesMap = vaultsState.data?.exchangeRatesMap;

  const [filteredVaults, setFilteredVaults] = useState(vaults); //ref to the header state and functions to determain the place holder when there is no

  //vaults to display and reset filters from here
  const filterRef = React.useRef({});

  usePeriodicUpdate({
    isFetchingSomethingElse:
      queryClient.isFetching() > 0 || queryClient.isMutating() > 0,
    isMainDataAlreadyFetched: isMainDataFetched,
  });

  if (!_.isEmpty(vaults) && !isMainDataFetched) {
    setIsMainDataFetched(true);
  }

  return (
    <Root>
      <FixedHeader>
        <Header isConnected={isConnected} />
        <WelcomeHeader
          vaults={vaults}
          setFilteredVaults={setFilteredVaults}
          ref={filterRef}
          isFetching={isFetching}
        />
      </FixedHeader>
      {vaultsState.isInitialized && !isFetching ? (
        <>
          <VaultsList
            filterVaults={filteredVaults}
            exchangeRatesMap={exchangeRatesMap}
            isConnected={isConnected}
            setSelectedTokenId={setSelectedTokenId}
            filterRef={filterRef}
          />
          <PositionSidePane
            data={filteredVaults.find((v) => v.tokenId === selectedTokenId)}
            isConnected={isConnected}
            isOpen={selectedTokenId !== undefined}
            onCloseClicked={() => setSelectedTokenId()}
          />
        </>
      ) : (
        <>
          <TokenPairContainer>
            {Array.from({ length: 12 }, (v, index) => (
              <VaultLoader key={`${index}`} />
            ))}
          </TokenPairContainer>
          <VaultsScreenLoader />
        </>
      )}
    </Root>
  );
}

function VaultsList({
  filterVaults,
  exchangeRatesMap,
  isConnected,
  setSelectedTokenId,
  filterRef,
}) {
  return filterVaults.length > 0 ? (
    <TokenPairContainer>
      {filterVaults.map((v, index) => {
        return (
          <TokenPair
            key={`vault-pair-${index}`}
            data={v}
            exchangeRatesMap={exchangeRatesMap}
            isConnected={isConnected}
            onEnterPosition={(p) => setSelectedTokenId(p.tokenId)}
          />
        );
      })}
    </TokenPairContainer>
  ) : (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      {filterRef?.current?.state?.showDepositedOnly ? (
        <NoDepositeVaultsIcon />
      ) : (
        <NoVaultsIcon />
      )}
      <span
        css={`
          font-weight: bold;
          font-size: 22px;
          line-height: 32px;
          color: ${colorGrayscaleOffWhite};
          margin: 5px 0px;
          text-align: center;
          text-align: center;
        `}
      >
        {filterRef?.current?.state?.showDepositedOnly
          ? "Seems like your not deposited to any vault"
          : "No matching results"}
      </span>
      <span
        css={`
          font-size: 16px;
          line-height: 18px;
          color: ${colorGrayscaleLabel};
          margin: 0px 5px;
        `}
      >
        {filterRef?.current?.state?.showDepositedOnly
          ? "Please make sure you are at the right wallet address."
          : "Please try to unselect filters, in order to get more vaults"}
      </span>
      {filterRef?.current?.resetState && (
        <ActionButtons
          css={`
            width: 240px;
          `}
        >
          <ActionButton
            text="Show all vaults"
            outline={colorPrimaryDefault}
            onClick={() => filterRef.current.resetState()}
            background="none"
          />
        </ActionButtons>
      )}
    </div>
  );
}

function FixedHeader(props) {
  //ref to keep track on the height of the fixed elements height
  //to put a spacer in the height needed and prevent next elements to
  //over-lap or jump
  const fixedElementRef = React.useRef(null);
  return (
    <>
      <div
        ref={fixedElementRef}
        css={`
          background: ${colorNavBlackBackground};
          box-shadow: 0px 24px 24px ${colorNavBlackShadow};
          z-index: 5;
          border-radius: 0px 0px 20px 20px;
          padding: 0px;
          position: fixed;
          top: 0px;
        `}
      >
        {props.children}
      </div>
      {
        //spacer to push down all the next elements to prevent overlapping of fixed element with regular elements
        fixedElementRef?.current && (
          <div
            css={`
              width: 0px;
              height: 0px;
              padding: 0px;
              margin: 0px;
              /*clientHeight: returns the height of an element but does not include margin*/
              /*adding margin to the fixed header here*/
              margin-bottom: ${fixedElementRef.current.clientHeight + 30}px;
            `}
          />
        )
      }
    </>
  );
}

FixedHeader.propTypes = {
  children: PropTypes.node,
};

VaultsList.propTypes = {
  filterVaults: PropTypes.array,
  exchangeRatesMap: PropTypes.object,
  isConnected: PropTypes.bool,
  setSelectedTokenId: PropTypes.func,
  filterRef: PropTypes.object,
};

const VaultLoader = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={330}
    viewBox="0 0 300 330"
    backgroundColor="rgba(38, 43, 69, 0.4)"
    foregroundColor="rgba(255, 255, 255, 0.1)"
    style={{
      backgroundColor: colorGrayscaleTitleActive,
      borderRadius: 10,
    }}
  >
    <rect x="15" y="26" rx="0" ry="0" width="168" height="25" />
    <rect x="204" y="15" rx="0" ry="0" width="80" height="47" />
    <circle cx="152" cy="108" r="11" />
    <rect x="15" y="81" rx="0" ry="0" width="110" height="18" />
    <rect x="185" y="82" rx="0" ry="0" width="99" height="18" />
    <rect x="41" y="170" rx="4" ry="4" width="245" height="20" />
    <rect x="41" y="195" rx="4" ry="4" width="179" height="20" />
    <rect x="41" y="220" rx="4" ry="4" width="71" height="20" />
    <rect x="15" y="259" rx="0" ry="0" width="93" height="16" />
    <rect x="15" y="278" rx="0" ry="0" width="93" height="20" />
    <rect x="15" y="301" rx="0" ry="0" width="44" height="14" />
    <circle cx="25" cy="180" r="10" />
    <circle cx="25" cy="205" r="10" />
    <circle cx="25" cy="230" r="10" />
    <rect x="15" y="147" rx="0" ry="0" width="100" height="16" />
    <rect x="224" y="105" rx="0" ry="0" width="60" height="35" />
    <rect x="15" y="105" rx="0" ry="0" width="60" height="35" />
  </ContentLoader>
);
