import styled from "styled-components";
import {
  colorGrayscaleDark,
  colorGrayscaleLabel,
  colorGrayscaleOffWhite,
  colorGrayscaleTitleActive,
  colorPrimaryDefault,
} from "../../utils/colors";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  width: 419px;
  box-sizing: border-box;
  background-color: ${colorGrayscaleDark};
  border-radius: 20px;
  user-select: none;
`;

Content.Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: ${colorGrayscaleOffWhite};
`;

Content.Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colorGrayscaleLabel};
  margin-bottom: 25px;
`;

export const Wallets = styled.div`
  display: grid;
  grid-template-columns: 140px 140px;
  grid-template-rows: 140px;
  column-gap: 15px;
  row-gap: 15px;
`;

export const Wallet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 140px;
  height: 140px;
  background-color: ${colorGrayscaleTitleActive};
  border: 1px solid ${colorGrayscaleTitleActive};
  border-radius: 10px;
  user-select: none;
  cursor: pointer;

  :hover {
    border: 1px solid ${colorPrimaryDefault};
    opacity: 0.8;
  }
`;

Wallet.Title = styled.div`
  color: ${colorGrayscaleOffWhite};
  margin-top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

Wallet.Icon = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

export const Notice = styled(Content.Subtitle)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-bottom: unset;
  font-size: 12px;
  line-height: 15px;
`;

export const MoreInfoLink = styled.a`
  color: ${colorPrimaryDefault};
  text-decoration: none;
  margin-left: 4px;
  cursor: pointer;
`;
