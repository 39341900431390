import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./index.styles";

export default function FadeIn({
  duration = 200,
  delay = 0,
  children,
  ...delegated
}) {
  return (
    <Wrapper
      {...delegated}
      style={{
        ...(delegated.style || {}),
        animationDuration: duration + "ms",
        animationDelay: delay + "ms",
      }}
    >
      {children}
    </Wrapper>
  );
}

FadeIn.propTypes = {
  duration: PropTypes.number,
  delay: PropTypes.number,
  children: PropTypes.node,
};
