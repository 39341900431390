import { RevaApi } from "../../apis";
import { activateErrorNotification } from "../../components/TransactionNotification/TransactionNotification";
import { config } from "../../config/config";
import { stringToBigNumber } from "../../helpers/utils";
import { fetchTokenToBusdRate } from "services/api/rates";
import { sendTransaction } from "helpers/blockchain";
const addresses = config.getNetworkAddresses();
const GEM = config.getSystemConfig().gasEstimationMultiplier;

export function fetchFarmingData({
  userAddress, // if user is not connected
  firstTokenSymbol,
  secondTokenSymbol,
  amount,
  precision,
  tokenId,
}) {
  // null userAddress happens when user is not connected to app
  userAddress = userAddress || "0x0000000000000000000000000000000000000000";

  return Promise.all([
    fetchUserRevaLpStakingPoolPositions({ userAddress }),
    fetchRevaLPStakingPools(),
    fetchLiquidityRatio({
      firstTokenSymbol,
      secondTokenSymbol,
      amount,
      precision,
    }),
    fetchTokenToBusdRate({ tokenId }),
  ])
    .catch((error) => console.log(error))
    .then(([lpPoolPositions, lpPools, liquidityRatio, busdRate]) => {
      return {
        lpPoolPositions,
        lpPools,
        liquidityRatio,
        busdRate,
      };
    });
}

export async function fetchLiquidityRatio({
  firstTokenSymbol,
  secondTokenSymbol,
  amount,
  precision,
}) {
  try {
    const revaApi = RevaApi();
    const data = await revaApi.getLiquidityRatio(
      firstTokenSymbol,
      secondTokenSymbol,
      amount,
      precision,
    );

    return data;
  } catch (error) {
    console.log(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function fetchRevaLPStakingPools() {
  try {
    const revaApi = RevaApi();
    const data = await revaApi.getRevaLpStakingPools();

    return data;
  } catch (error) {
    console.log(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function fetchRevaLPBalance({ userAddress }) {
  try {
    const revaApi = RevaApi();
    const data = await revaApi.getUserRevaBnbBalance(userAddress);

    return stringToBigNumber(data);
  } catch (error) {
    console.log(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function fetchUserRevaLpStakingPoolPositions({ userAddress }) {
  try {
    if (!userAddress) {
      return undefined;
    }
    const revaApi = RevaApi();
    const data = await revaApi.getAllUserRevaLpStakingPoolPositions(
      userAddress,
    );
    const userPositions = data.map((userPosition) => {
      return {
        ...userPosition,
        lpStaked: stringToBigNumber(userPosition.lpStaked),
        pendingReva: stringToBigNumber(userPosition.pendingReva),
        revaEarned: stringToBigNumber(userPosition.revaEarned),
      };
    });

    return userPositions;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function withdrawRevaLP({ poolId, amount }) {
  try {
    const { revaLpStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();
    const txData = await revaApi.unstakeRevaLp(poolId, amount);

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaLpStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const unstakeRevaLPTxHash = await sendTransaction(txParams);

    return unstakeRevaLPTxHash;
  } catch (error) {
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function depositRevaLP({ poolId, amount }) {
  try {
    const { revaLpStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();

    const txData = await revaApi.stakeRevaLp(poolId, amount);

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaLpStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const stakeRevaLPTxHash = await sendTransaction(txParams);

    return stakeRevaLPTxHash;
  } catch (error) {
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function claimRevaLP({ poolId }) {
  try {
    const { revaLpStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();
    const txData = await revaApi.claimRevaLpStakeReward(poolId);

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaLpStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const claimRevaLPTxHash = await sendTransaction(txParams);

    return claimRevaLPTxHash;
  } catch (error) {
    console.log(error);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}
