import { RevaApi } from "apis";
import { getVault, getAllTokens, getAllVaults } from "apis/utils/addresses";
import { config } from "config/config";
import store from "../../store";
import { isMetaMaskAvailable, stringToBigNumber } from "helpers/utils";
import { TOKEN_CODE_MAP, web3SessionKey } from "utils/constants";
import { CONNECTOR_KEYS } from "utils/wallets";
import {
  commitVaultsCache,
  loadVaultsSuccess,
  loadVaultsFailure,
  periodicUpdateSuccess,
  periodicUpdateFailure,
} from "actions";
import Big from "big.js";
import _ from "lodash";

import { activateErrorNotification } from "../../components/TransactionNotification/TransactionNotification";
import { sendTransaction } from "helpers/blockchain";

const addresses = config.getNetworkAddresses();
const GEM = config.getSystemConfig().gasEstimationMultiplier;

const convertVaultToBigNumber = (vault) => {
  // Leaving some of these as strings on purpose
  for (const key of [
    "principalNative",
    "principalBalanceBusd",
    "depositTokenBalance",
    "depositTokenBalanceBusd",
    //"depositTokenReward",
    "depositTokenRewardBusd",
    //"revaReward",
    "revaRewardBusd",
    "totalBalanceBusd",
  ]) {
    vault[key] = stringToBigNumber(vault[key]);
  }
};

const convertPositionToBigNumber = (position) => {
  position.rate = stringToBigNumber(position.rate);
  position.revaApy = stringToBigNumber(position.revaApy);
  position.valuePerToken = stringToBigNumber(position.valuePerToken);
  position.vaults.forEach((vault) => {
    vault.tvlBusd = stringToBigNumber(vault.tvlBusd);
  });
};

const tokenMap = Object.assign(
  {},
  ...getAllTokens().map((t) => ({ [t.tokenId]: t })),
); // convert token array to hash map with token id as key

const tokenSymbolMap = Object.assign(
  {},
  ...getAllTokens().map((t) => ({ [t.symbol]: t })),
);

const vaultMap = Object.assign(
  {},
  ...getAllVaults().map((v) => ({ [v.additionalData.vid]: v })),
); // convert vaults array to hash map with vault id as key

function processVaults(vaults = []) {
  vaults.length = Math.min(vaults.length, 5); // limit number of vaults at 5
  return vaults
    .sort((a, b) => parseFloat(b.apy) - parseFloat(a.apy))
    .map((v) => {
      return {
        ...v,
        apyNumeric: parseFloat(v.apy),
        details: vaultMap[v.vaultId],
      };
    });
}

function processTokens(
  allPositions = [],
  allUserPositions = [],
  exchangeRatesMap,
  allUserTokensMap = {},
  isConnected,
) {
  const allUserPositionsMap = Object.assign(
    {},
    ...allUserPositions.map((p) => ({ [p.tokenId]: p })),
  );
  return allPositions.map((p) => {
    const tokenDetails = {
      ...allUserTokensMap[p.tokenId],
      ...tokenMap[p.tokenId],
    };
    const symbol = tokenDetails.symbol;
    if (symbol.includes("-")) {
      const split = symbol.split("-");
      tokenDetails.codes = split.map((s) => TOKEN_CODE_MAP[s]);
    } else {
      tokenDetails.codes = [TOKEN_CODE_MAP[symbol]];
    }
    const userPosition = allUserPositionsMap[p.tokenId];
    userPosition?.userVaults?.sort((v1, v2) =>
      v1.principalBalanceBusd.gt(v2.principalBalanceBusd) ? -1 : 1,
    );
    const userVault =
      userPosition && userPosition.userVaults?.length > 0
        ? userPosition.userVaults[0]
        : undefined;
    const tokenBalanceObject = allUserTokensMap[p.tokenId];

    const vaults = processVaults(p.vaults);

    const position = vaults?.find(
      (p) => p.vaultId === userVault?.additionalData?.vid,
    );
    const inPosition =
      isConnected && !_.isEmpty(position) && userVault?.principalNative.gt(0);

    return {
      ...p,
      tokenDetails,
      userVault,
      position: inPosition ? position : undefined,
      inPosition,
      tokenBalance:
        tokenBalanceObject && tokenBalanceObject.balance
          ? new Big(tokenBalanceObject.balance)
          : new Big(0),
      vaults,
      exchangeRate: exchangeRatesMap ? exchangeRatesMap[p.tokenId] : undefined,
    };
  });
}

function calculateVaultState(data, isConnected) {
  const {
    stats,
    allPositions = [],
    allUserPositions = [],
    allUserTokens = [],
    exchangeRatesMap,
    accounts,
  } = data;

  const allUserTokensMap = Object.assign(
    {},
    ...allUserTokens.map((t) => ({ [t.tokenId]: t })),
  );

  // sort positions placing LP tokens first
  allPositions.sort((a, b) => {
    const symbolA = tokenMap[a.tokenId].symbol;
    const symbolB = tokenMap[b.tokenId].symbol;
    const weightA = !symbolA.includes("-") ? 0 : 1;
    const weightB = !symbolB.includes("-") ? 0 : 2;
    return weightB - weightA;
  });

  return {
    stats: {
      reva: {
        value: parseFloat(stats.revaPrice),
        fiatCurrency: "USD",
      },
      currEmissions: stats.currEmissions,
      maxSupply: parseFloat(stats.maxSupply),
      totalSupply: parseFloat(stats.totalSupply),
      circulatingSupply: parseFloat(stats.circSupply),
    },
    allPositions: processTokens(
      allPositions,
      allUserPositions,
      exchangeRatesMap,
      allUserTokensMap,
      isConnected,
    ),
    allUserTokens: processTokens(
      allUserTokens,
      allUserPositions,
      exchangeRatesMap,
      {},
      isConnected,
    ),
    exchangeRatesMap,
    accounts,
    tokenSymbolMap,
  };
}

export async function loadVaults() {
  try {
    const state = store.getState();
    const isConnected = state.globalState.isConnected;
    const revaApi = RevaApi();

    let stats,
      allPositions = [],
      allUserPositions = [],
      allUserTokens = [],
      exchangeRatesMap;

    const requests = [revaApi.stats(), revaApi.getAllPositions()];
    if (isConnected) {
      const accountAddress = revaApi.getWalletUser();
      requests.push(
        revaApi.getAllUserPositions(accountAddress),
        revaApi.userTokenBalances(accountAddress),
        revaApi.tokenRates(),
      );

      if (web3SessionKey === CONNECTOR_KEYS.injected && isMetaMaskAvailable()) {
        requests.push(
          window.ethereum.request({
            method: "eth_requestAccounts",
          }),
        );
      }
    }
    const responses = await Promise.all(requests);

    stats = responses[0];
    allPositions = responses[1];

    if (responses.length >= 5 /* TODO: replace with smarter check */) {
      allUserPositions = responses[2];
      allUserTokens = responses[3];
      exchangeRatesMap = Object.assign(
        {},
        ...responses[4].map((t) => ({
          [t.tokenId]: {
            ...t,
            busdPerToken: stringToBigNumber(t.busdPerToken),
          },
        })),
      );
      console.log("connected account: " + revaApi.getWalletUser());
    }

    // convert specific fields from string to BigNumber
    allPositions.forEach(convertPositionToBigNumber);
    allUserPositions.forEach((p) => {
      p.userVaults.forEach(convertVaultToBigNumber);
    });

    // agregate fetched data
    const incomingData = {
      stats,
      allPositions,
      allUserPositions,
      allUserTokens,
      exchangeRatesMap,
    };

    // cache it
    store.dispatch(commitVaultsCache(incomingData));

    // build vaults state object
    const payload = calculateVaultState(incomingData, isConnected);

    const isInitialized = store.getState().vaultsState.isInitialized; // this check needs to be performed bedore 'loadVaultsSuccess' as this method would set it to true
    const shouldStartPeriodicUpdates = !isInitialized;

    store.dispatch(loadVaultsSuccess(payload));

    if (shouldStartPeriodicUpdates) {
      // yield put(periodicUpdate());
    }
  } catch (error) {
    console.log(error.stack);
    store.dispatch(loadVaultsFailure(error));
  }
}

export async function refreshVault(tokenId) {
  if (_.isNil(tokenId)) {
    return;
  }

  try {
    const state = store.getState();
    const isConnected = state.globalState.isConnected;
    const cachedData = state.vaultsState.cache;
    if (!isConnected || _.isEmpty(cachedData.exchangeRatesMap)) {
      return;
    }

    const revaApi = RevaApi();
    const allUserPositions = await revaApi.getAllUserPositions(
      revaApi.getWalletUser(),
    );

    allUserPositions.forEach((position) =>
      position.userVaults.forEach(convertVaultToBigNumber),
    );

    store.dispatch(commitVaultsCache({ allUserPositions }));
    cachedData.allUserPositions = allUserPositions;

    // build vaults state object
    const payload = calculateVaultState(cachedData, true);
    store.dispatch(loadVaultsSuccess(payload));
  } catch (error) {
    console.log(error.stack);
    store.dispatch(loadVaultsFailure(error));
  }
}

export async function periodicUpdate() {
  try {
    const state = store.getState();
    const isConnected = state.globalState.isConnected;
    const cachedData = state.vaultsState.cache;
    if (!isConnected || _.isEmpty(cachedData.exchangeRatesMap)) {
      return;
    }

    const revaApi = RevaApi();
    let allUserTokensResponse = await revaApi.userTokenBalances(
      revaApi.getWalletUser(),
    );

    store.dispatch(commitVaultsCache({ allUserTokens: allUserTokensResponse }));
    cachedData.allUserTokens = allUserTokensResponse;

    // build vaults state object
    const payload = calculateVaultState(cachedData, true);
    store.dispatch(periodicUpdateSuccess(payload));
  } catch (error) {
    console.log(error.stack);
    store.dispatch(periodicUpdateFailure(error));
  }
}

export async function deposit({ vaultId, amount }) {
  try {
    const revaApi = RevaApi();

    const gasPrice = await revaApi.getGasPrice();
    const txData = await revaApi.generateDepositData(vaultId, amount);
    const txParams = {
      to: txData.address,
      from: revaApi.getWalletUser(), // must match user's active address.
      data: txData.data,
      value: txData.value,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    console.log("performing deposit");

    const depositTxHash = await sendTransaction(txParams);

    return depositTxHash;
  } catch (error) {
    console.log(error.stack);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function zapAndDeposit({ fromTokenId, toVaultId, amount }) {
  try {
    const revaApi = RevaApi();

    const gasPrice = await revaApi.getGasPrice();
    let txParams;

    const txData = await revaApi.generateZapAndDepositData(
      fromTokenId,
      toVaultId,
      amount,
    );
    const value = txData.value ? txData.value.toHexString() : "0x00";
    txParams = {
      to: addresses.zapAndDeposit,
      from: revaApi.getWalletUser(), // must match user's active address.
      gasPrice,
      data: txData.data,
      value,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const zapAndDepositTxHash = await sendTransaction(txParams);

    return zapAndDepositTxHash;
  } catch (error) {
    console.log(error.stack);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function withdraw({ fromVaultId, amount }) {
  try {
    // TODO: add endpoint from metamask
    const revaApi = RevaApi();

    const txData = await revaApi.generateWithdrawData(fromVaultId, amount);
    const gasPrice = await revaApi.getGasPrice();
    const txParams = {
      to: addresses.revault,
      from: revaApi.getWalletUser(), // must match user's active address.
      data: txData,
      value: "0x00",
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    console.log("performing withdraw");

    const withdrawTxHash = await sendTransaction(txParams);

    return withdrawTxHash;
  } catch (error) {
    console.log(error.stack);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function withdrawAll({ fromVaultId }) {
  try {
    // TODO: add endpoint from metamask
    const revaApi = RevaApi();
    const userAddress = revaApi.getWalletUser();

    const txData = await revaApi.generateWithdrawAllData(
      fromVaultId,
      userAddress,
    );
    const gasPrice = await revaApi.getGasPrice();
    const txParams = {
      to: addresses.revault,
      from: userAddress, // must match user's active address.
      data: txData,
      value: "0x00",
      gasPrice,
    };

    console.log("performing withdraw all");

    const withdrawTxHash = await sendTransaction(txParams);

    return withdrawTxHash;
  } catch (error) {
    console.log(error.stack);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

// TODO: this should be called RebalanceAll, in the future we want RebalanceAmount
export async function rebalance({ fromVaultId, toVaultId, txGas }) {
  try {
    const revaApi = RevaApi();

    const txData = await revaApi.generateRebalanceAllData(
      fromVaultId,
      toVaultId,
      revaApi.getWalletUser(),
    );
    const gasPrice = await revaApi.getGasPrice();
    const txParams = {
      to: addresses.revault,
      from: revaApi.getWalletUser(), // must match user's active address.
      data: txData,
      value: "0x00",
      gas: txGas.toString(16), // hex value
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    console.log("performing rebalance all");

    const rebalanceAllTxHash = await sendTransaction(txParams);

    console.log("Handle Rebalance with payload:", { fromVaultId, toVaultId });

    return rebalanceAllTxHash;
  } catch (error) {
    console.log(error.stack);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function claim({ vaultId }) {
  try {
    const revaApi = RevaApi();
    let to;

    const txData = await revaApi.generateClaimData(vaultId);
    const vault = getVault(vaultId);
    if (vault.vaultProvider === "beefy") {
      to = addresses.revaChef;
    } else {
      to = addresses.revault;
    }
    const gasPrice = await revaApi.getGasPrice();
    const txParams = {
      to,
      from: revaApi.getWalletUser(), // must match user's active address.
      data: txData,
      value: "0x00",
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const vaultClaimTxHash = await sendTransaction(txParams);

    console.log("Handle Claim with payload:", { vaultId });

    return vaultClaimTxHash;
  } catch (error) {
    activateErrorNotification(error);
    console.log(error.stack);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function fees({ vaultId }) {
  try {
    const revaApi = RevaApi();
    const feeData = await revaApi.getVaultFees(
      vaultId,
      revaApi.getWalletUser(),
    );

    return feeData;
  } catch (error) {
    console.log("feesMutation no vault found", error.stack);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}
