export const colorGrayscaleDark = "#0E1121";
export const colorGrayscaleTitleActive = "#14162B";
export const colorGrayscaleBody = "#424B6D";
export const colorGrayscaleBodyRGBA = "rgba(66, 75, 109, 0.3)";
export const colorGrayscaleLabel = "#6E7691";
export const colorGrayscaleLine = "#D9DDE9";
export const colorGrayscaleOffWhite = "#FCFCFC";
export const colorGrayscaleInputBackground = "#EFF1F7";
export const colorGrayscalePlaceholder = "#A0A7BD";
export const colorSuccessDarkmode = "#2FE0B0";
export const colorSuccessDefault = "#00BA88";
export const colorErrorDefault = "#ED2E7E";
export const colorErrorDark = "#C30052";
export const colorPrimaryDefault = "#5F2EEA";
export const colorPrimaryDark = "#3D00A2";
export const colorPrimaryLight = "#E4DAFF";
export const colorWarningDefault = "#F4B740";
export const colorInfoDefault = "#1CC8EE";
export const colorInfoDefaultRGBA = "rgba(28, 200, 238, 0.25)";
export const colorInfoDark = "#0088A6";
export const colorBackgroundDark = "rgba(66, 75, 109, 0)";
export const colorBackgroundDark2 = "rgba(49, 203, 255, 0)";
export const colorBackgroundDark3 = "rgba(66, 75, 109, 0.2)";
export const colorBackgroundDark4 = "rgba(38, 43, 69, 0.4)";
export const colorBackgroundDark5 = "rgba(255, 255, 255, 0.1)";
export const colorBackground = "rgba(49, 203, 255, 0.41)";
export const colorBarDefault = "#262B45";
export const colorBarPositive = "#0E4746";
export const colorBarNegative = "#541b41";
export const colorBarActive = "#212126";
export const colorVaultFilterHover = "#EFF1F7";
export const colorNavBlackBackground = "#0a0d1a";
export const colorNavBlackShadow = "rgba(0, 0, 0, 0.15)";
export const colorWhite = "#ffffff";
export const colorProviderBadgeBackground = "#151428";
