const { ethers } = require("ethers");
const { Contract } = require("ethers-multicall");

const addresses = require("../../config/config").config.getNetworkAddresses();

const tokenAbi = require("../../abi/IBEP20.json");
const zapAbi = require("../../abi/Zap.json");

const { format } = require("../utils/format.js");
const {
  getTokenById,
  getAllTradeableTokens,
} = require("../utils/addresses.js");

const { watchBalances, getBalances } = require("./multiwatcher.js");

async function getUserBalances(ethersProvider, multicallProvider, userAddress) {
  const tokens = getAllTradeableTokens();
  await watchBalances(userAddress, tokens);
  const balances = getBalances();
  const tokenUsdRates = await getAllTokenToBusdRates(multicallProvider);
  const tokenApprovals = await Promise.all(
    tokens.map(async (token) => {
      if (token.symbol !== "matic") {
        return {
          revault: await hasUserApprovedInfinity(
            ethersProvider,
            token.address,
            userAddress,
            addresses.revault,
          ),
          zapAndDeposit: await hasUserApprovedInfinity(
            ethersProvider,
            token.address,
            userAddress,
            addresses.zapAndDeposit,
          ),
        };
        // BNB is not a token and doesn't need approval
      } else {
        return {
          revault: true,
          zapAndDeposit: true,
        };
      }
    }),
  );
  const data = tokens.map((token, idx) => ({
    balance: balances[token.symbol],
    tokenId: token.tokenId,
    valuePerToken: tokenUsdRates[idx].busdPerToken,
    approvedRevault: tokenApprovals[idx]["revault"],
    approvedZapAndDeposit: tokenApprovals[idx]["zapAndDeposit"],
  }));
  return data;
}

async function getUserBalance(provider, userAddress, tokenId) {
  const token = getTokenById(tokenId);

  if (token.symbol == "matic") {
    const userBalance = await provider.getBalance(userAddress);
    return format(userBalance, token.decimals);
  } else {
    return getUserBalanceByTokenAddress(
      provider,
      userAddress,
      token.address,
      token.decimals,
    );
  }
}

async function getUserBalanceByTokenAddress(
  provider,
  userAddress,
  tokenAddress,
  tokenDecimals,
) {
  tokenDecimals = tokenDecimals || 18;

  const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);
  const userBalance = await tokenContract.balanceOf(userAddress);

  return format(userBalance, tokenDecimals);
}

async function getAllTokenToBusdRates(multicallProvider, asDictBN = false) {
  const tokens = getAllTradeableTokens();
  const zap = new Contract(addresses.zap, zapAbi);
  const tokenRateArray = await multicallProvider.all(
    tokens.map((token) =>
      zap.getBUSDValue(
        token.address,
        ethers.utils.parseUnits("1", token.decimals),
      ),
    ),
  );

  if (!asDictBN) {
    return tokens.map((token, i) => ({
      tokenId: token.tokenId,
      busdPerToken: format(tokenRateArray[i]),
    }));
  } else {
    const tokenMap = {};
    tokens.map((token, i) => {
      // Returned as BN in wei units to allow both BN operations & decimal places
      tokenMap[token.symbol] = tokenRateArray[i];
    });
    return tokenMap;
  }
}

async function getTokenToBusdRate(provider, tokenId) {
  const token = getTokenById(tokenId);
  const zapContract = new ethers.Contract(addresses.zap, zapAbi, provider);
  const tokenUnitAmount = ethers.utils.parseUnits("1", token.decimals);
  const busdAmount = await zapContract.getBUSDValue(
    token.address,
    tokenUnitAmount,
  );
  return format(busdAmount);
}

async function hasUserApprovedInfinity(
  provider,
  tokenAddress,
  userAddress,
  toAddress,
) {
  const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);

  const userApproval = await tokenContract.allowance(userAddress, toAddress);
  // greater than 10^40 is big enough
  const hugeNumber = ethers.utils.parseEther("100000000000000000");
  return userApproval.gt(hugeNumber);
}

export {
  getUserBalance,
  getUserBalances,
  getUserBalanceByTokenAddress,
  getAllTokenToBusdRates,
  getTokenToBusdRate,
  hasUserApprovedInfinity,
};
