import React from "react";
import ContentLoader from "react-content-loader";
import { colorGrayscaleTitleActive } from "utils/colors";

const FarmingLoader = () => (
  <ContentLoader
    speed={2}
    width={708}
    height={380}
    viewBox="0 0 708 380"
    backgroundColor="rgba(38, 43, 69, 0.4)"
    foregroundColor="rgba(255, 255, 255, 0.1)"
    style={{
      width: 708,
      height: 380,
      backgroundColor: colorGrayscaleTitleActive,
      borderRadius: 10,
    }}
  >
    <rect x="23" y="28" rx="4" ry="4" width="80" height="47" />
    <rect x="117" y="28" rx="0" ry="0" width="142" height="26" />
    <rect x="117" y="59" rx="0" ry="0" width="118" height="16" />
    <rect x="450" y="28" rx="0" ry="0" width="148" height="39" />
    <rect x="616" y="28" rx="0" ry="0" width="50" height="39" />
    <rect x="478" y="72" rx="0" ry="0" width="120" height="14" />
    <rect x="616" y="72" rx="0" ry="0" width="32" height="14" />
    <rect x="23" y="179" rx="4" ry="4" width="662" height="99" />
    <rect x="23" y="298" rx="4" ry="4" width="317" height="60" />
    <rect x="368" y="298" rx="4" ry="4" width="317" height="60" />
    <rect x="26" y="105" rx="0" ry="0" width="94" height="24" />
    <rect x="26" y="135" rx="0" ry="0" width="94" height="18" />
    <circle cx="154" cy="132" r="14" />
    <rect x="190" y="105" rx="0" ry="0" width="94" height="24" />
    <rect x="190" y="135" rx="0" ry="0" width="94" height="18" />
    <circle cx="314" cy="132" r="14" />
    <rect x="340" y="105" rx="0" ry="0" width="94" height="24" />
    <rect x="340" y="135" rx="0" ry="0" width="94" height="18" />
    <rect x="463" y="105" rx="0" ry="0" width="94" height="24" />
    <rect x="463" y="135" rx="0" ry="0" width="94" height="18" />
    <rect x="586" y="105" rx="0" ry="0" width="94" height="24" />
    <rect x="586" y="135" rx="0" ry="0" width="94" height="18" />
  </ContentLoader>
);

export default FarmingLoader;
