import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  loadVaults,
  refreshVault,
  periodicUpdate,
  deposit,
  zapAndDeposit,
  withdraw,
  withdrawAll,
  rebalance,
  claim,
} from "services/api/vaults";
import { onTransaction } from "utils";
import { QUERY_KEYS } from "utils/constants";
import { useSelector } from "react-redux";

const balanceRefreshDelayMs =
  require("../config/config").config.getSystemConfig().balanceRefreshDelayMs;

export const useVaults = () => {
  const { isInitialized } = useSelector((state) => state.globalState);
  const loadDataQuery = useQuery(
    QUERY_KEYS.vaultDataLoad,
    async () => {
      await loadVaults();
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: isInitialized,
    },
  );
  return loadDataQuery;
};

export const useRefreshVault = (tokenId) => {
  const queryClient = useQueryClient();
  const refreshDataQuery = useQuery(
    [QUERY_KEYS.vaultDataRefresh, tokenId],
    async () => {
      await refreshVault(tokenId);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  function refresh() {
    refreshDataQuery.refetch();
    queryClient.invalidateQueries(QUERY_KEYS.vaultPeriodicUpdate);
  }

  return { ...refreshDataQuery, refresh };
};

export const usePeriodicUpdate = ({
  isFetchingSomethingElse,
  isMainDataAlreadyFetched,
}) => {
  const refreshDataQuery = useQuery(
    QUERY_KEYS.vaultPeriodicUpdate,
    async () => {
      if (!isFetchingSomethingElse && isMainDataAlreadyFetched) {
        await periodicUpdate();
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      refetchInterval: balanceRefreshDelayMs,
      enabled: isMainDataAlreadyFetched,
    },
  );
  return refreshDataQuery;
};

export const useDeposit = ({ onSuccess }) => {
  const depositMutation = useMutation(
    async (params) => {
      const txHash = await deposit(params);
      await onTransaction({
        txHash,
      });

      return txHash;
    },
    {
      onSuccess,
    },
  );

  return depositMutation;
};

export const useZapAndDeposit = ({ onSuccess }) => {
  const zapAndDepositMutation = useMutation(
    async (params) => {
      const txHash = await zapAndDeposit(params);
      await onTransaction({
        txHash,
      });

      return txHash;
    },
    {
      onSuccess,
    },
  );

  return zapAndDepositMutation;
};

export const useWithdraw = ({ onSuccess }) => {
  const withdrawMutation = useMutation(
    async (params) => {
      const txHash = await (params.isMax
        ? withdrawAll(params)
        : withdraw(params));
      await onTransaction({
        txHash,
      });

      return { txHash, ...params };
    },
    {
      onSuccess,
    },
  );

  return withdrawMutation;
};

export const useRebalance = ({ tokenId }) => {
  const queryClient = useQueryClient();
  const rebalanceMutation = useMutation(
    async (params) => {
      const txHash = await rebalance(params);
      await onTransaction({
        txHash,
      });
      // continue to show loading while we refresh user positions:
      await refreshVault(tokenId);

      return txHash;
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(QUERY_KEYS.vaultPeriodicUpdate),
    },
  );

  return rebalanceMutation;
};

export const useClaim = ({ onSuccess }) => {
  const claimMutation = useMutation(
    async (params) => {
      const txHash = await claim(params);
      await onTransaction({
        txHash,
      });

      return txHash;
    },
    {
      onSuccess,
    },
  );

  return claimMutation;
};
