import { VAULT_FILTER_OPTIONS } from "utils/constants";

const stabaleCoins = ["busd", "dai", "usdc"];

export const filtersConfig = {
  [VAULT_FILTER_OPTIONS.AllTypes]: {
    label: "All Types",
    filterFunction: (vaultsArray) => vaultsArray,
    active: true,
  },
  [VAULT_FILTER_OPTIONS.LPAssets]: {
    label: "LP Assets",
    filterFunction: (vaultsArray) =>
      vaultsArray.filter((vault) => vault.tokenDetails.isFlip),
    active: false,
  },
  [VAULT_FILTER_OPTIONS.SingleAssets]: {
    label: "Single Assets",
    filterFunction: (vaultsArray) =>
      vaultsArray.filter((vault) => !vault.tokenDetails.isFlip),
    active: false,
  },
  [VAULT_FILTER_OPTIONS.Stablecoins]: {
    label: "Stablecoins",
    filterFunction: (vaultsArray) =>
      vaultsArray.filter((vault) =>
        stabaleCoins.find(
          (stabaleCoinName) => vault.tokenDetails.symbol === stabaleCoinName,
        ),
      ),
    active: false,
  },
};
