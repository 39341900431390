import styled from "styled-components";

import {
  colorErrorDefault,
  colorGrayscalePlaceholder,
  colorSuccessDarkmode,
  colorPrimaryDefault,
  colorGrayscaleTitleActive,
  colorGrayscaleBody,
  colorGrayscaleOffWhite,
  colorBackgroundDark3,
} from "../../utils/colors";

export const Root = styled.div`
  display: flex;
  height: 44px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colorGrayscalePlaceholder};
  align-items: center;
  justify-content: space-between;
`;

export const Stat = styled.div`
  display: flex;
  align-items: center;
`;

Stat.Value = styled.div`
  margin-left: 5px;
  color: ${(props) =>
    props.isPositive ? colorSuccessDarkmode : colorErrorDefault};
`;

Stat.Graph = styled.div`
  margin-left: 5px;
  margin-bottom: 3px;
`;

export const ExtrnalLink = styled.a`
  padding: 5px 10px;
  background: rgba(146, 47, 224, 0.1);
  border-radius: 35px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${colorPrimaryDefault};
  user-select: none;
  cursor: pointer;
  text-decoration: none;

  :hover {
    opacity: 0.6;
  }
`;

export const CGAndCMCLinks = styled.ul`
  display: none; /*hidden until mouse is hovering on the REVA value*/
  cursor: pointer;
  height: 100px;
  width: 160px;
  padding: 10px;
  background: ${colorGrayscaleTitleActive};
  border-radius: 10px;
  font-size: 14px;
`;

export const CustomLink = styled.li`
  text-transform: none; /*to prevent capitalaizing all the letters*/
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  height: 40px;
  width: 150px;
  border-radius: 5px;
  padding: 4px;
  color: ${colorGrayscaleBody};
  &:hover {
    color: ${colorGrayscaleOffWhite};
    background: ${colorBackgroundDark3};
  }
`;

export const HoverMenu = styled.div`
  display: inline-block;
  position: relative;
  &:hover ${CGAndCMCLinks} {
    /*when hovered display the links*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Spacer = styled.div`
  backgroundcolor: transparent;
  position: absolute;
  bottom: 25px;
  cursor: pointer;
`;

export const CustomStat = styled(Stat)`
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    background: ${colorGrayscaleTitleActive};
    border-radius: 30px;
  }
  ${(props) =>
    props.hover &&
    `background: ${colorGrayscaleTitleActive}; border-radius: 30px;`}
`;
