import React from "react";
import PropTypes from "prop-types";
import { useStakeData } from "hooks/staking.hooks";

export const StakingPoolsContext = React.createContext();

export const StakingPoolsProvider = (props) => {
  const { data } = useStakeData();
  const [selectedPool, setSelectedPool] = React.useState(0);
  const [timers, setTimers] = React.useState({});
  const [areSomePoolsStaked, setAreSomePoolsStaked] = React.useState(false);
  const [totalRevaStaked, setTotalRevaStaked] = React.useState();

  return (
    <StakingPoolsContext.Provider
      value={{
        data,
        selectedPool,
        setSelectedPool,
        timers,
        setTimers,
        areSomePoolsStaked,
        setAreSomePoolsStaked,
        totalRevaStaked,
        setTotalRevaStaked,
      }}
    >
      {props.children}
    </StakingPoolsContext.Provider>
  );
};

StakingPoolsProvider.propTypes = {
  children: PropTypes.node,
};
