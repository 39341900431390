import { useMutation, useQueryClient } from "react-query";
import { activateErrorNotification } from "components/TransactionNotification/TransactionNotification";
import { onTransaction } from "../utils";
import { RevaApi } from "apis";
import { sendTransaction } from "helpers/blockchain";
import { useStakeData } from "./staking.hooks";
import { QUERY_KEYS } from "utils/constants";

const GEM = 1.5; // we override the GEM for this type of tx due to past issues

async function setAutoCompound({
  tokenAddress,
  targetAddress,
  userAddress = window.ethereum.selectedAddress,
  value = "0x00",
  poolId,
  autoCompound,
  errorCallback,
}) {
  try {
    const revaApi = RevaApi();

    const { data, to } = await revaApi[
      autoCompound ? "enterCompoundingPosition" : "exitCompoundingPosition"
    ](poolId);

    const gasPrice = await revaApi.getGasPrice();
    const txParams = {
      to,
      from: userAddress,
      gasPrice,
      value,
      data,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const approveTxHash = await sendTransaction(txParams);

    return { tokenAddress, targetAddress, txHash: approveTxHash };
  } catch (error) {
    console.log(error.stack);
    errorCallback();
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export const useAutoCompound = ({
  params,
  onSuccess,
  onSettled,
  errorCallback,
}) => {
  const queryClient = useQueryClient();
  const autoCompoundMutation = useMutation(
    async (mutateParams) => {
      try {
        const data = await setAutoCompound({
          ...params,
          ...mutateParams,
          errorCallback,
        });
        const { txHash } = data;
        await onTransaction({
          txHash,
          callback: () => {
            queryClient.invalidateQueries(QUERY_KEYS.stakingData);
          },
        });

        return { ...data, ...mutateParams };
      } catch (error) {
        activateErrorNotification(error);
      }
    },
    {
      onSuccess,
      onSettled,
    },
  );

  return autoCompoundMutation;
};

export const useIsAutoCompoundInPool = ({ poolId }) => {
  const { data } = useStakeData();
  return Boolean(data?.userPositions[poolId]?.isCompounding);
};
