import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "components/Header/Header";
import Tabs from "components/Tabs";
import StakePools from "containers/stake/partials/StakePools";
import {
  ActionButton,
  ActionButtons,
  Root,
  FlexContainer,
} from "containers/common/styles";
import { InlineText, Paragraph } from "containers/common/text.styles";
import StakeOrUnstakeButton from "containers/stake/partials/StakeConfirmModal";
import {
  colorPrimaryDefault,
  colorErrorDefault,
  colorGrayscaleOffWhite,
} from "utils/colors";
import StakeInfo from "containers/stake/partials/StakeInfo";
import AssetView from "components/AssetView";
import LoadingSpinner from "components/LoadingSpinner";
import ModalConnect from "components/ModalConnect";
import Timer from "easytimer.js";
import { QUERY_KEYS, TOKEN_CODE_REVA } from "utils/constants";
import { ActionButton as ConnectWalletButton } from "components/PositionSidePane/ControlPanel.styles";
import _ from "lodash";
import { onTransaction } from "../../utils";
import {
  useApproveToken,
  useIsTokenApproved,
} from "../../hooks/approveToken.hooks";
import { config } from "../../config/config";
import Big from "big.js";
import AutoCompound from "components/AutoCompound";
import { useIsAutoCompoundInPool } from "hooks/autocompound.hook";
import { useBUSDrate } from "hooks/busdRate.hooks";
import { useRevaStakedBalance } from "hooks/revaStakedBalance.hooks";
import StakeLoader from "containers/stake/partials/StakeLoader";
import TitleSection from "containers/stake/partials/TitleSection";
import {
  CustomActionButton,
  PanelTitle,
  StakingTabs,
} from "containers/stake/StakeScreen.styles";
import {
  useStake,
  useStakeData,
  useUnstake,
  useClaim,
  useStakingPools,
  useUpdateWhenTimerChange,
} from "hooks/staking.hooks";
import { useIsConnected } from "hooks/wallet.hooks";
import RevaLink from "components/Link/RevaLink";
import { useQueryClient } from "react-query";
import { useRevaStats } from "hooks/revaStats.hooks";
import { useRevaUSDprice } from "hooks/common.hooks";
import { StakingPoolsProvider } from "contexts/StakingPoolsContext";

const addresses = config.getNetworkAddresses();
export const StakeModes = Object.freeze({
  Stake: "Stake",
  Unstake: "Unstake",
});

export default function StakingScreen() {
  return (
    <StakingPoolsProvider>
      <Stake />
    </StakingPoolsProvider>
  );
}

export function Stake() {
  const { isLoading: isStakeDataFetching, data } = useStakeData();
  const { isLoading: loadingRevaStats, stats } = useRevaStats();
  const { isLoading: isRevaUSDRateLoading } = useRevaUSDprice();
  const { totalRevaStaked, selectedPool, setSelectedPool, areSomePoolsStaked } =
    useStakingPools();

  const isLoading =
    isStakeDataFetching || loadingRevaStats || isRevaUSDRateLoading;
  const [selectedTab, setSelectedTab] = useState(0);
  const { isConnected } = useIsConnected();

  const onTabSelect = (newSelectedTab) => {
    const { userPositions } = data;
    const selectedPoolUserPosition = userPositions[selectedPool];
    setSelectedTab(newSelectedTab);
    if (selectedTab === 0 && newSelectedTab === 1) {
      if (selectedPoolUserPosition.revaStaked <= 0) {
        const firstStakedPoolIndex = userPositions.findIndex(
          (pool) => pool.revaStaked > 0,
        );
        if (firstStakedPoolIndex !== -1) {
          setSelectedPool(firstStakedPoolIndex);
        }
      }
    }
  };

  return (
    <Root>
      <Header isConnected={isConnected} />
      <FlexContainer gap="20px">
        <TitleSection
          title={
            <div>
              Lock REVA to{" "}
              <span style={{ color: colorPrimaryDefault }}>Boost</span> Rewards
            </div>
          }
          subtitle="Locking REVA will increase yield and voting power"
          totalRevaStaked={totalRevaStaked ? totalRevaStaked.toFixed() : null}
          isLoadingStakeData={isLoading}
          circSupply={stats?.circSupply}
        />
        <StakingTabs isConnected={isConnected}>
          <Tabs
            selectedIndex={selectedTab}
            onSelect={onTabSelect}
            tabs={[
              {
                title: StakeModes.Stake,
                disabled: !isConnected || isLoading,
              },
              {
                title: StakeModes.Unstake,
                disabled: isLoading || !isConnected || !areSomePoolsStaked,
              },
            ]}
            panels={[
              <ContentPanel
                key={StakeModes.Stake}
                content={getContentByPanel({ type: StakeModes.Stake })}
              />,
              <ContentPanel
                key={StakeModes.Unstake}
                content={getContentByPanel({ type: StakeModes.Unstake })}
              />,
            ]}
          />
        </StakingTabs>
      </FlexContainer>
    </Root>
  );
}

function ContentPanel({ content }) {
  const [amount, setAmount] = useState();
  const { balance, fetchBalance } = useRevaStakedBalance();
  const { isConnected } = useIsConnected();
  const queryClient = useQueryClient();
  const {
    setSelectedPool,
    selectedPool,
    timers,
    isPoolLocked,
    selectedPoolUserPosition,
    isCurrentPoolTimerRunning,
  } = useStakingPools();
  const { isLoading, data } = useStakeData();
  const isAutoCompound = useIsAutoCompoundInPool({ poolId: selectedPool });
  useUpdateWhenTimerChange();

  const shouldDisplayPopupWarrning = React.useMemo(() => {
    if (isAutoCompound) return false;
    if (selectedPool === 0) return false;
    if (selectedPoolUserPosition?.revaStaked) {
      const userStakedInPool = new Big(selectedPoolUserPosition?.revaStaked).gt(
        0,
      );
      if (
        (content.type === StakeModes.Stake &&
          !userStakedInPool &&
          !isCurrentPoolTimerRunning) ||
        (content.type === StakeModes.Unstake &&
          userStakedInPool &&
          !isCurrentPoolTimerRunning)
      ) {
        return false;
      } else {
        return true;
      }
    }
  }, [
    content.type,
    selectedPoolUserPosition,
    isCurrentPoolTimerRunning,
    isAutoCompound,
    selectedPool,
  ]);

  const { mutate: claim, isLoading: isClaiming } = useClaim({
    poolId: selectedPool,
    onSuccess: (txHash) =>
      onTransaction({
        txHash,
        callback: () => queryClient.invalidateQueries(QUERY_KEYS.stakingData),
      }),
  });

  const { revaToken, revaStakingPool } = addresses;
  const { data: isRevaTokenApproved } = useIsTokenApproved({
    tokenAddress: revaToken,
    targetAddress: revaStakingPool,
  });
  const { mutate: approveRevaToken, isLoading: isApprovingRevaToken } =
    useApproveToken({
      params: {
        tokenAddress: revaToken,
        targetAddress: revaStakingPool,
      },
    });

  const stakeMutation = useStake({
    poolId: selectedPool,
    amount,
    onSuccess: (txHash) => {
      return onTransaction({
        txHash,
        callback: () => queryClient.invalidateQueries(QUERY_KEYS.stakingData),
      });
    },
  });

  const unstakeMutation = useUnstake({
    poolId: selectedPool,
    amount,
    isEarly: isPoolLocked,
    onSuccess: (txHash) =>
      onTransaction({
        txHash,
        callback: () => queryClient.invalidateQueries(QUERY_KEYS.stakingData),
      }),
  });

  const { busdRate } = useBUSDrate({ tokenSymbol: "reva" });

  useEffect(() => {
    if (isConnected) {
      fetchBalance();
    }
  }, [isConnected]);

  if (isLoading || _.isEmpty(data)) {
    return (
      <FlexContainer style={{ minHeight: 200 }}>
        <StakeLoader />
      </FlexContainer>
    );
  }

  const { pools, userPositions } = data;

  const selectedPoolData = pools[selectedPool];

  const precentageButtonsConfig = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 75 },
    { value: 100 },
  ];

  return (
    <FlexContainer col align="normal">
      <PanelTitle>{content.panelTitle}</PanelTitle>
      <InlineText
        css="text-align: center; margin-bottom: 5px; white-space:pre;"
        size={12}
      >
        {content.panelSubtitle}
      </InlineText>
      <StakePools
        userPositions={userPositions}
        pools={pools}
        selectedPool={selectedPool}
        onSelect={setSelectedPool}
        disableNonStakedPools={content.type === StakeModes.Unstake}
      />
      <FlexContainer justify={"space-between"} style={{ marginBottom: "5px" }}>
        <FlexContainer col align="normal">
          <InlineText
            size={12}
            color={colorGrayscaleOffWhite}
            hoverColor={colorGrayscaleOffWhite}
            textTransform="uppercase"
          >
            Choose amount to {content.type}
          </InlineText>
          {selectedPoolUserPosition?.isCompounding &&
            content.type === StakeModes.Unstake && (
              <InlineText size={12} color={colorErrorDefault}>
                Turn off auto compounding before unstaking
              </InlineText>
            )}
        </FlexContainer>
        <AutoCompound poolId={selectedPool} />
      </FlexContainer>
      <AssetView
        leftFrameTitle={
          content.type === StakeModes.Stake ? "Stake Reva" : "Unstake Reva"
        }
        assetTitle="REVA"
        tokenDetails={{ symbol: "reva", codes: [TOKEN_CODE_REVA] }}
        busdPerToken={busdRate}
        balance={
          !isConnected
            ? new Big(0)
            : content.type === StakeModes.Stake
            ? balance
            : selectedPoolUserPosition
            ? selectedPoolUserPosition.revaStaked
            : new Big(0)
        }
        amountText={!isNaN(amount) ? amount.toString() : amount}
        onValueChange={setAmount}
        rightFrameTitle={
          content.type === StakeModes.Stake ? <RevaLink /> : null
        }
        balancePrecentageShortcutsConfig={
          content.type === StakeModes.Unstake
            ? precentageButtonsConfig
            : undefined
        }
        isDisabled={!isConnected}
      />
      {isConnected ? (
        !(
          selectedPoolUserPosition?.isCompounding &&
          content.type === StakeModes.Unstake
        ) ? (
          <>
            <ActionButtons
              css={
                /*in firefox the action buttons are under the position
                container and are not clickable, so z-index is raised here*/
                "z-index:1;"
              }
            >
              {!isAutoCompound && (
                <ActionButton
                  text={isClaiming ? <LoadingSpinner /> : "Claim"}
                  loading={isClaiming}
                  loadingText="Claiming..."
                  outline={colorPrimaryDefault}
                  onClick={claim}
                  background="none"
                />
              )}
              {isRevaTokenApproved && !isApprovingRevaToken ? (
                <StakeOrUnstakeButton
                  fullWidth={isAutoCompound}
                  type={content.type}
                  onConfirm={async () => {
                    await getStakeOrUnstakeMutation({
                      type: content.type,
                      stakeMutation,
                      unstakeMutation,
                    }).mutate({});
                    setAmount(0);
                  }}
                  loading={
                    getStakeOrUnstakeMutation({
                      type: content.type,
                      stakeMutation,
                      unstakeMutation,
                    }).isLoading
                  }
                  locked={shouldDisplayPopupWarrning}
                  disabled={
                    content.type === StakeModes.Stake
                      ? balance?.eq(0) ||
                        !amount ||
                        amount === 0 ||
                        (amount > 0 && balance?.lt(amount))
                      : selectedPoolUserPosition?.revaStaked.eq(0) ||
                        (amount > 0 &&
                          selectedPoolUserPosition?.revaStaked.lt(amount))
                  }
                  lockPeriod={selectedPoolData.lockPeriod}
                />
              ) : (
                <ActionButton
                  text={
                    isApprovingRevaToken ? <LoadingSpinner /> : "Approve REVA"
                  }
                  loading={isApprovingRevaToken}
                  loadingText="Approving..."
                  color={colorGrayscaleOffWhite}
                  onClick={async () => {
                    await approveRevaToken();
                  }}
                />
              )}
            </ActionButtons>
          </>
        ) : (
          <CustomActionButton
            text={"Unstake & Claim"}
            background={colorErrorDefault}
            color={colorGrayscaleOffWhite}
            disabled
          />
        )
      ) : (
        <ModalConnect
          isConnected={isConnected}
          customButton={
            <ConnectWalletButton
              style={{ minHeight: 60, padding: "10px 0", marginTop: "30px" }}
            >
              Connect Wallet
            </ConnectWalletButton>
          }
        />
      )}
      {isPoolLocked && content.actionWarning}
      <StakeInfo
        selectedPool={selectedPool}
        timeLeft={
          isPoolLocked ? timers[selectedPool]?.getTimeValues() : undefined
        }
      />
    </FlexContainer>
  );
}

ContentPanel.propTypes = {
  content: PropTypes.object,
  isConnected: PropTypes.bool,
  data: PropTypes.shape({
    pools: PropTypes.array,
    userPositions: PropTypes.array,
  }),
  refetchStakeData: PropTypes.func,
  selectedPool: PropTypes.number,
  timers: PropTypes.shape({ poolId: PropTypes.instanceOf(Timer) }),
  isPoolLocked: PropTypes.bool,
};

const getStakeOrUnstakeMutation = ({
  type,
  stakeMutation,
  unstakeMutation,
}) => {
  switch (type) {
    case StakeModes.Stake:
      return stakeMutation;
    case StakeModes.Unstake:
      return unstakeMutation;
  }
};

const getContentByPanel = ({ type }) => {
  const content = { type };
  switch (type) {
    case StakeModes.Stake:
      content.panelTitle = "Select your boost power";
      content.panelSubtitle =
        "Each pool have different lock period.\n Notice withdrawing before the lock timer has ended will trigger 25% tax.";
      break;
    case StakeModes.Unstake:
      content.panelTitle = "Select Pool For Unstaking";
      content.actionWarning = (
        <Paragraph size={14}>
          Unstaking REVA before the lock timer has ended will trigger{" "}
          <InlineText color={colorErrorDefault}>25% </InlineText>withdrawal fee
        </Paragraph>
      );
      break;
  }

  return content;
};
