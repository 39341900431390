import styled from "styled-components";
import {
  colorGrayscaleLabel,
  colorGrayscaleOffWhite,
  colorPrimaryDefault,
} from "../../utils/colors";
import Button from "../Button";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: -65px;
`;

export const AppIcon = styled.img`
  width: 114px;
  height: 114px;
  object-fit: contain;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: ${colorGrayscaleOffWhite};
  margin-top: 34px;
`;

export const Subtitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${colorGrayscaleLabel};
  margin-top: 10px;
  margin-bottom: 34px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const DisconnectButton = styled(Button)`
  flex: 1;
  color: ${colorPrimaryDefault};
  height: 60px;
`;

export const SwitchButton = styled(Button)`
  flex: 1;
  height: 60px;
`;
