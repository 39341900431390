import styled from "styled-components";

import Button from "../../../components/Button/";

import {
  colorGrayscaleBody,
  colorVaultFilterHover,
  colorPrimaryDefault,
  colorGrayscaleDark,
  colorGrayscaleBodyRGBA,
  colorInfoDefaultRGBA,
  colorGrayscaleLabel,
  colorGrayscaleLine,
  colorInfoDark,
  colorInfoDefault,
  colorGrayscaleOffWhite,
} from "utils/colors";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
`;

export const Title = styled.div`
  color: ${colorGrayscaleOffWhite};
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
`;
export const Subtitle = styled.div`
  color: ${colorGrayscaleLabel};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

export const PlayIcon = styled.img`
  width: 17px;
  height: 17px;
  object-fit: contain;
  margin-right: 7px;
`;

export const PlayButton = styled(Button)`
  background-color: rgba(146, 47, 224, 0.1);
  color: ${colorPrimaryDefault};
  margin-top: 20px;
  margin-bottom: 30px;

  :hover {
    background-color: rgba(146, 47, 224, 0.3);
  }
`;

export const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  alighn-items: center;
  color: #424b6d;
  font-size: 14px;
  line-hight: 16px;
  gap: 10px;
  margin-left: auto;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "flex-start" : "center")};
  align-items: center;
  width: 100%;
  padding: 0px 15px;
  height: 55px;
  box-sizing: border-box;
  border-radius: 15px;
  gap: 15px;
`;

export const Slider = styled.button`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin: auto;
  background: ${(props) =>
    !props.showStakedOnly ? colorGrayscaleBodyRGBA : colorInfoDefaultRGBA};
  border: none;
  border-radius: 100px;
  width: 25px;
  height: 14px;
  cursor: pointer;
`;

export const State = styled.span`
  border-radius: 100px;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OnOption = styled(State)`
  ${(props) =>
    !props.showStakedOnly && `background-color:${colorGrayscaleLabel}`};
  color: ${(props) =>
    !props.showStakedOnly ? colorGrayscaleLine : colorInfoDark};
`;

export const OffOption = styled(State)`
  ${(props) => props.showStakedOnly && `background-color:${colorInfoDefault}`};
  color: ${(props) =>
    props.showStakedOnly ? colorGrayscaleOffWhite : colorGrayscaleLabel};
`;

export const Option = styled.div`
  border-radius: 7px;
  box-sizing: border-box;
  padding: 7px;
  border: ${(props) =>
    props.selected
      ? `1px solid ${colorGrayscaleDark}`
      : `1px solid ${colorGrayscaleBody}`};
  font-size: 14px;
  cursor: pointer;
  ${(props) =>
    props.selected
      ? `color:${colorPrimaryDefault};background-color:${colorGrayscaleDark};`
      : `color: ${colorGrayscaleBody};`}
  &:hover {
    ${(props) => !props.selected && `color: ${colorVaultFilterHover};`}
  }
`;
