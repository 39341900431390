import * as types from "../utils/constants/ActionTypes";

/* Load */

export function loadVaultsSuccess(vaultData) {
  return {
    type: types.LOAD_VAULTS_SUCCESS,
    payload: {
      vaultData,
    },
  };
}

export function loadVaultsFailure(error) {
  return {
    type: types.LOAD_VAULTS_FAILURE,
    payload: {
      error,
    },
  };
}

/* Commit Vaulrs Cache */

export const commitVaultsCache = (cache) => {
  return { type: types.COMMIT_VAULTS_CACHE, payload: cache };
};

/* Mark Approval successful */

export const approvalSuccess = (payload) => {
  return { type: types.APPROVAL_SUCCESS, payload };
};

/* Mark Vaults initialized */
export const markVaultsInitialized = (isInitialized) => {
  return { type: types.SET_VAULTS_INITIALIZED, isInitialized };
};

/* Periodic Update */

export function periodicUpdateSuccess(vaultData) {
  return {
    type: types.PERIODIC_UPDATE_SUCCESS,
    payload: {
      vaultData,
    },
  };
}

export function periodicUpdateFailure(error) {
  return {
    type: types.PERIODIC_UPDATE_FAILURE,
    payload: {
      error,
    },
  };
}
