import * as types from "utils/constants/ActionTypes";

const initialState = {
  isInitialized: false,
  isConnected: false,
  accountAddress: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CONNECTION_STATUS:
      return {
        ...state,
        isInitialized: true,
        isConnected: action.isConnected,
        accountAddress: action.accountAddress,
      };
    default:
      return state;
  }
};
