import React from "react";
import PropTypes from "prop-types";
import { PageSubtitle, PageTitle } from "../StakeScreen.styles";
import InfoItem from "components/InfoItem";
import { formatNumericString } from "helpers/utils";
import { Line } from "containers/common/styles";
import { colorGrayscaleLabel } from "utils/colors";
import { FlexContainer } from "containers/common/styles";

function TitleSection({
  totalRevaStaked,
  circSupply,
  title,
  subtitle,
  isLoadingStakeData,
}) {
  const isLoading = !totalRevaStaked || isLoadingStakeData;

  return (
    <FlexContainer
      col
      justify="start"
      align="start"
      css="width: 530px; margin: 130px auto;"
    >
      <PageTitle css="text-align: start; font-size:48px">{title}</PageTitle>
      <PageSubtitle>{subtitle}</PageSubtitle>

      <FlexContainer
        css="margin-top: 5rem; width: 450px;"
        gap={isLoading ? "20px" : "0"}
      >
        <InfoItem
          title={`${formatNumericString(totalRevaStaked)}`}
          style="text-align: start;"
          subtitle="Total Reva Staked"
          titleStyle="font-size: 46px; margin-bottom: 20px"
          subtitleStyle="font-weight: 400;"
          loading={isLoading}
        />
        {!isLoading && (
          <Line
            rotate="21deg"
            color={colorGrayscaleLabel}
            css="margin: 0 0 0 17px; width: 30px;"
          />
        )}
        <InfoItem
          title={formatNumericString(circSupply)}
          style="text-align: start;"
          subtitle="reva in circulation"
          titleStyle="font-size: 46px; margin-bottom: 20px"
          subtitleStyle="font-weight: 400;"
          loading={isLoading}
        />
      </FlexContainer>
    </FlexContainer>
  );
}

export default TitleSection;

TitleSection.propTypes = {
  isConnected: PropTypes.bool,
  isLoadingStakeData: PropTypes.bool,
  totalRevaStaked: PropTypes.string,
  circSupply: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
};
