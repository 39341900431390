import InfoItem from "components/InfoItem";
import NumberFormatter from "components/NumberFormatter";
import React from "react";
import { Info } from "../styles";
import { PropTypes } from "prop-types";
import { Divider } from "components/Common.styles";
import { useFarmingData } from "hooks/farming.hooks";
import { numberWithCommas } from "helpers/utils";
import { FlexContainer } from "containers/common/styles";

export default function FarmingInfo({ firstTokenSymbol, secondTokenSymbol }) {
  const {
    isLoading,
    data: { lpPools, busdRate },
  } = useFarmingData({
    firstTokenSymbol,
    secondTokenSymbol,
  });
  if (isLoading) {
    return null;
  }

  const { apy: APR, totalLpStaked } = lpPools[0];
  const totalLiquidity = numberWithCommas(
    busdRate.times(totalLpStaked).toFixed(2),
  );

  return (
    <FlexContainer col css="text-align: start">
      <Info>
        <InfoItem
          title={<NumberFormatter value={APR} suffix="%" withToolTip />}
          subtitle="APR"
          style="text-align: start; padding: 0 1rem;"
        />
        <Divider />
        <InfoItem
          title={
            <NumberFormatter value={totalLiquidity} prefix="$" withToolTip />
          }
          subtitle="TOTAL LIQUIDITY"
          style="text-align: start; padding: 0 1rem;"
        />
      </Info>
    </FlexContainer>
  );
}

FarmingInfo.propTypes = {
  firstTokenSymbol: PropTypes.string,
  secondTokenSymbol: PropTypes.string,
};
