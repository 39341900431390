import Timer from "easytimer.js";

export const initTimersByPoolsPositions = ({ userPositions, pools }) => {
  const timersData = {};

  userPositions.forEach((poolPosition) => {
    const { timeStaked, poolId } = poolPosition;
    const poolLockPeriod = pools[poolId].lockPeriod;

    const timeLeft =
      poolLockPeriod - timeStaked <= 0 ? 0 : poolLockPeriod - timeStaked;

    timersData[poolId] = new Timer({
      startValues: {
        days: poolId === 0 ? 0 : Math.floor(timeLeft / (3600 * 24)),
        hours: poolId === 0 ? 0 : Math.floor((timeLeft % (3600 * 24)) / 3600),
        minutes: poolId === 0 ? 0 : Math.floor((timeLeft % 3600) / 60),
        seconds: poolId === 0 ? 0 : Math.floor(timeLeft % 60),
      },
      countdown: true,
    });

    timersData[poolId].start();
  });
  return timersData;
};
