import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { markVaultsInitialized } from "../../actions/vaults";
import {
  Root,
  Title,
  Subtitle,
  AppIcon,
  ButtonContainer,
  DisconnectButton,
  SwitchButton,
} from "./index.styles";
import iconCreditCard from "../../assets/credit-card.png";
import { colorGrayscaleLabel } from "../../utils/colors";
import { useWallet } from "../../hooks/wallet.hooks";

export default function UnsupportedNetworkView() {
  const { logOut, switchNetwork } = useWallet();
  const dispatch = useDispatch();

  useEffect(() => dispatch(markVaultsInitialized(false)), []);

  return (
    <Root>
      <AppIcon src={iconCreditCard} />
      <Title>Revault App currently supports the Polygon (Matic) network</Title>
      <Subtitle>
        You are currently connected to an unsupported network. <br />
        Please switch network via your wallet.
      </Subtitle>
      <ButtonContainer>
        <DisconnectButton
          text="Disconnect Wallet"
          outline
          backgroundColor={colorGrayscaleLabel}
          onClick={logOut}
        />
        <div style={{ width: 20 }} />
        <SwitchButton
          text="Switch to Polygon Network"
          onClick={switchNetwork}
        />
      </ButtonContainer>
    </Root>
  );
}
