import styled from "styled-components";
import {
  colorErrorDefault,
  colorGrayscaleDark,
  colorGrayscaleLabel,
  colorGrayscaleLine,
} from "../../utils/colors";

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  user-select: none;
`;

const BaseFrame = styled.div`
  border: 1px solid
    ${(props) => (props.isOpen ? colorGrayscaleLabel : "#282f48")};
  border-radius: 5px;
  background-color: ${colorGrayscaleDark};
  user-select: none;
`;

const HoverFrame = styled(BaseFrame)`
  cursor: pointer;

  :hover {
    border: 1px solid ${colorGrayscaleLabel};
  }
`;

export const Polygon = styled(BaseFrame)`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colorGrayscaleLine};
  margin-right: 10px;
  padding-left: 2px;
  padding-right: 8px;
`;

export const PolygonIcon = styled.img`
  height: 33px;
  width: 33px;
  object-fit: contain;
  margin-right: 5px;
`;

export const Account = styled(HoverFrame)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 173px;
`;

export const AccountIcon = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
  padding: 7px;
  pointer-events: none;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 38px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  pointer-events: none;
`;

export const Chain = styled.div`
  color: ${colorGrayscaleLabel};
  line-height: 16px;
  text-transform: capitalize;
`;

export const Address = styled.div`
  width: 100%;
  color: ${colorGrayscaleLine};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
`;

export const Controls = styled(HoverFrame)`
  position: absolute;
  left: 0;
  top: 50px;
  width: 163px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid ${colorGrayscaleLabel};
`;

export const ControlItem = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 28px;
  padding: 5px;
  color: ${(props) => props.textColor || colorErrorDefault};

  :hover {
    opacity: 0.6;
  }
`;

export const ToggleButton = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 5px;
  pointer-events: none;
`;

export const ControlIcon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 10px;
`;

export const PlusIcon = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-left: 9px;
`;
