import React from "react";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../utils/constants";
import Loader from "../containers/vaults/ValidNetworkLoader/ValidNetworkLoader";

export default function validNetworkWidget(address, network) {
  //address must be in lower case or else api call will fail
  address = address.toLowerCase();
  //create a div
  let divRef = React.useRef(null);
  //initialize it so we don't have to render it
  divRef.current = document.createElement("div");
  const [loading, setLoading] = React.useState(false);

  //fetching asset from script - if fetch succeeded address was correct
  //to handle adresses thet are not on the api but on the revault platform
  const isValidAddress = useQuery(
    [QUERY_KEYS.validNetworAddressCheck, address],
    async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `https://static.valid.network/widget/score-${address}.svg`,
        );
        if (res.status === 200) {
          return true;
        } else {
          setLoading(false);
          return false;
        }
      } catch (e) {
        setLoading(false);
      }
    },
    {
      refetchOnMount: false,
    },
  );

  //if the address is valid append child to the div ref
  React.useEffect(() => {
    if (isValidAddress.data) {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.src = "https://static.valid.network/widget/seal.js";
      scriptElement.setAttribute("address", address);
      scriptElement.setAttribute("network", network);
      divRef.current.appendChild(scriptElement);
      setLoading(false);
    }
  }, [isValidAddress?.data]);

  //return divRef only if it should be displayed
  if (!isValidAddress.data && !loading) {
    return null;
  }
  if (isValidAddress.data) {
    return <div ref={divRef} />;
  }
  if (loading) {
    return <Loader />;
  }
}
