const { ethers } = require("ethers");

const addresses = require("../../config/config").config.getNetworkAddresses();

const { getToken, getVault } = require("../utils/addresses");

const revaultAbi = require("../../abi/ReVault.json");

const revaultAddress = addresses.revault;

const {
  generateDepositPayload,
  generateWithdrawAllPayload,
  generateRebalanceAllPayloads,
  generateWithdrawPayload,
  generateWithdrawFarmPayload,
  generateDepositFarmPayload,
  generateHarvestPayload,
  generateWithdrawSharesPayload,
  acryptosConvertTokensToShares,
  generateWithdrawAllFarmAndVaultPayloads,
} = require("../utils/payloads");
const { claim } = require("./revachef");

async function rebalanceAll(fromVaultId, toVaultId, userAddress) {
  const revaultContract = new ethers.Contract(revaultAddress, revaultAbi);

  const payloads = await generateRebalanceAllPayloads(
    fromVaultId,
    toVaultId,
    userAddress,
  );
  const txData =
    await revaultContract.populateTransaction.rebalanceVaultToVault(
      fromVaultId,
      toVaultId,
      payloads,
    );
  return txData.data;
}

async function deposit(provider, vaultId, amount) {
  const vault = getVault(vaultId);
  const token = getToken(vault.depositTokenSymbol);

  const revaultContract = new ethers.Contract(revaultAddress, revaultAbi);

  const formattedAmount = ethers.utils.parseUnits(
    String(amount),
    token.decimals,
  );

  let value = "0x00";
  const toAddress = revaultAddress;

  let data;
  if (vault.additionalData.farmAddress) {
    const depositVaultPayload = await generateDepositPayload(
      vaultId,
      formattedAmount,
    );
    const depositFarmPayload = await generateDepositFarmPayload(vaultId, "0");
    const depositFarmLeftPayload = depositFarmPayload.substr(
      0,
      depositFarmPayload.length - 64,
    );
    const txData =
      await revaultContract.populateTransaction.depositToVaultAndFarm(
        formattedAmount,
        vaultId,
        depositVaultPayload,
        depositFarmLeftPayload,
      );
    data = txData.data;
  } else {
    const depositPayload = await generateDepositPayload(
      vaultId,
      formattedAmount,
    );
    const txData = await revaultContract.populateTransaction.depositToVault(
      formattedAmount,
      vaultId,
      depositPayload,
    );
    data = txData.data;
  }
  // for bnb
  if (vault.depositTokenSymbol === "matic") {
    value = formattedAmount.toHexString();
  }
  return { address: toAddress, data, value: value };
}

async function withdraw(vaultId, amount) {
  const vault = getVault(vaultId);
  const token = getToken(vault.depositTokenSymbol);

  const revaultContract = new ethers.Contract(revaultAddress, revaultAbi);

  const formattedAmount = ethers.utils.parseUnits(
    String(amount),
    token.decimals,
  );
  if (vault.additionalData.farmAddress) {
    const sharesAmount = await acryptosConvertTokensToShares(
      vault.address,
      formattedAmount,
    );
    const withdrawVaultPayload = await generateWithdrawSharesPayload(
      vaultId,
      sharesAmount,
    );
    const withdrawFarmPayload = await generateWithdrawFarmPayload(
      vaultId,
      sharesAmount,
    );
    const txData =
      await revaultContract.populateTransaction.withdrawFromFarmAndVaultAndClaim(
        vaultId,
        withdrawFarmPayload,
        withdrawVaultPayload,
      );
    return txData.data;
  } else {
    const withdrawPayload = await generateWithdrawPayload(
      vaultId,
      formattedAmount,
    );
    const txData =
      await revaultContract.populateTransaction.withdrawFromVaultAndClaim(
        vaultId,
        withdrawPayload,
      );
    return txData.data;
  }
}

async function withdrawAll(vaultId, userAddress) {
  const vault = getVault(vaultId);
  const revaultContract = new ethers.Contract(revaultAddress, revaultAbi);

  let txData;
  if (vault.additionalData.farmAddress) {
    const [withdrawFarmPayload, withdrawVaultPayload] =
      await generateWithdrawAllFarmAndVaultPayloads(userAddress, vaultId);
    txData =
      await revaultContract.populateTransaction.withdrawFromFarmAndVaultAndClaim(
        vaultId,
        withdrawFarmPayload,
        withdrawVaultPayload,
      );
  } else {
    const withdrawAllPayload = await generateWithdrawAllPayload(vaultId);

    txData =
      await revaultContract.populateTransaction.withdrawFromVaultAndClaim(
        vaultId,
        withdrawAllPayload,
      );
  }
  return txData.data;
}

async function harvest(vaultId) {
  const vault = getVault(vaultId);

  // beefy doesn't have harvest, so we claim reva from revachef
  if (vault.vaultProvider === "beefy") {
    const token = getToken(vault.depositTokenSymbol);
    return claim(token.tokenId);
  }
  const revaultContract = new ethers.Contract(revaultAddress, revaultAbi);
  const harvestPayload = await generateHarvestPayload(vaultId);

  const txData = await revaultContract.populateTransaction.harvestVault(
    vaultId,
    harvestPayload,
  );
  return txData.data;
}

export { deposit, withdraw, withdrawAll, rebalanceAll, harvest };
