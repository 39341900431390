import styled from "styled-components";
import { Title } from "components/TokenPair/index.styles";
import { Subtitle } from "components/TokenSelect/TokenCard.styles";

export const PageTitle = styled(Title)`
  display: flex;
  justify-content: center;
  margin-top: 47px;
  margin-bottom: 0;
  font-size: 32px;
`;

export const PageSubtitle = styled(Subtitle)`
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 5px;
`;
