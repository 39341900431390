import React, { useEffect, useState, useRef } from "react";
import usePortal from "react-cool-portal";
import PropTypes from "prop-types";
import { getShortTxHash } from "../../helpers/utils";
import { RevaApi } from "../../apis";
import ConnectorSelection from "./ConnectorSelection";
import { useChainName, useWallet } from "hooks/wallet.hooks";
import { useHover, useOnClickOutside } from "helpers/hooks";
import { web3SessionKey } from "utils/constants";
import { getConnectorImageForKey } from "utils/wallets";

import Button from "components/Button";
import FadeIn from "components/FadeIn";

import {
  Root,
  Polygon,
  PolygonIcon,
  Account,
  AccountIcon,
  Info,
  Address,
  Chain,
  Controls,
  ControlItem,
  ControlIcon,
  PlusIcon,
  ToggleButton,
} from "./index.styles";

import iconPlus from "../../assets/plus.png";
import iconLogOut from "../../assets/icon-log-out.png";
import iconChevronDown from "../../assets/chevron-down.png";
import iconChevronUp from "../../assets/chevron-up-white.png";
import iconPolygon from "assets/coin-logo-matic.png";
export default function ModalConnect({ isConnected, customButton }) {
  const chainName = useChainName();
  const { logOut } = useWallet();
  const connectorKey = window.localStorage.getItem(web3SessionKey);

  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    clickOutsideToHide: false,
  });

  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const clickOutsideRef = useRef();
  useOnClickOutside(clickOutsideRef, () => setIsAccountOpen(false));
  const [hoverRef, isHover] = useHover();

  useEffect(() => {
    if (isShow && isConnected) {
      hide();
    }
  }, [isConnected, isShow, hide]);

  useEffect(() => {
    if (isHover && !isAccountOpen) {
      setIsAccountOpen(true);
    }
  }, [isHover]);

  if (isConnected) {
    return (
      <Root ref={clickOutsideRef}>
        <Polygon>
          <PolygonIcon src={iconPolygon} />
          Polygon
        </Polygon>
        <Account
          ref={hoverRef}
          isOpen={isAccountOpen}
          onClick={() => setIsAccountOpen(!isAccountOpen)}
        >
          <AccountIcon src={getConnectorImageForKey(connectorKey)} />
          <Info>
            <Chain>{chainName}</Chain>
            <Address>{getShortTxHash(RevaApi().getWalletUser(), 4)}</Address>
          </Info>
          <ToggleButton src={isAccountOpen ? iconChevronUp : iconChevronDown} />
          {isAccountOpen && (
            <FadeIn>
              <Controls>
                <ControlItem onClick={logOut}>
                  <ControlIcon src={iconLogOut} />
                  Disconnect
                </ControlItem>
              </Controls>
            </FadeIn>
          )}
        </Account>
      </Root>
    );
  }

  return (
    <>
      {React.cloneElement(customButton || DefaultConnectButton, {
        onClick: show,
      })}

      <Portal>
        <ConnectorSelection onClose={hide} />
      </Portal>
    </>
  );
}

const DefaultConnectButton = (
  <Button text="Connect Wallet" suffix={<PlusIcon src={iconPlus} />} />
);

ModalConnect.propTypes = {
  isConnected: PropTypes.bool,
  customButton: PropTypes.element,
};
