import DoubleTokenBox from "components/DoubleTokenBox";
import React from "react";
import { PropTypes } from "prop-types";
import { getToken } from "apis/utils/addresses";
import { PANCAKESWAP_ADD_BNB_URL } from "utils/constants";
import Link from "components/Link";
import { FlexContainer } from "containers/common/styles";
import { PanelTitle } from "containers/common/panel.styles";

export default function DoubleTokenLogoAndName({ firstToken, secondToken }) {
  const doubleTokenSymbol = `${firstToken.title}-${secondToken.title}`;
  // const revaTokenAddress = getToken("reva").address;
  const getTokenLink = "https://quickswap.exchange/#/swap"; // PANCAKESWAP_ADD_BNB_URL + revaTokenAddress;

  return (
    <FlexContainer css="text-transform: uppercase;">
      <DoubleTokenBox coins={[firstToken.type, secondToken.type]} />
      <FlexContainer col css="margin-left:1rem">
        <PanelTitle>{doubleTokenSymbol}</PanelTitle>
        <Link
          to={getTokenLink}
          text={`Get ${doubleTokenSymbol} LP`}
          external
          withExternalIcon
        />
      </FlexContainer>
    </FlexContainer>
  );
}

DoubleTokenLogoAndName.propTypes = {
  firstToken: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  secondToken: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};
