import React from "react";
import PropTypes from "prop-types";
import { filtersConfig } from "./vaultsFilterConfig";
import { VAULT_FILTER_OPTIONS } from "utils/constants";
import { colorWhite } from "utils/colors";
import Big from "big.js";
import numeral from "numeral";
import _ from "lodash";
import {
  Root,
  Title,
  Subtitle,
  SliderWrapper,
  Filters,
  Slider,
  OnOption,
  OffOption,
  Option,
} from "./WelcomeHeader.styles";
import { useIsConnected } from "../../../hooks/wallet.hooks";

const WelcomeHeader = React.forwardRef(({ vaults, setFilteredVaults }, ref) => {
  const [filters, setFilters] = React.useState(_.clone(filtersConfig));
  const [showDepositedOnly, setShowDepositedOnly] = React.useState(false);
  const { isConnected } = useIsConnected();

  //BUSD is USD
  const totalBusd = numeral(
    vaults.reduce(
      (sum, vault) =>
        vault.userVault ? Big(vault.userVault.totalBalanceBusd).plus(sum) : sum,
      Big(0),
    ),
  ).format("0,0.00");

  //total value Locked
  const tvl = numeral(
    vaults.reduce((sum, vault) => Big(vault.tvl).plus(sum), Big(0)),
  ).format("0,0.00");

  //listenning to change in the diffrent filters and actually filtering the vaults
  React.useEffect(() => {
    if (vaults) {
      let vaultsToDisplay;
      if (showDepositedOnly) {
        vaultsToDisplay = vaults.filter((vault) => vault.inPosition);
      } else {
        vaultsToDisplay = vaults;
      }
      _.keys(filters).forEach((filterKey) => {
        if (filters[filterKey].active) {
          vaultsToDisplay = filters[filterKey].filterFunction(vaultsToDisplay);
        }
      });
      setFilteredVaults(_.clone(vaultsToDisplay));
    }
  }, [filters, vaults, showDepositedOnly]);

  //update the ref with the current state so the father can
  //display things according to current filters state
  React.useEffect(() => {
    ref.current = {
      state: { filters, showDepositedOnly },
      resetState: () => {
        const filtersCopy = filtersConfig;
        _.keys(filtersCopy).forEach((filterKey) => {
          filtersCopy[filterKey].active = false;
        });
        filtersCopy[VAULT_FILTER_OPTIONS.AllTypes].active = true;
        setFilters(_.clone(filtersCopy));
        setShowDepositedOnly(false);
      },
    };
  }, [filters, showDepositedOnly]);

  return (
    <Root>
      <Title
        css={`
          ${isConnected && `margin-left: 15px;margin-right: auto;`}
        `}
      >
        {isConnected ? "Vaults" : "We Find & Maximize Vault Strategies"}
      </Title>
      <Subtitle
        css={`
          margin-top: 5px;
          font-size: 16px;
          ${isConnected && `margin-left: 15px;margin-right: auto;`}
        `}
      >
        {isConnected
          ? `Total Value Locked $${tvl} • In Position $${totalBusd}`
          : "Find and deposit funds in the best performing vaults in the market at any given time"}
      </Subtitle>
      <Filters center={isConnected}>
        {_.keys(filters).map((filterKey) => (
          <Filter
            key={filterKey}
            label={filters[filterKey].label}
            selected={filters[filterKey].active}
            setFilters={setFilters}
            filters={filters}
            filterKey={filterKey}
          />
        ))}
        {isConnected && (
          <SliderWrapper>
            <span
              css={`
                ${showDepositedOnly && `color:${colorWhite}`}
              `}
            >
              Deposited Only
            </span>
            <Slider
              showStakedOnly={showDepositedOnly}
              onClick={() => setShowDepositedOnly((ls) => !ls)}
            >
              <OnOption showStakedOnly={showDepositedOnly}></OnOption>
              <OffOption showStakedOnly={showDepositedOnly}></OffOption>
            </Slider>
          </SliderWrapper>
        )}
      </Filters>
    </Root>
  );
});
//when using forword ref need to do this - or make changes to eslint config
WelcomeHeader.displayName = "WelcomeHeader";

function Filter({ label, filters, setFilters, selected, filterKey }) {
  function handleClick() {
    const filtersCopy = _.clone(filters);
    _.keys(filtersCopy).forEach((filtersKey) => {
      filtersCopy[filtersKey].active = false;
    });
    filtersCopy[filterKey].active = true;

    setFilters(_.clone(filtersCopy));
  }

  return (
    <Option selected={selected} onClick={handleClick}>
      {label}
    </Option>
  );
}

WelcomeHeader.propTypes = {
  vaults: PropTypes.array,
  filters: PropTypes.array,
  setFilteredVaults: PropTypes.func,
  isConnected: PropTypes.bool,
  isFetching: PropTypes.bool,
};

Filter.propTypes = {
  label: PropTypes.string,
  setFilters: PropTypes.func,
  selected: PropTypes.bool,
  filterKey: PropTypes.string,
  filters: PropTypes.object,
};

export default WelcomeHeader;
