import React from "react";
import PropTypes from "prop-types";
import InfoItem from "components/InfoItem";
import Big from "big.js";
import NumberFormatter from "components/NumberFormatter";
import { ReactComponent as BadgeIcon } from "assets/icons/stakescreen/badge.svg";
import { useIsAutoCompoundInPool } from "hooks/autocompound.hook";
import { colorInfoDefault, colorPrimaryLight } from "utils/colors";
import AutoCompoundLockTooltip from "components/AutoCompound/AutoCompoundLockTooltip";
import { FlexContainer } from "containers/common/styles";
import { useRevaUSDprice } from "hooks/common.hooks";
import {
  HighlightedTitle,
  Items,
  ProgressBarContainer,
  StakeInfoContainer,
  StyledLockIcon,
} from "containers/stake/StakeScreen.styles";
import { useStakeData } from "hooks/staking.hooks";
import { useIsConnected } from "hooks/wallet.hooks";
import ContentLoader from "react-content-loader";

export default function StakeInfo({ timeLeft, selectedPool }) {
  const { isConnected } = useIsConnected();

  if (!isConnected) {
    return null;
  }
  return (
    <StakeInfoContainer>
      <HighlightedTitle>Your Position</HighlightedTitle>
      <Info timeLeft={timeLeft} selectedPool={selectedPool} />
    </StakeInfoContainer>
  );
}

StakeInfo.propTypes = {
  timeLeft: PropTypes.object, // days, hours, minutes, seconds, tenth of seconds
  selectedPool: PropTypes.number,
};

StakeInfo.defaultProps = {
  lockPeriod: 0,
  selectedPool: 0,
};

const Info = ({ timeLeft, selectedPool }) => {
  const { isLoading: isStakeDataLoading, data } = useStakeData();
  const isAutoCompound = useIsAutoCompoundInPool({ poolId: selectedPool });
  const { price: revaPrice, isLoading: isRevaPriceLoading } = useRevaUSDprice();
  const { pools, userPositions } = data;
  if (isRevaPriceLoading || isStakeDataLoading || !userPositions || !pools) {
    return <StakeInfoLoader />;
  }

  const selectedPoolData = pools[selectedPool];
  const selectedPoolUserPosition = userPositions[selectedPool];
  const { multiplier, lockPeriod } = selectedPoolData;
  let { pendingReva, revaStaked } = selectedPoolUserPosition;
  pendingReva = pendingReva ? pendingReva : new Big(0); // set default value as 0
  revaStaked = revaStaked ? revaStaked : new Big(0); // set default value as 0
  const revaTokenUSDvalue = revaPrice;
  const lockPeriodInDays = lockPeriod / 60 / 60 / 24;

  const getProgressValue = () => {
    const { days, hours, minutes, seconds } = timeLeft;

    const timeLeftInSeconds =
      days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;

    return ((lockPeriod - timeLeftInSeconds) / lockPeriod).toFixed(2);
  };
  return (
    <>
      <Items>
        <InfoItem
          title={multiplier && `x${multiplier}`}
          subtitle="active Pool"
          titleStyle="text-transform: none;"
        />
        {isAutoCompound ? (
          <>
            <InfoItem
              title={
                <NumberFormatter
                  value={revaStaked.plus(pendingReva).times(revaTokenUSDvalue)}
                  withToolTip
                  top
                  prefix="$"
                />
              }
              subtitle="position value"
            />
            <InfoItem
              title={
                <NumberFormatter
                  value={revaStaked.plus(pendingReva)}
                  withToolTip
                  top
                />
              }
              subtitle={
                <FlexContainer gap="4px">
                  REVA staked
                  <BadgeIcon />
                </FlexContainer>
              }
              subtitleStyle={`color: ${colorInfoDefault}`}
            />
          </>
        ) : (
          <>
            <InfoItem
              title={<NumberFormatter value={revaStaked} top withToolTip />}
              subtitle="REVA Staked"
            />
            <InfoItem
              title={<NumberFormatter value={pendingReva} top withToolTip />}
              subtitle="REVA Earned"
            />
          </>
        )}
        {timeLeft && (
          <>
            <InfoItem
              title={
                isAutoCompound
                  ? `${lockPeriodInDays} Days`
                  : `${timeLeft.toString([
                      "days",
                      "hours",
                      "minutes",
                      "seconds",
                    ])}`
              }
              subtitle={
                <FlexContainer
                  gap="4px"
                  data-for="autocompound-lock-tooltip"
                  data-tip="auto-compound lock tooltip"
                  css={`
                    ${isAutoCompound
                      ? `color : ${colorPrimaryLight}`
                      : undefined};
                    min-width: 150px;
                  `}
                >
                  time left
                  {isAutoCompound && (
                    <>
                      <AutoCompoundLockTooltip wrapperId="autocompound-lock-tooltip" />
                      <StyledLockIcon />
                    </>
                  )}
                </FlexContainer>
              }
            />
          </>
        )}
      </Items>
      {timeLeft && (
        <ProgressBarContainer isAutoCompound={isAutoCompound}>
          <progress
            value={
              !isAutoCompound
                ? revaStaked && timeLeft
                  ? getProgressValue()
                  : 0
                : 1
            }
            max="1"
          />
        </ProgressBarContainer>
      )}
    </>
  );
};

Info.propTypes = {
  timeLeft: PropTypes.object, // days, hours, minutes, seconds, tenth of seconds
  selectedPool: PropTypes.number,
};

const StakeInfoLoader = (props) => (
  <ContentLoader
    speed={2}
    width={460}
    height={60}
    viewBox="0 0 460 60"
    backgroundColor="rgba(38, 43, 69, 0.4)"
    foregroundColor="rgba(255, 255, 255, 0.1)"
    {...props}
  >
    <rect x="18" y="10" rx="0" ry="0" width="66" height="33" />
    <rect x="375" y="10" rx="0" ry="0" width="66" height="33" />
    <rect x="194" y="10" rx="0" ry="0" width="66" height="33" />
    <rect x="10" y="48" rx="0" ry="0" width="82" height="15" />
    <rect x="187" y="48" rx="0" ry="0" width="82" height="15" />
    <rect x="368" y="48" rx="0" ry="0" width="82" height="15" />
  </ContentLoader>
);
