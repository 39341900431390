import React from "react";
import ContentLoader from "react-content-loader";
import { colorGrayscaleTitleActive } from "utils/colors";

const ValidNetworkLoader = () => (
  <ContentLoader
    speed={2}
    width={175}
    height={37}
    viewBox="0 0 117 36"
    backgroundColor="rgba(38, 43, 69, 0.4)"
    foregroundColor="rgba(255, 255, 255, 0.1)"
    style={{
      width: 175,
      height: 37,
      backgroundColor: colorGrayscaleTitleActive,
      borderRadius: 10,
    }}
  >
    <rect width="117" height="36" fill="url(#paint0_linear_40265_37806)" />
    <defs>
      <linearGradient
        id="paint0_linear_40265_37806"
        x1="4.35859e-07"
        y1="18"
        x2="112.592"
        y2="18"
        gradientUnits="userSpaceOnUse"
      ></linearGradient>
    </defs>
  </ContentLoader>
);

export default ValidNetworkLoader;
