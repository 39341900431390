import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { setConnectionStatus } from "actions/global";
import { network } from "services/connectors";
import { useEagerConnect, useInactiveListener } from "hooks/wallet.hooks";
import { web3SessionKey, NETWORK_CONTEXT_NAME } from "utils/constants";
import { getKeyForConnector } from "utils/wallets";

export default function Web3ReactManager({ children }) {
  const dispatch = useDispatch();
  const { account, active, connector, library } = useWeb3React();
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React(NETWORK_CONTEXT_NAME);

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network);
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active]);

  useEffect(() => {
    if (triedEager) {
      if (account) {
        handleConnected();
      } else {
        handleDisconnected();
      }
    }
  }, [account, triedEager]);

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  function handleConnected() {
    window.ethers = new ethers.providers.Web3Provider(library.provider);
    localStorage.setItem(web3SessionKey, getKeyForConnector(connector));
    dispatch(setConnectionStatus(true, account));
  }

  function handleDisconnected() {
    dispatch(setConnectionStatus(false));
  }

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null;
  }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return null;
  }

  // if neither context is active, spin
  if (!active && !networkActive) {
    return null;
  }

  return children;
}
