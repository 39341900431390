import React from "react";
import { ReactComponent as CoinGekoIcon } from "../../assets/icons/coingecko.svg";
import { ReactComponent as CoinMarketCapIcon } from "../../assets/icons/coinmarketcap.svg";
import {
  Stat,
  CGAndCMCLinks,
  CustomLink,
  HoverMenu,
  Spacer,
  CustomStat,
} from "./Drawer.styles";
import { colorBackgroundDark4, colorBackgroundDark5 } from "../../utils/colors";
import PropTypes from "prop-types";
import { useHover } from "../../helpers/hooks";
import NumberFormatter from "../NumberFormatter";
import Vector from "../../assets/svg/Vectory";
import { useRevaStats } from "hooks/revaStats.hooks";
import ContentLoader from "react-content-loader";
import numeral from "numeral";

export const getTVL = () => {
  const { stats } = useRevaStats();
  return <Stat>{`TVL: $${numeral(stats.tvl).format("0.00a") || "0"}`}</Stat>;
};

function RevaHoverLinks() {
  const links = [
    {
      link: "https://coinmarketcap.com/currencies/revault-network/",
      logo: <CoinMarketCapIcon width="24" height="24" />,
      label: "CoinMarketCap",
    },
    {
      link: "https://www.coingecko.com/en/coins/revault-network",
      logo: <CoinGekoIcon width="24" height="24" />,
      label: "Coin Gecko",
    },
  ];

  return (
    <CGAndCMCLinks>
      {links.map((linkObject) => (
        <CustomLink
          key={linkObject.link}
          onClick={(e) => {
            //to prevent event propagating to the nav and cousing it to expend
            e.stopPropagation();
            window.open(`${linkObject.link}`, "_blank");
          }}
        >
          {linkObject.logo}
          <span>{linkObject.label}</span>
        </CustomLink>
      ))}
    </CGAndCMCLinks>
  );
}

export const RevaHoverLinksContainer = React.forwardRef((props, ref) => {
  return (
    <HoverMenu>
      <Spacer ref={ref}>
        <RevaHoverLinks />
      </Spacer>
      {props.children}
    </HoverMenu>
  );
});
RevaHoverLinksContainer.displayName = "RevaHoverLinksContainer";

export const getRevaStat = () => {
  //to implement background on REVA value when hovered on the links
  const [ref, isHover] = useHover();
  const { stats } = useRevaStats();

  return (
    <RevaHoverLinksContainer ref={ref}>
      <CustomStat hover={isHover}>
        REVA
        <Stat.Value isPositive>
          <NumberFormatter value={stats.revaPrice || 0} prefix="$" />
        </Stat.Value>
        <Stat.Graph>
          <Vector isBull />
        </Stat.Graph>
      </CustomStat>
    </RevaHoverLinksContainer>
  );
};

export const getMaxSupplyStat = () => {
  const { stats } = useRevaStats();
  return (
    <Stat>
      <NumberFormatter
        value={stats.maxSupply || 0}
        prefix="Max supply: "
        suffix=" REVA"
      />
    </Stat>
  );
};
export const getCurrentEmissionsStat = () => {
  const { stats } = useRevaStats();
  return (
    <Stat>{`current emissions: ${stats.currEmissions || "0"}/block`}</Stat>
  );
};

export const getTotalSupplyStat = () => {
  const { stats } = useRevaStats();
  return (
    <Stat>
      <NumberFormatter
        value={stats.totalSupply || 0}
        prefix="Total supply: "
        suffix=" REVA"
      />
    </Stat>
  );
};

export const getTotalCirculatingSupplyStat = () => {
  const { stats } = useRevaStats();
  return (
    <Stat>
      <NumberFormatter
        value={stats.circSupply || 0}
        prefix="Circulating supply: "
        suffix=" REVA"
      />
    </Stat>
  );
};

export const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={2000}
    height={30}
    viewBox="0 0 340 10"
    backgroundColor={colorBackgroundDark4}
    foregroundColor={colorBackgroundDark5}
    {...props}
  >
    <rect x="0" y="5" rx="0" ry="0" width="1500" height="20" />
  </ContentLoader>
);

RevaHoverLinksContainer.propTypes = { children: PropTypes.node };
