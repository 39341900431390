import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { useOnClickOutside } from "../../helpers/hooks";

import {
  Wrapper,
  Root,
  Title,
  InputContainer,
  Input,
  Slider,
} from "./index.styles";

import iconSearch from "assets/icon-search.png";
import iconSearchWhite from "assets/icon-search-white.png";
import iconXCircle from "assets/icon-x-circle.png";
import TokenCard from "./TokenCard";

export default function TokenSelect({
  data = [],
  sourceTokenId,
  onTokenSelected,
  onClose,
}) {
  const rootRef = useRef();
  const inputRef = useRef();
  useOnClickOutside(rootRef, onClose);

  const allItems = data.filter((token) => {
    return (
      token.tokenId !== sourceTokenId &&
      // omit these tokens from zap
      !["wmatic", "reva", "reva-matic"].includes(
        token.tokenDetails.symbol.toLowerCase(),
      )
    );
  });

  const [displayItems, setDisplayItems] = useState(allItems);
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setDisplayItems(filterItems(allItems, searchValue));
  }, [searchValue]);

  const handleClear = () => {
    setSearchValue("");
    inputRef.current.focus();
  };

  return (
    <Wrapper>
      <Root ref={rootRef}>
        <Title>Select Token</Title>
        <InputContainer>
          <Input
            ref={inputRef}
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.trim())}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <Input.Icon src={isFocused ? iconSearchWhite : iconSearch} />
          {displayItems.length < allItems.length && (
            <Input.Clear src={iconXCircle} onClick={handleClear} />
          )}
        </InputContainer>
        <Slider>
          {displayItems.length === 0
            ? "No results..."
            : displayItems.map((t, index) => (
                <TokenCard
                  key={`tc-${index}`}
                  data={t}
                  onClick={onTokenSelected}
                />
              ))}
        </Slider>
      </Root>
    </Wrapper>
  );
}

function filterItems(items, searchTerm) {
  if (!_.isEmpty(searchTerm)) {
    return items.filter((t) => t.tokenDetails.symbol.includes(searchTerm));
  }
  return items;
}

TokenSelect.propTypes = {
  data: PropTypes.array,
  sourceTokenId: PropTypes.number,
  onTokenSelected: PropTypes.func,
  onClose: PropTypes.func,
};
