import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React as useWeb3ReactCore } from "@web3-react/core";
import { ConnectorEvent } from "@web3-react/types";
import { UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector";
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from "@web3-react/walletconnect-connector";
import { bscConnector, injected, walletconnect } from "services/connectors";
import { web3SessionKey, NETWORK_CONTEXT_NAME } from "utils/constants";
import { CONNECTOR_KEYS } from "utils/wallets";
import { isMetaMaskAvailable } from "../helpers/utils";
import { useQueryClient } from "react-query";

import { config } from "../config/config";
import { getChainByName } from "apis/utils/addresses";
import { markVaultsInitialized } from "actions";

const addresses = require("config/config").config.getNetworkAddresses();
const mainnetChainId = getChainByName("matic-mainnet").chainId;

export const useWallet = () => {
  const dispatch = useDispatch();
  const { activate, deactivate } = useWeb3ReactCore();

  const logIn = useCallback((connector) => {
    return activate(connector, async (error) => {
      window.localStorage.removeItem(web3SessionKey);
      if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
      ) {
        if (connector instanceof WalletConnectConnector) {
          connector.walletConnectProvider = null;
        }
      }
    }).catch((error) => {
      console.error("Error logging in", error);
    });
  }, []);

  const logOut = useCallback(() => {
    deactivate();
    localStorage.removeItem(web3SessionKey);
    dispatch(markVaultsInitialized(false));
  }, []);

  const switchNetwork = useCallback(async () => {
    const connectorKey = window.localStorage.getItem(web3SessionKey);
    if (connectorKey === CONNECTOR_KEYS.injected && isMetaMaskAvailable()) {
      await metaMaskSwitchNetwork();
    } else if (connectorKey === CONNECTOR_KEYS.bsc) {
      await bscSwitchNetwork();
    }
  }, []);

  return { logIn, logOut, switchNetwork };
};

async function metaMaskSwitchNetwork() {
  const mainnetChainIdHex = `0x${getChainByName(
    "matic-mainnet",
  ).chainId.toString(16)}`;

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: mainnetChainIdHex }],
    });
  } catch (switchError) {
    // The chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: mainnetChainIdHex,
              rpcUrl: process.env.REACT_APP_WEB3_PROVIDER,
            },
          ],
        });
      } catch (addError) {
        throw new Error("failed to add network to metamask");
      }
    }
    // There is already a pending request to switch network - ignore
    if (switchError.code === -32002) {
      return;
    }
    // User rejected the request to switch network - ignore
    if (switchError.code === 4001) {
      return;
    }
    throw new Error("failed to switch metamask network");
  }
}

async function bscSwitchNetwork() {
  return window.BinanceChain.switchNetwork("matic-mainnet");
}

export function useActiveWeb3React() {
  const context = useWeb3ReactCore();
  const contextNetwork = useWeb3ReactCore(NETWORK_CONTEXT_NAME);
  return context.active ? context : contextNetwork;
}

export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore(); // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false);
  const { logIn, logOut } = useWallet();

  useEffect(() => {
    if (injected.listeners(ConnectorEvent.Deactivate).length === 0) {
      injected.on(ConnectorEvent.Deactivate, () => {
        logOut();
      });
    }
  }, []);

  useEffect(() => {
    const connectorKey = window.localStorage.getItem(web3SessionKey);
    if (connectorKey === CONNECTOR_KEYS.injected && isMetaMaskAvailable()) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          logIn(injected).finally(() => setTried(true));
        } else {
          setTried(true);
        }
      });
    } else if (connectorKey === CONNECTOR_KEYS.walletConnect) {
      // no eager connect for WalletConnect since this causes instability
      // disconnecting immediately upon connection
      // perhaps we will turn this back on when we switch to walletConnect 2.0
      logIn(walletconnect)
        .then(() => logOut())
        .finally(() => setTried(true));
    } else if (connectorKey === CONNECTOR_KEYS.bsc) {
      bscConnector.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          logIn(bscConnector).finally(() => setTried(true));
        } else {
          setTried(true);
        }
      });
    } else {
      setTried(true);
    }
  }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore(); // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((e) => {
          console.error("Failed to activate after chain changed", e);
        });
      };

      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((e) => {
            console.error("Failed to activate after accounts changed", e);
          });
        }
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
}

export function useChainName() {
  const { chainId } = useWeb3ReactCore();
  const chainName = useMemo(() => {
    if (chainId) {
      return (
        addresses?.chains?.find((c) => c.chainId === parseInt(chainId))
          ?.chainName || getChainName(chainId)
      );
    }
    return "";
  }, [chainId]);
  return chainName;
}

export function useNetworkValidity() {
  const { chainId } = useWeb3ReactCore();
  const queryClient = useQueryClient();
  const { isConnected, isInitialized } = useSelector(
    (state) => state.globalState,
  );

  const isInvalidNetwork = useMemo(() => {
    return (
      config.isProduction() &&
      isConnected &&
      chainId &&
      chainId != mainnetChainId
    );
  }, [isConnected, chainId]);

  // Connection status changed -> refetch
  useEffect(() => {
    if (isInitialized) {
      queryClient.refetchQueries();
    }
  }, [isConnected, isInitialized]);

  // Network is invalid -> delete react-query cache
  useEffect(() => {
    if (isInvalidNetwork) {
      queryClient.refetchQueries();
    }
  }, [isInvalidNetwork]);

  return !isInvalidNetwork;
}

const getChainName = (chainId) => {
  if (!!Number(chainId) && chainId.length > 9) {
    return "local";
  }
  switch (`${chainId}`) {
    case "1":
      return "mainnet";
    case "3":
      return "ropsten";
    case "4":
      return "rinkeby";
    case "5":
      return "goerli";
    case "42":
      return "kovan";
    case "56":
      return "bsc";
    case "137":
      return "polygon";
    default:
      return `unknown`;
  }
};

export const useIsConnected = () => {
  const { isConnected } = useSelector((state) => state.globalState);
  return { isConnected };
};
