import { RevaApi } from "apis";
import { useQuery } from "react-query";
import { fetchRevaBalance } from "services/api/staking";
import { REFETCHING_INTERVALS } from "utils/constants";
import { QUERY_KEYS } from "utils/constants";
import { useIsConnected } from "./wallet.hooks";

export const useRevaStakedBalance = () => {
  const { isConnected } = useIsConnected();
  const result = useQuery(
    QUERY_KEYS.revaStakeBalance,
    async () => {
      if (!isConnected) {
        return;
      }
      const balance = await fetchRevaBalance({
        userAddress: RevaApi().getWalletUser(),
      });
      return balance;
    },
    {
      refetchInterval: REFETCHING_INTERVALS.HOOKS.REVA_STAKED_BALANCE,
    },
  );
  return { ...result, balance: result.data, fetchBalance: result.refetch };
};
