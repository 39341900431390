import styled from "styled-components";
import {
  colorPrimaryDefault,
  colorGrayscaleLabel,
  colorGrayscaleOffWhite,
  colorGrayscaleBody,
} from "utils/colors";

export const InlineText = styled.span`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color || colorGrayscaleLabel};
  text-transform: ${(props) => props.textTransform || "none"};
  &:hover {
    color: ${(props) => props.hoverColor || "none"};
  }
`;

export const Paragraph = styled.p`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color || colorGrayscaleOffWhite};
  text-align: ${(props) => props.color || "center"};
`;

export const SimpleText = styled.div`
  font-size: ${(props) => props.fontSize}px;
  color: ${colorGrayscaleBody};
  margin: 0 0.1rem 0;
  text-transform: uppercase;
  font-style: normal;
  font-weight: "500";
  line-height: 32px;
  background-color: transparent;
  text-align: start;
  border: none;
  outline: none;
`;

export const Bold = styled.span`
  font-weight: bold;
  color: ${colorPrimaryDefault};
  padding-right: 0.3rem;
`;
