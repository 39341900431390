import { config } from "config/config";
import { RevaApi } from "../../apis";
import { stringToBigNumber } from "helpers/utils";
import { activateErrorNotification } from "components/TransactionNotification/TransactionNotification";
import { sendTransaction } from "helpers/blockchain";

const addresses = config.getNetworkAddresses();
const GEM = config.getSystemConfig().gasEstimationMultiplier;

export async function fetchStakeData() {
  try {
    const [pools, userPositions] = await Promise.all([
      fetchRevaStakingPools(),
      fetchUserRevaStakingPoolPositions({
        userAddress: RevaApi().getWalletUser(),
      }),
    ]);
    return { pools, userPositions };
  } catch (error) {
    console.log(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function fetchRevaStakingPools() {
  try {
    const revaApi = RevaApi();
    const data = await revaApi.getRevaStakingPools();

    return data;
  } catch (error) {
    console.log(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}

export async function fetchUserRevaStakingPoolPositions({ userAddress }) {
  try {
    if (!userAddress) {
      return undefined;
    }
    const revaApi = RevaApi();
    let data = await revaApi.getAllUserRevaStakingPoolPositions(userAddress);
    // Converting to big, this should move to state management
    const userPositions = data.map((userPosition) => {
      return {
        ...userPosition,
        pendingReva: stringToBigNumber(userPosition.pendingReva),
        revaStaked: stringToBigNumber(userPosition.revaStaked),
      };
    });

    return userPositions;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function fetchRevaBalance({ userAddress }) {
  try {
    const revaApi = RevaApi();
    const data = await revaApi.getUserRevaBalance(userAddress);

    return stringToBigNumber(data);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export async function stakeReva({ poolId, amount }) {
  try {
    const { revaStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();

    const txData = await revaApi.stakeReva(poolId, amount);

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const stakeRevaTxHash = await sendTransaction(txParams);

    return stakeRevaTxHash;
  } catch (error) {
    // TODO parse error to fit FE structure
    activateErrorNotification(error);
    const parsedError = error;
    throw parsedError;
  }
}

export async function stakeRevaWhileCompound({ poolId, amount }) {
  try {
    const { revaStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();
    const txData = await revaApi.depositToRevaCompoundingPosition(
      poolId,
      amount,
    );

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData.data,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const stakeRevaTxHash = await sendTransaction(txParams);

    return stakeRevaTxHash;
  } catch (error) {
    // TODO parse error to fit FE structure
    activateErrorNotification(error);
    const parsedError = error;
    throw parsedError;
  }
}

export async function unstakeReva({ poolId, amount, isEarly }) {
  try {
    const { revaStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();

    let txData;
    if (isEarly) {
      txData = await revaApi.unstakeRevaEarly(poolId, amount);
    } else {
      txData = await revaApi.unstakeReva(poolId, amount);
    }

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const unstakeRevaTxHash = await sendTransaction(txParams);

    return unstakeRevaTxHash;
  } catch (error) {
    console.error(error.stack);
    // TODO parse error to fit FE structure
    console.error(error.stack);
    activateErrorNotification(error);
    const parsedError = error;
    throw parsedError;
  }
}

export async function claimReva({ poolId }) {
  try {
    const { revaStakingPool } = addresses;

    const revaApi = RevaApi();
    const gasPrice = await revaApi.getGasPrice();
    const txData = await revaApi.claimRevaStakeReward(poolId);

    // Metamask request logic (approve and sign tx)
    const txParams = {
      to: revaStakingPool,
      from: revaApi.getWalletUser(),
      value: "0x00",
      data: txData,
      gasPrice,
    };
    txParams.gas = await revaApi.getGasLimit(txParams, GEM);

    const claimRevaTxHash = await sendTransaction(txParams);

    return claimRevaTxHash;
  } catch (error) {
    console.log(error);
    activateErrorNotification(error);
    // TODO parse error to fit FE structure
    const parsedError = error;
    throw parsedError;
  }
}
