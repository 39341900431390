import store from "../../store";
import { config } from "../../config/config";

function getWalletUser() {
  const state = store.getState();
  const { isConnected, accountAddress } = state.globalState;

  if (!isConnected || !accountAddress) {
    return config.getNetworkAddresses().dead;
  }
  return accountAddress;
}

export { getWalletUser };
