import styled from "styled-components";
import Button from "components/Button";
import {
  colorPrimaryDefault,
  colorGrayscaleBody,
  colorGrayscaleDark,
  colorGrayscaleOffWhite,
} from "utils/colors";

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-height: 800px) {
    padding-bottom: 0; // so StakeScreen will take the entire screen. width: 100vh / fill available not working properly on both screen sizes
    height: fill-available;
  }
  padding-bottom: 140px; // so StakeScreen will take the entire screen. width: 100vh / fill available not working properly on both screen sizes
`;

export const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 20px;
`;

export const ActionButton = styled(Button)`
  flex: 0.48;
  height: 60px;
  background: ${(props) => props.background || colorPrimaryDefault};
  color: ${(props) => props.color || colorPrimaryDefault};
  border: ${(props) =>
    props.outline ? `1px solid ${props.outline}` : undefined};
  border-radius: 10px;
  flex-grow: 1;

  &:hover {
    color: ${colorGrayscaleOffWhite};
  }

  :disabled {
    color: ${(props) => (props.outline ? props.outline : colorPrimaryDefault)};
    background: none;
    cursor: default;
  }
`;

export const Line = styled.div`
  border-left: 2px solid
    ${(props) => (props.color ? `${props.color}` : `${colorGrayscaleOffWhite}`)};
  height: 75px;
  width: 20px;
  transform: ${(props) => (props.rotate ? `rotate(${props.rotate})` : "none")};
`;

export const Operator = styled.div`
  border-radius: 50%;
  background: ${colorGrayscaleDark};
  color: ${colorGrayscaleBody};
  font-weight: bold;
  padding: 5px;
  text-align: center;
  display: flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
  justify-content: center;
`;

export const AssetViewContainer = styled.div`
  border: 0.5px solid ${colorGrayscaleBody};
  border-radius: 10px;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  gap: ${(props) => (props.gap ? props.gap : "0")};
`;
