export const LOAD_VAULTS_SUCCESS = "LOAD_VAULTS_SUCCESS";
export const LOAD_VAULTS_FAILURE = "LOAD_VAULTS_FAILURE";
export const SET_VAULTS_INITIALIZED = "SET_VAULTS_INITIALIZED";

export const COMMIT_VAULTS_CACHE = "COMMIT_VAULTS_CACHE";

export const PERIODIC_UPDATE_SUCCESS = "PERIODIC_UPDATE_SUCCESS";
export const PERIODIC_UPDATE_FAILURE = "PERIODIC_UPDATE_FAILURE";

export const APPROVAL_SUCCESS = "APPROVAL_SUCCESS";

export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";
