const { ethers } = require("ethers");
const { BigNumber } = require("ethers");

// const systemConfig = require("../../config/config").config.getSystemConfig();
const addresses = require("../../config/config").config.getNetworkAddresses();
const { getVault, getToken, getTokenByAddress } = require("../utils/addresses");

const { format } = require("../utils/format.js");

const zapAbi = require("../../abi/Zap.json");

const { getApy } = require("./apy");
const { getTokenToBusdRate } = require("./tokens");
const { getGasPrice, getGasLimit } = require("./network");
const { rebalanceAll } = require("../write/revault");
const {
  getUserProxyContractAddress,
  getUserVaultPrincipal,
} = require("./revault");

const {
  getBunnyMinterContract,
  getBunnyVaultContract,
  getBeefyStrategyContract,
  getAutofarmStrategyContract,
  getAcryptosFarmContract,
} = require("./vault_providers");

// Bunny

async function getBunnyWithdrawFeeParams(provider) {
  const bunnyMinterContract = getBunnyMinterContract(provider);
  const withdrawalFeeFactor = await bunnyMinterContract.WITHDRAWAL_FEE();
  const withdrawalFeeMax = await bunnyMinterContract.FEE_MAX();
  return { withdrawalFeeFactor, withdrawalFeeMax };
}

async function getBunnyWithdrawalFeeTimes(provider, userAddress, vaultId) {
  const bunnyVaultContract = getBunnyVaultContract(provider, vaultId);
  const bunnyMinterContract = getBunnyMinterContract(provider);

  const userProxyContractAddress = await getUserProxyContractAddress(
    provider,
    userAddress,
  );
  const depositedAt = await bunnyVaultContract.depositedAt(
    userProxyContractAddress,
  );
  const currentTimestamp = (await provider.getBlock("latest")).timestamp;

  const feePeriodEnd = depositedAt.add(
    await bunnyMinterContract.WITHDRAWAL_FEE_FREE_PERIOD(),
  );

  let withdrawalFeeRemainingTime;
  if (currentTimestamp > feePeriodEnd) {
    withdrawalFeeRemainingTime = BigNumber.from(0);
  } else {
    withdrawalFeeRemainingTime = feePeriodEnd.sub(currentTimestamp);
  }

  return {
    withdrawalFeeRemainingTime,
    depositedAt,
  };
}

async function getBunnyWithdrawFeeAndRemainingTime(
  provider,
  userAddress,
  vaultId,
  amount,
) {
  const { withdrawalFeeRemainingTime, depositedAt } =
    await getBunnyWithdrawalFeeTimes(provider, userAddress, vaultId);

  let withdrawalFee;
  if (withdrawalFeeRemainingTime.eq(0)) {
    withdrawalFee = BigNumber.from(0);
  } else {
    const bunnyMinterContract = getBunnyMinterContract(provider);
    withdrawalFee = await bunnyMinterContract.withdrawalFee(
      amount,
      depositedAt,
    );
  }

  return { withdrawalFee, withdrawalFeeRemainingTime };
}

// Beefy

async function getBeefyWithdrawFeeParams(provider, vaultId) {
  const vault = getVault(vaultId);
  const strategyContract = await getBeefyStrategyContract(provider, vaultId);
  const withdrawalFeeFactor = await strategyContract.withdrawalFee();
  const withdrawalFeeMax = await strategyContract.WITHDRAWAL_MAX();

  return { withdrawalFeeFactor, withdrawalFeeMax };
}

async function getBeefyWithdrawFee(provider, vaultId, amount) {
  const { withdrawalFeeFactor, withdrawalFeeMax } =
    await getBeefyWithdrawFeeParams(provider, vaultId);
  return BigNumber.from(amount).mul(withdrawalFeeFactor).div(withdrawalFeeMax);
}

// Autofarm

async function getAutofarmDepositFeeParams(provider, vaultId) {
  const strategyContract = await getAutofarmStrategyContract(provider, vaultId);
  const entranceFeeFactorMax = await strategyContract.entranceFeeFactorMax();
  // autofarm entranceFeeFactor is not how much fee to take but how many shares to mint
  const entranceFeeFactor = entranceFeeFactorMax.sub(
    await strategyContract.entranceFeeFactor(),
  );

  return { entranceFeeFactor, entranceFeeFactorMax };
}

async function getAutofarmDepositFee(provider, vaultId, amount) {
  const { entranceFeeFactor, entranceFeeFactorMax } =
    await getAutofarmDepositFeeParams(provider, vaultId);
  return BigNumber.from(amount)
    .mul(entranceFeeFactor)
    .div(entranceFeeFactorMax);
}

async function getVaultFees(provider, vaultId, userAddress) {
  const vault = getVault(vaultId);

  let withdrawalFeePercentage, depositFeePercentage;
  let harvestFeePercentage = "0.00";
  if (vault.vaultProvider === "bunny") {
    const { withdrawalFeeRemainingTime, depositedAt } =
      await getBunnyWithdrawalFeeTimes(provider, userAddress, vaultId);
    if (userAddress && withdrawalFeeRemainingTime.eq(0) && !depositedAt.eq(0)) {
      withdrawalFeePercentage = "0.00";
    } else {
      const { withdrawalFeeFactor, withdrawalFeeMax } =
        await getBunnyWithdrawFeeParams(provider);
      withdrawalFeePercentage = (
        (withdrawalFeeFactor.toNumber() / withdrawalFeeMax.toNumber()) *
        100
      ).toFixed(2);
    }
    depositFeePercentage = "0.00";
  } else if (vault.vaultProvider === "beefy") {
    const { withdrawalFeeFactor, withdrawalFeeMax } =
      await getBeefyWithdrawFeeParams(provider, vaultId);
    withdrawalFeePercentage = (
      (withdrawalFeeFactor.toNumber() / withdrawalFeeMax.toNumber()) *
      100
    ).toFixed(2);
    depositFeePercentage = "0.00";
  } else if (vault.vaultProvider === "autofarm") {
    const { entranceFeeFactor, entranceFeeFactorMax } =
      await getAutofarmDepositFeeParams(provider, vaultId);
    withdrawalFeePercentage = "0.00";
    depositFeePercentage = (
      (entranceFeeFactor.toNumber() / entranceFeeFactorMax.toNumber()) *
      100
    ).toFixed(2);
  } else if (vault.vaultProvider === "acryptos") {
    // TODO: hardcoded
    withdrawalFeePercentage = "0.1";
    depositFeePercentage = "0.00";
    const farmContract = getAcryptosFarmContract(provider, vaultId);
    harvestFeePercentage = ethers.utils.formatEther(
      await farmContract.harvestFee(),
    );
  }

  return {
    withdrawalFeePercentage,
    depositFeePercentage,
    harvestFeePercentage,
  };
}

async function getRebalanceCosts(
  provider,
  fromVaultId,
  toVaultId,
  userAddress,
) {
  // gas cost
  const rebalanceTxData = await rebalanceAll(
    fromVaultId,
    toVaultId,
    userAddress,
  );
  const rebalanceTx = {
    to: addresses.revault,
    from: userAddress,
    data: rebalanceTxData,
  };
  const txGas = await getGasLimit(provider, rebalanceTx); // Gas Estimation Multiplier not taken into account on purpose
  const gasPrice = await getGasPrice(provider, true);
  const gasCostEth = gasPrice.mul(txGas);

  const fromVault = getVault(fromVaultId);
  const toVault = getVault(toVaultId);
  const token = getTokenByAddress(fromVault.depositTokenAddress);

  const userVaultPrincipal = await getUserVaultPrincipal(
    provider,
    fromVault.additionalData.vid,
    userAddress,
  );

  // withdrawal fee
  let withdrawalFeeToken = BigNumber.from(0);
  if (fromVault.vaultProvider == "bunny") {
    withdrawalFeeToken = (
      await getBunnyWithdrawFeeAndRemainingTime(
        provider,
        userAddress,
        fromVaultId,
        userVaultPrincipal,
      )
    ).withdrawalFee;
  } else if (fromVault.vaultProvider == "beefy") {
    withdrawalFeeToken = await getBeefyWithdrawFee(
      provider,
      fromVaultId,
      userVaultPrincipal,
    );
  } else if (fromVault.vaultProvider == "acryptos") {
    // TODO: don't hardcode 0.1% even though it's supposed to be constant across all vaults?
    // otherwise we need to read vault.controller->controller.strategy(tokenAddress)->strategy.withdrawalFee
    withdrawalFeeToken = userVaultPrincipal.div("1000");
  }

  // deposit fee
  let depositFeeToken = BigNumber.from(0);
  if (toVault.vaultProvider == "autofarm") {
    depositFeeToken = await getAutofarmDepositFee(
      provider,
      toVaultId,
      userVaultPrincipal,
    );
  }

  // convert to USD
  const precision = 2;
  const bnbToken = getToken("matic");
  const bnbBusdRate = await getTokenToBusdRate(provider, bnbToken.tokenId);
  const tokenBusdRate = await getTokenToBusdRate(provider, token.tokenId);

  const gasCostUsd =
    parseFloat(ethers.utils.formatUnits(gasCostEth, bnbToken.decimals)) *
    bnbBusdRate;
  const withdrawalFeeUsd =
    parseFloat(ethers.utils.formatUnits(withdrawalFeeToken, token.decimals)) *
    tokenBusdRate;
  const depositFeeUsd =
    parseFloat(ethers.utils.formatUnits(depositFeeToken, token.decimals)) *
    tokenBusdRate;

  const totalCostUsd = gasCostUsd + withdrawalFeeUsd + depositFeeUsd;

  return {
    totalCostUsd: parseFloat(totalCostUsd.toFixed(precision)),
    txGas: txGas,
    gasPrice: parseFloat(ethers.utils.formatEther(gasPrice)),
    gasCostUsd: parseFloat(gasCostUsd.toFixed(precision)),
    withdrawalFeeUsd: parseFloat(withdrawalFeeUsd.toFixed(precision)),
    depositFeeUsd: parseFloat(depositFeeUsd.toFixed(precision)),
  };
}

async function getRebalanceDailyGainEstimation(
  provider,
  fromVaultId,
  toVaultId,
  userAddress,
) {
  const fromVault = getVault(fromVaultId);
  const token = getTokenByAddress(fromVault.depositTokenAddress);

  const userVaultPrincipal = await getUserVaultPrincipal(
    provider,
    fromVault.additionalData.vid,
    userAddress,
  );

  const zapContract = new ethers.Contract(addresses.zap, zapAbi, provider);
  const tokenToBusdRate = await zapContract.getBUSDValue(
    token.address,
    BigNumber.from("10").pow(token.decimals),
  );
  const principalBalanceBusd = tokenToBusdRate
    .mul(userVaultPrincipal)
    .div(BigNumber.from("10").pow(token.decimals));

  const fromApy = await getApy(fromVaultId);
  const toApy = await getApy(toVaultId);
  const apyDiff = toApy - fromApy;
  const dailyGain =
    (parseFloat(format(principalBalanceBusd)) * apyDiff) / 100 / 365;

  return dailyGain;
}

async function getRebalanceBreakEvenPeriod(
  provider,
  fromVaultId,
  toVaultId,
  userAddress,
) {
  const rebalanceCostUsd = (
    await getRebalanceCosts(provider, fromVaultId, toVaultId, userAddress)
  ).totalCostUsd;
  const dailyGainUsd = await getRebalanceDailyGainEstimation(
    provider,
    fromVaultId,
    toVaultId,
    userAddress,
  );

  // negative gains - will never break even
  if (dailyGainUsd <= 0) {
    return {};
  }

  const daysToBreakEvenFloat = rebalanceCostUsd / dailyGainUsd;
  return {
    days: Math.floor(daysToBreakEvenFloat),
    hours: Math.floor((daysToBreakEvenFloat % 1) * 24),
    minutes: Math.floor((((daysToBreakEvenFloat % 1) * 24) % 1) * 60),
  };
}

export {
  getVaultFees,
  getRebalanceCosts,
  getRebalanceDailyGainEstimation,
  getRebalanceBreakEvenPeriod,
};
