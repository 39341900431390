import styled from "styled-components";
import { colorGrayscaleBody } from "../../utils/colors";

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  max-width: 990px;
  height: 70px;
  border-radius: 0px 0px 10px 10px;
  padding: 15px;
  box-sizing: border-box;
`;

export const LogoContainer = styled.div`
  display: flex;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
`;

export const VersionLabel = styled.div`
  color: ${colorGrayscaleBody};
  padding-top: 29px;
  padding-left: 10px;
  font-size: 14px;
`;
