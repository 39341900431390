import React from "react";
import styled from "styled-components";
import { Title } from "components/TokenPair/index.styles";
import ReactTooltip from "react-tooltip";
import { ReactComponent as TimeLock } from "assets/icons/stakescreen/time-lock.svg";
import { colorGrayscaleBody, colorGrayscaleLabel } from "utils/colors.js";
import PropTypes from "prop-types";

export default function AutoCompoundLockTooltip({ wrapperId }) {
  return (
    <ReactTooltipStyled id={wrapperId} backgroundColor={"transparent"}>
      <ToolTip>
        <TimeLock />
        <StyledTitle>Time Lock</StyledTitle>
        <ColoredText size={14} color={colorGrayscaleLabel}>
          While auto comp is on the lock timer is frozen. Turn off auto comp to
          restart it.
        </ColoredText>
      </ToolTip>
    </ReactTooltipStyled>
  );
}

AutoCompoundLockTooltip.propTypes = {
  wrapperId: PropTypes.string,
};

const ColoredText = styled.span`
  ${(props) => (props.size ? `font-size:${props.size}px` : "font-size : 12px")};
  line-height: 18px;
  ${(props) => (props.color ? `color:${props.color}` : "inherit")};
  margin-bottom: 5px;
  text-align: center;
  text-transform: none;
`;

const StyledTitle = styled(Title)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 0px;
`;

const ToolTip = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 213px;
  border-radius: 10px;
  opacity: 1 !important;
  background: rgba(20, 22, 43, 1);
  border: 1px solid ${colorGrayscaleBody};
`;

//override transparent background
const ReactTooltipStyled = styled(ReactTooltip)`
  &.__react_component_tooltip.show {
    opacity: 1;
  }
`;
