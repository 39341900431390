import { InlineText } from "containers/common/text.styles";
import React from "react";
import { Link as RRDLink } from "react-router-dom";
import { colorGrayscaleBody } from "utils/colors";
import PropTypes from "prop-types";
import { StyledGoToIcon } from "containers/common/icons.styles";

export default function Link({
  to,
  external,
  withExternalIcon,
  text,
  color = colorGrayscaleBody,
  textSize = 12,
  textTransform = "uppercase",
  textDecoration = "none",
  hoverColor,
}) {
  return (
    <RRDLink
      to={external ? { pathname: to } : to}
      target={external ? "_blank" : undefined}
      rel={external ? "noopener noreferrer" : undefined}
      style={{
        textDecoration,
        display: "flex",
      }}
    >
      <InlineText
        size={textSize}
        color={color}
        textTransform={textTransform}
        hoverColor={hoverColor}
      >
        {text}
      </InlineText>
      {withExternalIcon ? <StyledGoToIcon /> : null}
    </RRDLink>
  );
}

Link.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  withExternalIcon: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
  textSize: PropTypes.number,
  textTransform: PropTypes.string,
  textDecoration: PropTypes.string,
  hoverColor: PropTypes.string,
};
