import React from "react";
import PropTypes from "prop-types";

import NumberFormatter from "../NumberFormatter";

import { vaultLogo, getVaultProviderName } from "../../helpers/utils";

import {
  Root,
  Title,
  Subtitle,
  Dot,
  BestApyLabel,
  Stats,
  BarPrefix,
  BarSuffix,
  BarSign,
  Notice,
  LogoContainer,
  ProviderBadge,
  Logo,
} from "./VaultStats.styles";
import { VALIDNETWORK_SUPPORTED_CHAINS } from "../../utils/constants";
import { Stat as Styled } from "../TopVaults/index.styles";
import iconPlus from "../../assets/icon-plus.png";
import iconEquals from "../../assets/icon-equals.png";
import useValidNetwork from "hooks/validNetwork.hook";

const dotComponent = <Dot>•</Dot>;
export default function VaultStats({
  data,
  revaApy = 0,
  isConnected,
  inBestPosition,
  inPosition,
}) {
  const vaultName = getVaultProviderName(data.details);
  //auto farm address is in filed called strategyAddress, check if exists, if not use regular address
  const address =
    data.details?.additionalData?.strategyAddress || data.details?.address;
  const validNetworkWidget = useValidNetwork(
    address,
    VALIDNETWORK_SUPPORTED_CHAINS.BSC,
  );

  const isRebalanceRequired = inPosition && !inBestPosition;
  const protocol = data.details?.additionalData?.protocol; //strategy

  return (
    <Root>
      <Title>
        {getVaultProviderName(data.details)}
        {validNetworkWidget}
      </Title>
      <Subtitle>
        <NumberFormatter value={data.tvlBusd} prefix="TVL $" />
        {!isRebalanceRequired && (
          <>
            {dotComponent}
            <BestApyLabel>BEST APY</BestApyLabel>
          </>
        )}
      </Subtitle>
      <Stats>
        <Stats.Item
          css={`
            white-space: nowrap;
            width: calc(95%);
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          `}
        >
          <Stats.Item.Value>{`${data.apy}%`}</Stats.Item.Value>
          {`${vaultName} APY`}
        </Stats.Item>
        <Stats.Icon src={iconPlus} />

        <Stats.Item>
          <Stats.Item.Value>
            <NumberFormatter value={revaApy} suffix="%" />
          </Stats.Item.Value>
          REVA REWARDS
        </Stats.Item>
        <Stats.Icon src={iconEquals} />
        <Stats.Item>
          <Stats.Item.Value>
            <NumberFormatter value={data.apyNumeric + revaApy} suffix="%" />
          </Stats.Item.Value>
          TOTAL
        </Stats.Item>
      </Stats>
      <Styled.Container.Bar
        isConnected={isConnected && inPosition}
        isFirst={isConnected ? (inPosition ? inBestPosition : true) : true}
        inPosition={inPosition}
        vaultsHavePosition={inPosition}
        vaultsHaveBestPosition={inBestPosition}
        isCompact={false}
      >
        <BarPrefix>
          <LogoContainer>
            <Logo
              src={vaultLogo({ vaultDetails: data.details, active: true })}
            />
            {protocol && (
              <ProviderBadge>{protocol.charAt(0).toUpperCase()}</ProviderBadge>
            )}
          </LogoContainer>
          <Styled.Container.Bar.Text singleBar>
            {vaultName}
          </Styled.Container.Bar.Text>
        </BarPrefix>
        <BarSuffix isRebalanceRequired={isRebalanceRequired}>
          {`APY ${data.apy}%`}
          <BarSign isRebalanceRequired={isRebalanceRequired}>+</BarSign>
          <BarSuffix.Bkg isRebalanceRequired={isRebalanceRequired}>
            <NumberFormatter value={revaApy} prefix="REVA " suffix="%" />
          </BarSuffix.Bkg>
        </BarSuffix>
      </Styled.Container.Bar>
      {isRebalanceRequired && (
        <Notice>Rebalance available for your active vault</Notice>
      )}
    </Root>
  );
}

VaultStats.propTypes = {
  data: PropTypes.object,
  inPosition: PropTypes.bool,
  revaApy: PropTypes.number,
  isConnected: PropTypes.bool,
  inBestPosition: PropTypes.bool,
};
